import { IsoCountryCode2Char, PosVendor } from '@rbilabs/intl-common';
import { createModel } from '@rematch/core';

import { ProductFragmentFragment, RestaurantFragmentFragment } from 'src/graphql';
import { Batch, prepareBatches, splitBatches } from 'src/rematch/utils';
import { getPlus } from 'src/utils';

import { EditorAdditionalChannel, EditorState, Update } from '../types';

import {
  updateAllQuickFill,
  updateChannelQuickFill,
  updateOnlyQuickFill,
  updateProductQuickFill,
} from './helpers';
import { IUserPermissions } from './userPermissions';

import type { RootModel } from '.';

export const editor = createModel<RootModel>()({
  state: {
    MAX_STORES_PER_BATCH: 30,
    MAX_PRODUCTS_PER_BATCH: 100,
    region: '',
    restaurants: null,
    products: [],
    initialUpdates: [],
    updates: [],
    validatedBatches: [],
    quickFill: {
      enabled: false,
      updates: [],
    },
    showQuickFillToolTip: false,
    isLoading: false,
    isCopyMode: false,
    additionalChannels: [],
    isModalVisible: false,
  } as EditorState,

  reducers: {
    resetUpdatePrices(state) {
      const updatedState = {
        ...state,
        initialUpdates: state.initialUpdates.map(element => {
          const { availability, price, overrides, ...e } = element;
          return e;
        }),
        updates: state.updates.map(element => {
          const { availability, price, overrides, ...e } = element;
          return e;
        }),
      };
      return updatedState;
    },

    /**
     * Stores preloaded data for the editor input fields
     * when only one restaurant was selected to edit.
     */
    setSingleRestaurantUpdates(state, payload: Update[]) {
      const initialUpdates = payload.map(p => ({ ...p }));
      const initialUpdatesIds = initialUpdates.map(update => update.id);

      return {
        ...state,
        initialUpdates: [
          ...state.initialUpdates.filter(up => !initialUpdatesIds.includes(up.id)),
          ...initialUpdates,
        ],
        updates: [...state.updates.filter(up => !initialUpdatesIds.includes(up.id)), ...payload],
      };
    },

    /**
     * Stores the batches that are ready to be saved,
     * including any side-effects found after validating
     * the changes requested by the user.
     */
    setValidatedBatches(state, payload?: Batch) {
      if (!payload) {
        return {
          ...state,
          validatedBatches: [],
        };
      }

      return {
        ...state,
        validatedBatches: [...state.validatedBatches, payload],
      };
    },

    /**
     * Stores a hash woth data of the selected restaurants
     * separating stores by POS Vendor
     * ex: { rpos: ['123'], simply_delivery: ['234', '345'] }
     */
    setRestaurants(state, payload: RestaurantFragmentFragment[]) {
      if (payload.length === 0) {
        return {
          ...state,
          restaurants: null,
        };
      }

      const restaurants = payload.reduce<
        Record<PosVendor, Array<RestaurantFragmentFragment['id']>>
      >((hash, restaurant) => {
        const vendor = restaurant.pos?.vendor as PosVendor;
        if (vendor) {
          const storeIds = hash[vendor] ?? [];
          hash[vendor] = [...storeIds, restaurant.id];
        }
        return hash;
      }, {} as Record<PosVendor, Array<RestaurantFragmentFragment['id']>>);
      return { ...state, restaurants };
    },

    /**
     * Stores the data of the selected products and
     * prepares product plus for batches consumption
     */
    setProducts(state, payload: ProductFragmentFragment[]) {
      if (payload.length === 0) {
        return { ...state, products: [] };
      }

      return {
        ...state,
        products: payload.map(({ id, name, vendorConfigs, __typename }) => ({
          id,
          name,
          __typename,
          plus: getPlus(vendorConfigs),
        })),
      };
    },

    /**
     * Updates user selections list
     */
    updatePrice(state, payload: Update, isOverride: boolean = false) {
      const prev = state.updates.find(u => u.id === payload.id);

      // add
      if (!prev) {
        return { ...state, updates: [...state.updates, payload] };
      }

      // update
      const updatesData = { ...prev, ...payload };

      if (isOverride) {
        return {
          ...state,
          updates: state.updates.map(update => (update === prev ? updatesData : update)),
        };
      }

      if (typeof payload.price === 'number' || typeof prev.availability === 'boolean') {
        if (typeof payload.price !== 'number') {
          delete updatesData.price;
        }

        return {
          ...state,
          updates: state.updates.map(update => (update === prev ? updatesData : update)),
        };
      }

      // remove
      return { ...state, updates: state.updates.filter(update => update !== prev) };
    },

    /**
     * Updates user selections list
     */
    updateAvailability(state, payload: Update) {
      const prev = state.updates.find(u => u.id === payload.id);

      // add
      if (!prev) {
        return { ...state, updates: [...state.updates, payload] };
      }

      // update
      if (typeof payload.availability === 'boolean' || typeof prev.price === 'number') {
        return {
          ...state,
          updates: state.updates.map(update =>
            update === prev ? { ...prev, ...payload } : update
          ),
        };
      }

      // remove
      return { ...state, updates: state.updates.filter(update => update !== prev) };
    },

    /**
     * Updates all prices/availabilities
     */
    updateAllQuickFill,

    /**
     * Updates only the quick fill row inputs
     */
    updateOnlyQuickFill,

    /**
     * Updates product quick fill
     */
    updateProductQuickFill,

    /**
     * Updates channel quick fill
     */
    updateChannelQuickFill,

    /**
     * Clears the user updates
     */
    clearUpdates(state) {
      return {
        ...state,
        initialUpdates: [],
        updates: [],
        quickFill: {
          enabled: false,
          updates: [],
        },
        showQuickFillToolTip: false,
      };
    },

    /**
     * Updates the default value with the value
     * of the max-stores-per-batch
     */
    setMaxStoresPerBatch(state, payload: number) {
      return { ...state, MAX_STORES_PER_BATCH: payload };
    },

    /**
     * Store the user region so it's added to the batches
     */
    setUserRegion(state, payload: IsoCountryCode2Char) {
      return { ...state, region: payload };
    },

    /**
     * Toggles the quick fill value
     */
    toggleQuickFill(state, selectedRestaurantsCount: number) {
      const enabled = !state.quickFill.enabled;

      if (!enabled) {
        if (selectedRestaurantsCount === 1) {
          const updates = state.updates.map(up => {
            const originalUpdate = state.initialUpdates.find(
              initialUpdate => initialUpdate.id === up.id
            );

            up.price = originalUpdate?.price;
            up.availability = originalUpdate?.availability;
            up.overrides = originalUpdate?.overrides;

            return up;
          });

          return {
            ...state,
            updates,
            quickFill: {
              updates: [],
              enabled,
            },
          };
        }

        return {
          ...state,
          updates: [],
          quickFill: {
            updates: [],
            enabled,
          },
        };
      }

      return { ...state, quickFill: { ...state.quickFill, enabled } };
    },

    /**
     * Stores the value of the loading state
     */
    setIsLoading: (state, payload: boolean) => {
      return {
        ...state,
        isLoading: payload,
      };
    },

    setIsCopyMode: (state, payload: boolean) => {
      return {
        ...state,
        isCopyMode: payload,
      };
    },

    setShowQuickFillToolTip: (state, payload: boolean) => {
      return {
        ...state,
        showQuickFillToolTip: payload,
      };
    },

    setAdditionalChannels: (state, payload: EditorAdditionalChannel[]) => {
      return {
        ...state,
        additionalChannels: payload,
      };
    },

    setIsModalVisible: (state, payload: boolean) => {
      return {
        ...state,
        isModalVisible: payload,
      };
    },
  },

  selectors: (slice, createSelector) => ({
    /**
     * Memoized list of additional channels enabled
     *
     */
    additionalChannels() {
      return slice(state => state.additionalChannels);
    },

    /**
     * Memoized value of the user region
     */
    region() {
      return slice(state => state.region);
    },

    /**
     * Memoized value of showQuickFillToolTip
     */
    showQuickFillToolTip() {
      return slice(state => state.showQuickFillToolTip);
    },

    /**
     * Memoized list of products to edit
     */
    products() {
      return slice(state => state.products);
    },

    /**
     * Memoized list of restaurants to edit
     */
    restaurants() {
      return slice(state => state.restaurants);
    },
    /**
     * Memoized list of the original modifications
     */
    initialUpdates() {
      return slice(state => state.initialUpdates);
    },

    /**
     * Memoized list of current modifications
     */
    updates() {
      return slice(state => state.updates);
    },

    /**
     * Memoized list of batches ready to be saved
     */
    validatedBatches() {
      return slice(state => state.validatedBatches);
    },

    /**
     * Memoized value of the quick fill
     */
    quickFill() {
      return slice(state => state.quickFill);
    },

    /**
     * Memoized number of max stores
     */
    MAX_STORES_PER_BATCH() {
      return slice(state => state.MAX_STORES_PER_BATCH);
    },

    /**
     * Memoized number of max products
     */
    MAX_PRODUCTS_PER_BATCH() {
      return slice(state => state.MAX_PRODUCTS_PER_BATCH);
    },

    /**
     * Memoized value of the loading state
     */
    isLoading() {
      return slice(state => state.isLoading);
    },

    isCopyMode() {
      return slice(state => state.isCopyMode);
    },

    isModalVisible() {
      return slice(state => state.isModalVisible);
    },

    /**
     * Memoized list of processed sever call payloads
     */
    batches(models: any) {
      return createSelector(
        this.region,
        this.products,
        this.restaurants,
        this.initialUpdates,
        this.updates,
        this.MAX_STORES_PER_BATCH,
        this.additionalChannels,
        models.userPermissions.allowChangePrice,
        models.userPermissions.allowChangeAvailability,
        (rg, pr, rs, iu, ud, mx, adCh, acp, aca) => {
          const region = rg as unknown as EditorState['region'];
          const products = pr as unknown as EditorState['products'];
          const restaurants = rs as unknown as EditorState['restaurants'];
          const initialUpdates = iu as unknown as EditorState['initialUpdates'];
          const updates = ud as unknown as EditorState['updates'];
          const additionalChannels = adCh as unknown as EditorState['additionalChannels'];

          const MAX_STORES_PER_BATCH = mx as unknown as EditorState['MAX_STORES_PER_BATCH'];
          const allowChangePrice = acp as unknown as IUserPermissions['allowChangePrice'];
          const allowChangeAvailability =
            aca as unknown as IUserPermissions['allowChangeAvailability'];

          if (updates.length === 0) {
            return [];
          }

          // Prepare batches by POS Vendor, ServiceMode and Channel
          const batches = prepareBatches({
            region,
            products,
            restaurants,
            initialUpdates,
            updates,
            allowChangePrice,
            allowChangeAvailability,
            additionalChannels,
          });

          // Ensure batches won't exceed the max store size
          if (batches.every(batch => batch.storeIds.length <= MAX_STORES_PER_BATCH)) {
            return batches;
          }

          // split batches per max store size
          return splitBatches(batches, MAX_STORES_PER_BATCH);
        }
      );
    },
  }),
});
