import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** Additional opening hours for the service modes of a restaurant. */
export type AdditionalTimeSlot = {
  readonly __typename: 'AdditionalTimeSlot';
  /** Close time */
  readonly close?: Maybe<Scalars['String']>;
  /** Open time */
  readonly open?: Maybe<Scalars['String']>;
};

/** Type for a Restaurant address. */
export type Address = {
  readonly __typename: 'Address';
  /** Administrative Area */
  readonly administrativeArea?: Maybe<Scalars['String']>;
  /** Address geolocation */
  readonly coordinates: Coordinates;
  /** Street and number */
  readonly formattedAddress: Scalars['String'];
  /** Additions instructions on how to find the address */
  readonly instructions?: Maybe<Scalars['String']>;
  /** Locality */
  readonly locality?: Maybe<Scalars['String']>;
  /** Phone number */
  readonly phoneNumber?: Maybe<Scalars['String']>;
  /** Postal code */
  readonly postalCode?: Maybe<Scalars['String']>;
  /** Country */
  readonly regionCode: RegionCode;
  /** Street */
  readonly route: Scalars['String'];
  /** Street number */
  readonly streetNumber: Scalars['String'];
  /** Sublocality */
  readonly sublocality?: Maybe<Scalars['String']>;
  /** Subpremise */
  readonly subpremise?: Maybe<Scalars['String']>;
};

/** All Channels result. */
export type AllChannels = {
  readonly __typename: 'AllChannels';
  /** Array of channels returned. */
  readonly allChannels: ReadonlyArray<ChannelConfigOption>;
  /** Total number of channels available for the brand and market. */
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** All Dmp Orders result */
export type AllDmpOrders = {
  readonly __typename: 'AllDmpOrders';
  /** Total number of active drivers */
  readonly numberOfActiveDrivers: Scalars['String'];
  /** List of active orders in DMP */
  readonly orders: ReadonlyArray<DmpOrder>;
};

/** All Drivers result. */
export type AllDrivers = {
  readonly __typename: 'AllDrivers';
  /** Array of drivers returned. */
  readonly allDrivers: ReadonlyArray<Driver>;
};

/** All History result. */
export type AllHistory = {
  readonly __typename: 'AllHistory';
  /** Array of history returned. */
  readonly allHistory: ReadonlyArray<Maybe<History>>;
  /** The key for the next query, used for pagination. */
  readonly nextPageKey?: Maybe<Scalars['String']>;
  /** Source of data - temporary helper value to determine where to fetch data from */
  readonly source?: Maybe<Scalars['String']>;
};

/** All Orders result. */
export type AllOrders = {
  readonly __typename: 'AllOrders';
  /** Array of orders returned. */
  readonly allOrders: ReadonlyArray<RestaurantOrder>;
  /** All restaurants ids assigned to the user. */
  readonly restaurantsIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

/** All Restaurant groups query result. */
export type AllRestaurantGroups = {
  readonly __typename: 'AllRestaurantGroups';
  /** Array of restaurant groups returned. */
  readonly groups: ReadonlyArray<RestaurantGroup>;
  /** Total number of restaurant groups available. */
  readonly totalCount: Scalars['Int'];
};

/** allRestaurantGroups query params */
export type AllRestaurantGroupsParams = {
  /** Region */
  readonly region?: InputMaybe<Scalars['String']>;
  /** User OktaId used to filter groups that belong to that user */
  readonly userId?: InputMaybe<Scalars['String']>;
};

/** All Restaurants result. */
export type AllRestaurants = {
  readonly __typename: 'AllRestaurants';
  /** Array of restaurants returned. */
  readonly allRestaurants: ReadonlyArray<Restaurant>;
  /** Total number of restaurants available for the user, used for pagination. */
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Arguments for the AllRestaurants Query */
export type AllRestaurantsArgs = {
  /** Restaurant availability */
  readonly availability?: InputMaybe<ReadonlyArray<InputMaybe<AvailabilityModes>>>;
  /** Environment dev/staging/prod */
  readonly environment?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Max number of results */
  readonly limit?: InputMaybe<Scalars['Int']>;
  /** Pagination offset */
  readonly offset?: InputMaybe<Scalars['Int']>;
  /** POS Vendor */
  readonly pos?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Region */
  readonly region?: InputMaybe<Scalars['String']>;
  /** List of restaurant ids */
  readonly restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** All Restaurants result. */
export type AllRestaurantsDelivery = {
  readonly __typename: 'AllRestaurantsDelivery';
  /** Array of restaurants returned. */
  readonly allRestaurantsDelivery: ReadonlyArray<RestaurantDelivery>;
  /** Total number of restaurants available for the user, used for pagination. */
  readonly totalCount?: Maybe<Scalars['Int']>;
};

/** Arguments for the AllRestaurants Query */
export type AllRestaurantsDeliveryArgs = {
  /** Max number of results */
  readonly limit?: InputMaybe<Scalars['Int']>;
  /** Pagination offset */
  readonly offset?: InputMaybe<Scalars['Int']>;
  /** List of restaurant ids */
  readonly restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

/** All Service status from a list of restaurants */
export type AllRestaurantsServiceStatus = {
  readonly __typename: 'AllRestaurantsServiceStatus';
  /** The service status from restaurant */
  readonly dmpServiceStatus: Scalars['String'];
  /** Restaurant identifier */
  readonly restaurantId: Scalars['String'];
  /** Restaurant name */
  readonly restaurantName: Scalars['String'];
};

/** Type for a Amount */
export type Amount = {
  readonly __typename: 'Amount';
  /** Amount */
  readonly amount: Scalars['Float'];
  /** Currency */
  readonly currency: Scalars['String'];
};

/** Hours in which the product will be available for a given store, service mode and channel. */
export type AvailabilityHours = {
  readonly __typename: 'AvailabilityHours';
  /** End time of the period of availability. */
  readonly end: Scalars['String'];
  /** Start time of the period of availability. */
  readonly start: Scalars['String'];
};

/** Restaurant availability */
export enum AvailabilityModes {
  OFFLINE = 'Offline',
  ONLINE = 'Online',
  UNKNOWN = 'Unknown',
}

/** Availability Status */
export enum AvailabilityStatus {
  DRIVER_OFFSHIFT = 'DRIVER_OFFSHIFT',
  DRIVER_WORKING = 'DRIVER_WORKING',
}

/** Brand */
export enum Brand {
  BK = 'BK',
  FHS = 'FHS',
  PLK = 'PLK',
  TH = 'TH',
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type ChangeDmpPasswordInput = {
  /** The confirmation of the new restaurant password */
  readonly confirmPassword?: InputMaybe<Scalars['String']>;
  /** The update of the restaurant password */
  readonly password?: InputMaybe<Scalars['String']>;
};

export enum Channel {
  BITENINJA = 'biteninja',
  BOLT = 'bolt',
  CATERING = 'catering',
  DOORDASH = 'doordash',
  GFO = 'gfo',
  GLOVO = 'glovo',
  GRUBHUB = 'grubhub',
  IN_STORE = 'in_store',
  JUST_EAT = 'just_eat',
  SKIP = 'skip',
  UBER = 'uber',
  WAITR = 'waitr',
  WHITELABEL = 'whitelabel',
}

/** All Channels result. */
export type ChannelConfigOption = {
  readonly __typename: 'ChannelConfigOption';
  /** The channel name */
  readonly channel: Channel;
  /** The enabled serviceModes for the channel */
  readonly serviceModes: ReadonlyArray<ServiceMode>;
};

/** Child Link between restaurants */
export type ChildRestaurantLink = {
  readonly __typename: 'ChildRestaurantLink';
  /** If the user is allowed to modify the link */
  readonly allowModification: Scalars['Boolean'];
  /** Link id */
  readonly id: Scalars['String'];
  /** Child restaurant */
  readonly restaurant: RestaurantDelivery;
};

/**
 * Unique combination of items defined by one PLU.
 * More info: https://rbictg.atlassian.net/wiki/spaces/COG/pages/3445949405/Combo
 */
export type Combo = {
  readonly __typename: 'Combo';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Parent products of this product */
  readonly parentProducts?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Menu sections in which it is located */
  readonly sections?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** Complaint related to an Order */
export type Complaint = {
  readonly __typename: 'Complaint';
  /** Category */
  readonly category: Scalars['String'];
  /** Comments added to the complaint */
  readonly comments?: Maybe<Scalars['String']>;
  /** Items entitled to compensation */
  readonly compensation?: Maybe<OrderCart>;
  /** Created date */
  readonly created: Scalars['String'];
  /** customer */
  readonly customer: ComplaintsCustomer;
  /** DMP Category */
  readonly dmpCategory?: Maybe<ComplaintDmpCategory>;
  /** If ticket has been escalated */
  readonly escalated?: Maybe<Scalars['Boolean']>;
  /** Id */
  readonly id: Scalars['String'];
  /** Order */
  readonly order?: Maybe<DmpOrderById>;
  /** OrderId */
  readonly orderId?: Maybe<Scalars['String']>;
  /** When the order was placed */
  readonly orderPlaced?: Maybe<Scalars['String']>;
  /** Payment */
  readonly payment?: Maybe<PaymentDmp>;
  /** Complaint resolution */
  readonly resolution?: Maybe<Scalars['String']>;
  /** Complaint resolution comments */
  readonly resolutionComments?: Maybe<Scalars['String']>;
  /** Source */
  readonly source: Scalars['String'];
  /** status */
  readonly status: ComplaintStatus;
  /** Restaurant id */
  readonly storeId?: Maybe<Scalars['String']>;
  /** Complaints subcategory */
  readonly subcategory?: Maybe<Scalars['String']>;
  /** Sub Category Id */
  readonly subcategoryId?: Maybe<Scalars['String']>;
  /** Resolution type for ticket */
  readonly type?: Maybe<Scalars['String']>;
};

/** ComplaintDMPCategory */
export enum ComplaintDmpCategory {
  ACCURACY = 'ACCURACY',
  COMMENTS = 'COMMENTS',
  FULL_REFUND = 'FULL_REFUND',
  QUALITY = 'QUALITY',
  TIME = 'TIME',
}

/** Complaint pre-defined resolution comment */
export type ComplaintPredefinedComment = {
  readonly __typename: 'ComplaintPredefinedComment';
  /** comment */
  readonly comment: Scalars['String'];
  /** id */
  readonly id: Scalars['String'];
};

/** Complaint Status */
export enum ComplaintStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_REFUND = 'PENDING_REFUND',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED',
  VOUCHER_SENT = 'VOUCHER_SENT',
}

/** Complaints Customer */
export type ComplaintsCustomer = {
  readonly __typename: 'ComplaintsCustomer';
  /** E-mail */
  readonly email: Scalars['String'];
  /** Name */
  readonly name: Scalars['String'];
  /** Phone number */
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

/** A config offer. */
export type ConfigOffer = {
  readonly __typename: 'ConfigOffer';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** Type for a Restaurant coordinates. */
export type Coordinates = {
  readonly __typename: 'Coordinates';
  /** Latitude Area */
  readonly latitude: Scalars['Float'];
  /** Longitude Area */
  readonly longitude: Scalars['Float'];
};

export type CreatePolicy = {
  /** The content of the policy */
  readonly content: Scalars['String'];
};

/** Enum for DayPeriod. */
export enum DayPeriod {
  AFTERNOON = 'AFTERNOON',
  LATE_NIGHT = 'LATE_NIGHT',
}

/** Type for an delivery object that belongs to an order. */
export type Delivery = {
  readonly __typename: 'Delivery';
  /** Driver information. */
  readonly driver?: Maybe<OrderDriver>;
  /** Name of the driver. */
  readonly status: Scalars['String'];
};

/** Enum for DeliveryMode */
export enum DeliveryMode {
  MANAGED_BY_STORE = 'MANAGED_BY_STORE',
  MANAGED_EXTERNALLY = 'MANAGED_EXTERNALLY',
}

/** Cash limitations configuration for restaurant */
export type DeliveryOrderAmountLimit = {
  readonly __typename: 'DeliveryOrderAmountLimit';
  /** Max value of delivery order with physical payment */
  readonly deliveryOrderAmountLimit?: Maybe<Scalars['Float']>;
  /** Flag to enable deliveryOrderAmountLimit */
  readonly deliveryOrderAmountLimitEnabled?: Maybe<Scalars['Boolean']>;
  /** Flag to block order after repeated failures */
  readonly deliveryOrderRepeatedFailureLimitation?: Maybe<Scalars['Boolean']>;
  /** Max value for first delivery order with physical payment */
  readonly firstDeliveryOrder?: Maybe<Scalars['Float']>;
  /** Flag to enable firstDeliveryOrder */
  readonly firstDeliveryOrderEnabled?: Maybe<Scalars['Boolean']>;
};

export type DeliveryOrderAmountLimitInput = {
  /** Max value of delivery order with physical payment */
  readonly deliveryOrderAmountLimit?: InputMaybe<Scalars['Float']>;
  /** Flag to enable deliveryOrderAmountLimit */
  readonly deliveryOrderAmountLimitEnabled?: InputMaybe<Scalars['Boolean']>;
  /** Flag to block order after repeated failures */
  readonly deliveryOrderRepeatedFailureLimitation?: InputMaybe<Scalars['Boolean']>;
  /** Max value for first delivery order with physical payment */
  readonly firstDeliveryOrder?: InputMaybe<Scalars['Float']>;
  /** Flag to enable firstDeliveryOrder */
  readonly firstDeliveryOrderEnabled?: InputMaybe<Scalars['Boolean']>;
};

/** Type for a Restaurant delivery settings. */
export type DeliverySettings = {
  readonly __typename: 'DeliverySettings';
  /** Cost of delivery */
  readonly deliveryCost: Scalars['String'];
  /** Estimated Time Arrival */
  readonly eta: Scalars['Int'];
};

export type DeliverySettingsInput = {
  /** Delivery cost */
  readonly deliveryCost: Scalars['String'];
  /** ETA to delivery orders */
  readonly eta: Scalars['Int'];
};

/** Delivery status */
export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_NOT_REQUIRED = 'QUOTE_NOT_REQUIRED',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
}

/** Type for a Restaurant delivery time settings. */
export type DeliveryTimeSettings = {
  readonly __typename: 'DeliveryTimeSettings';
  /** Working hours of the restaurant */
  readonly periods: ReadonlyArray<Maybe<WorkingPeriod>>;
  /** Day of the week */
  readonly weekday: Scalars['String'];
};

/** DMP master relations */
export type DmpMasterRelations = {
  readonly __typename: 'DmpMasterRelations';
  /** Period of the day where master is configured */
  readonly dayPeriod: Scalars['String'];
  /** List o master restaurants */
  readonly masterRestaurants: ReadonlyArray<MasterRestaurantLinks>;
};

/** Type for a DMP order */
export type DmpOrder = {
  readonly __typename: 'DmpOrder';
  /** Channel in which the order was made */
  readonly channel: Scalars['String'];
  /** Date of order creation */
  readonly createdAt: Scalars['String'];
  /** property identifies how the order is managed */
  readonly deliveryMode: DeliveryMode;
  /** Order identifier to be displayed on the screen */
  readonly displayOrderId?: Maybe<Scalars['String']>;
  /** Driver full name */
  readonly driverName: Scalars['String'];
  /** Address formatted as street and number */
  readonly formattedAddress: Scalars['String'];
  /** Creation time of the last event */
  readonly lastEventTime: Scalars['String'];
  /** last mile delivery id in case the order is a last mile order */
  readonly lastMileDeliveryId?: Maybe<Scalars['String']>;
  /** last mile delivery provider in case the order is a last mile order */
  readonly lastMileProvider?: Maybe<Scalars['String']>;
  /** Order identifier */
  readonly orderId: Scalars['String'];
  /** Quadrant of the delivery */
  readonly quadrant: Scalars['String'];
  /** Restaurant identifier */
  readonly restaurantId: Scalars['String'];
  /** Current order status */
  readonly status: Scalars['String'];
};

/** Type for a DMP order by id */
export type DmpOrderById = {
  readonly __typename: 'DmpOrderById';
  /** Brand order */
  readonly brand: Brand;
  /** Cart order */
  readonly cart?: Maybe<OrderCart>;
  /** Channel in which the order was made */
  readonly channel: Scalars['String'];
  /** Compensation order */
  readonly compensation?: Maybe<OrderCompesation>;
  /** Date of order creation */
  readonly createdAt: Scalars['String'];
  /** Customer order */
  readonly customer: OrderCustomer;
  /** Delivery Address order */
  readonly deliveryAddress: Address;
  /** Delivery Id */
  readonly deliveryId?: Maybe<Scalars['String']>;
  /** Display Order Id */
  readonly displayOrderId?: Maybe<Scalars['String']>;
  /** Driver id, if assigned */
  readonly driverId?: Maybe<Scalars['String']>;
  /** Drop of fWindow */
  readonly dropoffWindow?: Maybe<TimeWindow>;
  /** Events order */
  readonly events: ReadonlyArray<OrderEvents>;
  /** Event URL */
  readonly eventsUrl?: Maybe<Scalars['String']>;
  /** Order id in database */
  readonly id: Scalars['String'];
  /** Instructions regarding the preparation */
  readonly instructions?: Maybe<Scalars['String']>;
  /** Payment order */
  readonly payment?: Maybe<PaymentDmp>;
  /** Pick Up Window */
  readonly pickupWindow?: Maybe<TimeWindow>;
  /** Quadrant */
  readonly quadrant: OrderQuadrant;
  /** Quote Id */
  readonly quoteId: Scalars['String'];
  /** RBI NumberId */
  readonly rbiNumberId: Scalars['String'];
  /** Region Code */
  readonly regionCode: Scalars['String'];
  /** Restaurant Id */
  readonly restaurantId: Scalars['String'];
  /** Status Order */
  readonly status: Scalars['String'];
  /** Date update Order */
  readonly updatedAt: Scalars['String'];
};

/** Type for a driver. */
export type Driver = {
  readonly __typename: 'Driver';
  /** True if the driver accepted to share his location, false otherwise. */
  readonly allowGeolocation?: Maybe<Scalars['Boolean']>;
  /** Current availability status for the driver. */
  readonly availabilityStatus?: Maybe<DriverAvailabilityStatus>;
  /** Date when the driver was created. */
  readonly createdAt?: Maybe<Scalars['String']>;
  /** Current delivery availability status for the driver. */
  readonly deliveryAvailabilityStatus?: Maybe<DriverDeliveryAvailabilityStatus>;
  /** Document number of the driver. */
  readonly document?: Maybe<Scalars['String']>;
  /** E-mail of the driver. */
  readonly email?: Maybe<Scalars['String']>;
  /** The first name of the driver. */
  readonly firstName?: Maybe<Scalars['String']>;
  /** Driver's identification number. */
  readonly identifier?: Maybe<Scalars['String']>;
  /** The last name of the driver. */
  readonly lastName?: Maybe<Scalars['String']>;
  /** Latitude value of driver's location. */
  readonly latitude?: Maybe<Scalars['Int']>;
  /** Longitude value of driver's location. */
  readonly longitude?: Maybe<Scalars['Int']>;
  /** Motorbike number being used by the driver. */
  readonly motorbike?: Maybe<Scalars['String']>;
  /** Maximum number of orders that the driver can deliver at a time. */
  readonly orderLimit?: Maybe<Scalars['Int']>;
  /** Id's of the orders assigned to this driver. */
  readonly ordersAssigned?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Driver's mobile phone number. */
  readonly phoneNumber?: Maybe<Scalars['String']>;
  /** Current status for the driver. */
  readonly realtimeStatus?: Maybe<DriverRealtimeStatus>;
  /** Restaurant name. */
  readonly restaurant?: Maybe<Scalars['String']>;
  /** Time the location was sent. Example: '2022-06-15T15:40Z' */
  readonly time?: Maybe<Scalars['String']>;
  /** Date when the driver was updated. */
  readonly updatedAt?: Maybe<Scalars['String']>;
};

/** Driver availability status */
export enum DriverAvailabilityStatus {
  DRIVER_OFFSHIFT = 'DRIVER_OFFSHIFT',
  DRIVER_WORKING = 'DRIVER_WORKING',
}

/** Driver delivery availability status */
export enum DriverDeliveryAvailabilityStatus {
  DRIVER_AVAILABLE = 'DRIVER_AVAILABLE',
  DRIVER_UNAVAILABLE = 'DRIVER_UNAVAILABLE',
}

/** Driver Realtime status */
export enum DriverRealtimeStatus {
  DRIVER_DELIVERING = 'DRIVER_DELIVERING',
  DRIVER_HEADING_RESTAURANT = 'DRIVER_HEADING_RESTAURANT',
  DRIVER_IN_RESTAURANT = 'DRIVER_IN_RESTAURANT',
}

export type Error = {
  readonly __typename: 'Error';
  /** Error code */
  readonly code: Scalars['String'];
  /** Error message */
  readonly message: Scalars['String'];
  /** Id of the product that triggered the error */
  readonly productId?: Maybe<Scalars['String']>;
  /** Id of the restaurant that triggered the error */
  readonly restaurantId?: Maybe<Scalars['String']>;
};

/** Error codes */
export enum ErrorCode {
  BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

/** Type for Eta Values */
export type EtaValues = {
  readonly __typename: 'EtaValues';
  /** List containing the eta options for the restaurant */
  readonly etaValues: ReadonlyArray<Scalars['String']>;
};

/** Geometry coordinates. */
export type GeoJson = {
  readonly __typename: 'GeoJson';
  /** Color that represents these coordinates on the map. */
  readonly color: Scalars['String'];
  /** Geometry coordinates list. */
  readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Scalars['Float']>>>;
};

export type History = {
  readonly __typename: 'History';
  /** Channel */
  readonly channel: Scalars['String'];
  /** Date of history creation */
  readonly date: Scalars['String'];
  /** Service mode */
  readonly serviceMode: Scalars['String'];
  /** Current history status */
  readonly status: Scalars['String'];
  /** History store ID */
  readonly storeId: Scalars['String'];
  /** History updates */
  readonly updates: ReadonlyArray<Maybe<HistoryUpdate>>;
  /** Email of the user that made the update */
  readonly userEmail: Scalars['String'];
  /** ID of the user that made the update */
  readonly userOktaId: Scalars['String'];
};

export type HistoryUpdate = {
  readonly __typename: 'HistoryUpdate';
  /** Availability of the product */
  readonly availability?: Maybe<Scalars['Boolean']>;
  /** Price of the product */
  readonly price?: Maybe<Scalars['Int']>;
  /** Product */
  readonly product: Scalars['String'];
};

/** Opening hours for the service modes of a restaurant. */
export type HoursOfOperation = {
  readonly __typename: 'HoursOfOperation';
  /** Additional time slot for friday */
  readonly friAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Friday closing time */
  readonly friClose?: Maybe<Scalars['String']>;
  /** Friday opening time */
  readonly friOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for monday */
  readonly monAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Monday closing time */
  readonly monClose?: Maybe<Scalars['String']>;
  /** Monday opening time */
  readonly monOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for saturday */
  readonly satAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Saturday closing time */
  readonly satClose?: Maybe<Scalars['String']>;
  /** Saturday opening time */
  readonly satOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for sunday */
  readonly sunAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Sunday closing time */
  readonly sunClose?: Maybe<Scalars['String']>;
  /** Sunday opening time */
  readonly sunOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for thursday */
  readonly thrAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Thursday closing time */
  readonly thrClose?: Maybe<Scalars['String']>;
  /** Thursday opening time */
  readonly thrOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for tuesday */
  readonly tueAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Tuesday closing time */
  readonly tueClose?: Maybe<Scalars['String']>;
  /** Tuesday opening time */
  readonly tueOpen?: Maybe<Scalars['String']>;
  /** Additional time slot for wednesday */
  readonly wedAdditionalTimeSlot?: Maybe<AdditionalTimeSlot>;
  /** Wednesday closing time */
  readonly wedClose?: Maybe<Scalars['String']>;
  /** Wednesday opening time */
  readonly wedOpen?: Maybe<Scalars['String']>;
};

/**
 * Single product displayed in the platform, such as Whopper or Coca Cola.
 * More Info: https://rbictg.atlassian.net/wiki/spaces/COG/pages/3445949536/Item
 */
export type Item = {
  readonly __typename: 'Item';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Parent products of this product */
  readonly parentProducts?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Menu sections in which it is located */
  readonly sections?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** Last mile provider specific configuration */
export type LastMileProviderConfiguration = {
  readonly __typename: 'LastMileProviderConfiguration';
  /** Disabled payment methods for the provider */
  readonly disabledPaymentMethods: ReadonlyArray<Scalars['String']>;
  /** Last mile provider */
  readonly provider: LastMileProviders;
};

export type LastMileProviderConfigurationInput = {
  /** Payment methods disabled for the last mile provider */
  readonly disabledPaymentMethods: ReadonlyArray<Scalars['String']>;
  /** Last mile provider of the configuration */
  readonly provider: LastMileProviders;
};

/** Last mile providers */
export enum LastMileProviders {
  CATCHER = 'Catcher',
  GLOVO = 'Glovo',
  STUART = 'Stuart',
  UBERDIRECT = 'UberDirect',
}

/** List of Last mile providers. */
export type LastMileProvidersObject = {
  readonly __typename: 'LastMileProvidersObject';
  /** Array of last mile providers returned. */
  readonly lastMileProviders?: Maybe<ReadonlyArray<Maybe<LastMileProviders>>>;
};

/** Last mile settings for a restaurant */
export type LastMileSettings = {
  readonly __typename: 'LastMileSettings';
  /** LocationId code used by Catcher last mile provider */
  readonly catcherLocationId?: Maybe<Scalars['String']>;
  /** List of last mile providers enabled in a restaurant */
  readonly providers: ReadonlyArray<Maybe<LastMileProviders>>;
  /** Providers specific configurations */
  readonly settings?: Maybe<ReadonlyArray<Maybe<LastMileProviderConfiguration>>>;
};

export type LastMileSettingsInput = {
  /** Location id used by catcher last mile provider */
  readonly catcherLocationId?: InputMaybe<Scalars['String']>;
  /** List of last mile providers in a restaurant */
  readonly providers?: InputMaybe<ReadonlyArray<InputMaybe<LastMileProviders>>>;
  /** Provider specific configurations */
  readonly settings?: InputMaybe<ReadonlyArray<LastMileProviderConfigurationInput>>;
};

export type MarketPaymentsInput = {
  readonly isOnlinePayment?: InputMaybe<Scalars['Boolean']>;
  readonly paymentMethodBrand: Scalars['String'];
  readonly paymentName?: InputMaybe<Scalars['String']>;
};

/** Master Links between restaurants */
export type MasterRestaurantLinks = {
  readonly __typename: 'MasterRestaurantLinks';
  /** If the user is allowed to modify the link */
  readonly allowModification: Scalars['Boolean'];
  /** List of restaurants linked to this master */
  readonly children: ReadonlyArray<ChildRestaurantLink>;
  /** Total number of links */
  readonly count: Scalars['Int'];
  /** Master restaurant id */
  readonly id: Scalars['String'];
  /** Master restaurant */
  readonly restaurant: RestaurantDelivery;
};

/** Master Links between restaurants */
export type MasterRestaurantRelation = {
  readonly __typename: 'MasterRestaurantRelation';
  /** Period of the day where master is configured */
  readonly dayPeriod: DayPeriod;
  /** Relationship id */
  readonly id: Scalars['String'];
  /** Master restaurant id */
  readonly masterId: Scalars['String'];
  /** Child restaurant id */
  readonly restaurantId: Scalars['String'];
};

/** Type for a MenuSelections */
export type MenuSelections = {
  readonly __typename: 'MenuSelections';
  /** Dmp Id */
  readonly dmpId?: Maybe<Scalars['String']>;
  /** External Reference Id */
  readonly externalReferenceId?: Maybe<Scalars['String']>;
  /** Id */
  readonly id?: Maybe<Scalars['String']>;
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Quantity */
  readonly price: Amount;
  /** Quantity */
  readonly quantity: Scalars['Int'];
  /** Reference Id */
  readonly referenceId?: Maybe<Scalars['String']>;
  /** Type of the item. Eg: Combo */
  readonly type?: Maybe<Scalars['String']>;
};

/**
 * Ingredients that the brand allows to get modfied in each item.
 * More Info: https://rbictg.atlassian.net/wiki/spaces/COG/pages/3445949584/Modifier
 */
export type Modifier = {
  readonly __typename: 'Modifier';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Name */
  readonly name: Scalars['String'];
};

/**
 * The options you can have for each of the ingredients that
 * the brand allows to get modified in each item.
 * More Info: https://rbictg.atlassian.net/wiki/spaces/COG/pages/3445949657/Modifier+Multiplier
 */
export type ModifierMultiplier = {
  readonly __typename: 'ModifierMultiplier';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Modifier of which this quantity applies to. */
  readonly modifier: Modifier;
  /** Name */
  readonly name: Scalars['String'];
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Presentational string such as "Extra" for "Extra cheese". */
  readonly prefix: Scalars['String'];
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** Quantity of modifiers. */
  readonly quantity: Scalars['Float'];
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Menu sections in which it is located */
  readonly sections?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

export type MultiConstantPlus = {
  readonly __typename: 'MultiConstantPlus';
  /** PLU value */
  readonly plu?: Maybe<Scalars['String']>;
  /** This field is only used in Tim Horton's because their PLUs need to have two separate values. */
  readonly qualifier?: Maybe<Scalars['String']>;
  /** Quantity */
  readonly quantity?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly _: Scalars['Boolean'];
  /** Approve Complaint */
  readonly approveComplaint?: Maybe<Complaint>;
  /** Approve Polygon to be main polygon */
  readonly approvePolygon?: Maybe<RestaurantPolygonList>;
  /** Cancel a polygon request for approval */
  readonly cancelPolygonRequestForApproval?: Maybe<RestaurantPolygonList>;
  /** Change driver info in order */
  readonly changeOrderDriver: RestaurantOrderDelivery;
  /** Update the status of a user */
  readonly changeUserStatus: OktaUser;
  /** Create new driver */
  readonly copyStorePlus?: Maybe<Scalars['String']>;
  /** Create new driver */
  readonly createDriver?: Maybe<Scalars['String']>;
  /** Create a link between a restaurant and a master restaurant given a day period */
  readonly createMasterRestaurantRelation: MasterRestaurantRelation;
  /** Create new polygon */
  readonly createPolygon?: Maybe<RestaurantPolygonList>;
  /** Deactivate driver (apply logical delete) */
  readonly deleteDriver?: Maybe<Scalars['String']>;
  /** delete a link between a restaurant and a master restaurant given a day period */
  readonly deleteMasterRestaurantRelation: Scalars['String'];
  /** Delete an existing polygon by Id */
  readonly deletePolygon?: Maybe<RestaurantPolygonList>;
  /** Delete the current policy */
  readonly deleteRiderAppPolicy?: Maybe<Scalars['String']>;
  /** Update the availability of one or more products at one or more stores */
  readonly deleteRole: Scalars['String'];
  /** Upload product prices for one or more restaurants using a CSV file */
  readonly oldUpdateMenuFromCSV: Scalars['String'];
  /** Create or update the privacy policy drivers need to accpet on Rider App */
  readonly putRiderAppPolicy?: Maybe<Scalars['String']>;
  /** Reject complaint that was pending approval */
  readonly rejectComplaint?: Maybe<Complaint>;
  /** Reject Polygon that was waiting for approval */
  readonly rejectPolygon?: Maybe<RestaurantPolygonList>;
  /** Send polygon for approval */
  readonly sendPolygonForApproval?: Maybe<RestaurantPolygonList>;
  /** Update DMP password */
  readonly updateDmpPassword?: Maybe<Scalars['String']>;
  /** Create new driver */
  readonly updateDriver?: Maybe<Scalars['String']>;
  /** Upload product prices for one or more restaurants using a CSV file */
  readonly updateMenuFromCSV: Scalars['String'];
  /** Update an existing polygon */
  readonly updatePolygon?: Maybe<RestaurantPolygon>;
  /** Update the availability of one or more products at one or more stores */
  readonly updateProductAvailability: ProductMutationResponse;
  /** Update the price of one or more products at one or more stores */
  readonly updateProductPrices: ProductMutationResponse;
  /** Update the price and availability of one or more products at one or more stores */
  readonly updateProducts: ProductMutationResponse;
  /** Update the cash limitation config for a list of restaurants sanity documents Ids */
  readonly updateRestaurantCashLimitation?: Maybe<ReadonlyArray<Restaurant>>;
  /** Update restaurant payment methods */
  readonly updateRestaurantPayments?: Maybe<ReadonlyArray<Restaurant>>;
  /** Update restaurant settings */
  readonly updateRestaurantSettings: RestaurantDmp;
  /** Create or update a role. */
  readonly upsertRole: Role;
  /** Create or update a user. */
  readonly upsertUser: OktaUser;
  /** Validate products updates */
  readonly validateProducts: ValidateProductsMutationResponse;
};

export type MutationApproveComplaintArgs = {
  complaintId: Scalars['String'];
  loyaltyEngineId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  refundAmount?: InputMaybe<Scalars['Int']>;
  restaurantId: Scalars['String'];
};

export type MutationApprovePolygonArgs = {
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationCancelPolygonRequestForApprovalArgs = {
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationChangeOrderDriverArgs = {
  name: Scalars['String'];
  orderId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type MutationChangeUserStatusArgs = {
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
  oktaId: Scalars['String'];
  restaurantIds: ReadonlyArray<Scalars['String']>;
  role: Scalars['String'];
  userName: Scalars['String'];
};

export type MutationCopyStorePlusArgs = {
  fromStoreId: Scalars['String'];
  toGroupIds: ReadonlyArray<InputMaybe<Scalars['String']>>;
  toStoreIds: ReadonlyArray<Scalars['String']>;
};

export type MutationCreateDriverArgs = {
  body: CreateDriverPayload;
  restaurantId: Scalars['String'];
};

export type MutationCreateMasterRestaurantRelationArgs = {
  dayPeriod: DayPeriod;
  masterId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationCreatePolygonArgs = {
  body: CreatePolygonPayload;
  restaurantId: Scalars['String'];
};

export type MutationDeleteDriverArgs = {
  driverId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationDeleteMasterRestaurantRelationArgs = {
  dayPeriod: DayPeriod;
  masterId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationDeletePolygonArgs = {
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationDeleteRoleArgs = {
  roleName: Scalars['String'];
};

export type MutationOldUpdateMenuFromCsvArgs = {
  items: ReadonlyArray<OldUpdateMenuFromCsvInput>;
  storeId: Scalars['String'];
};

export type MutationPutRiderAppPolicyArgs = {
  body: CreatePolicy;
};

export type MutationRejectComplaintArgs = {
  complaintId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationRejectPolygonArgs = {
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationSendPolygonForApprovalArgs = {
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationUpdateDmpPasswordArgs = {
  body: ChangeDmpPasswordInput;
  restaurantId: Scalars['String'];
};

export type MutationUpdateDriverArgs = {
  body?: InputMaybe<UpdateDriverPayload>;
  confirmPassword?: InputMaybe<Scalars['String']>;
  driverId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  restaurantId: Scalars['String'];
};

export type MutationUpdateMenuFromCsvArgs = {
  channel: Channel;
  items: ReadonlyArray<UpdateMenuFromCsvInput>;
  storeId: Scalars['String'];
};

export type MutationUpdatePolygonArgs = {
  body: UpdatePolygonPayload;
  polygonId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type MutationUpdateProductAvailabilityArgs = {
  products: ReadonlyArray<ProductAvailabilityToUpdate>;
  serviceMode?: InputMaybe<ServiceMode>;
  v3?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateProductPricesArgs = {
  products: ReadonlyArray<ProductPriceToUpdate>;
  serviceMode?: InputMaybe<ServiceMode>;
  v3?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateProductsArgs = {
  updates: UpdatesInput;
  v3?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateRestaurantCashLimitationArgs = {
  cashLimitationUpdate?: InputMaybe<DeliveryOrderAmountLimitInput>;
  documentIds: ReadonlyArray<Scalars['String']>;
};

export type MutationUpdateRestaurantPaymentsArgs = {
  documentIds: ReadonlyArray<Scalars['String']>;
  marketPayments: ReadonlyArray<MarketPaymentsInput>;
  paymentsUpdate: ReadonlyArray<PaymentMethodInput>;
};

export type MutationUpdateRestaurantSettingsArgs = {
  body: Settings;
  restaurantId: Scalars['String'];
};

export type MutationUpsertRoleArgs = {
  permissions: ReadonlyArray<Roles>;
  roleId?: InputMaybe<Scalars['String']>;
  roleName: Scalars['String'];
};

export type MutationUpsertUserArgs = {
  email: Scalars['String'];
  isNewUser?: InputMaybe<Scalars['Boolean']>;
  oktaId: Scalars['String'];
  restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  roleId: Scalars['String'];
  userName: Scalars['String'];
};

export type MutationValidateProductsArgs = {
  updates: UpdatesInput;
  v3?: InputMaybe<Scalars['Boolean']>;
};

/** DMP Notification */
export type Notification = {
  readonly __typename: 'Notification';
  /** Creation date in iso string */
  readonly createdAt: Scalars['String'];
  /** Notification type */
  readonly eventType: Scalars['String'];
  /** Updated restaurant settings */
  readonly eventValue: RestaurantSettings;
  /** Notification id */
  readonly id: Scalars['String'];
  /** Notification's restaurant id */
  readonly restaurantId: Scalars['String'];
  /** Notification's restaurant name */
  readonly restaurantName: Scalars['String'];
};

/** Notification settings for a Restaurant. */
export type NotificationSettings = {
  readonly __typename: 'NotificationSettings';
  /** Enable Delivery Area notification */
  readonly deliveryArea: Scalars['Boolean'];
  /** Enable Eta notification */
  readonly eta: Scalars['Boolean'];
  /** Enable Pause Service notification */
  readonly pauseService: Scalars['Boolean'];
  /** Enable POS Offline notification */
  readonly posOffline: Scalars['Boolean'];
};

export type NotificationSettingsInput = {
  /** Enable Delivery Area notification */
  readonly deliveryArea: Scalars['Boolean'];
  /** Enable Eta notification */
  readonly eta: Scalars['Boolean'];
  /** Enable Pause Service notification */
  readonly pauseService: Scalars['Boolean'];
  /** Enable POS Offline notification */
  readonly posOffline: Scalars['Boolean'];
};

/** An offer for an item/combo. */
export type Offer = {
  readonly __typename: 'Offer';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** A User created and managed through the portal. */
export type OktaUser = {
  readonly __typename: 'OktaUser';
  /** Country where the user is from. */
  readonly Country: Scalars['String'];
  /** Creation date. */
  readonly CreatedAt?: Maybe<Scalars['String']>;
  /** User who created the role. */
  readonly CreatedBy?: Maybe<Scalars['String']>;
  /** Email of the user */
  readonly Email?: Maybe<Scalars['String']>;
  /** Okta ID with which the user was created */
  readonly OktaId: Scalars['String'];
  /** Permissions associated to the user role. */
  readonly Permissions?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** List of restaurants assigned to the user. */
  readonly RestaurantIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Id of the role assigned. */
  readonly RoleId: Scalars['String'];
  /** Name of the role assigned. */
  readonly RoleName?: Maybe<Scalars['String']>;
  /** Whether the user is activated in the portal or not. */
  readonly Status?: Maybe<Scalars['Boolean']>;
  /** Update date. */
  readonly UpdatedAt?: Maybe<Scalars['String']>;
  /** User who updated the role. */
  readonly UpdatedBy?: Maybe<Scalars['String']>;
  /** Name of the user */
  readonly UserName?: Maybe<Scalars['String']>;
};

export type OldUpdateMenuFromCsvInput = {
  /** Product id */
  readonly plu: Scalars['String'];
  /** Product price */
  readonly price: Scalars['Int'];
};

/** Type for a OrderCart */
export type OrderCart = {
  readonly __typename: 'OrderCart';
  /** Menu selections */
  readonly menuSelections?: Maybe<ReadonlyArray<Maybe<OrderCartMenuSelections>>>;
};

/** Type for a OrderCart */
export type OrderCartComplaints = {
  readonly __typename: 'OrderCartComplaints';
  /** DMP Id */
  readonly dmpId: Scalars['String'];
  /** Menu selections */
  readonly menuSelections?: Maybe<ReadonlyArray<Maybe<OrderCartMenuSelections>>>;
};

/** Type for a OrderCartMenuSelections */
export type OrderCartMenuSelections = {
  readonly __typename: 'OrderCartMenuSelections';
  /** Dmp Id */
  readonly dmpId?: Maybe<Scalars['String']>;
  /** External Reference Id */
  readonly externalReferenceId?: Maybe<Scalars['String']>;
  /** Id */
  readonly id?: Maybe<Scalars['String']>;
  /** Menu Selections */
  readonly menuSelections?: Maybe<ReadonlyArray<Maybe<MenuSelections>>>;
  /** Name */
  readonly name?: Maybe<Scalars['String']>;
  /** Quantity */
  readonly price: Amount;
  /** Quantity */
  readonly quantity: Scalars['Int'];
  /** Reference Id */
  readonly referenceId?: Maybe<Scalars['String']>;
  /** Type of the item. Eg: Combo */
  readonly type?: Maybe<Scalars['String']>;
};

/** Type for a OrderCompesation */
export type OrderCompesation = {
  readonly __typename: 'OrderCompesation';
  /** Menu selections */
  readonly menuSelections?: Maybe<ReadonlyArray<Maybe<OrderCartMenuSelections>>>;
};

/** Type for a OrderCustomer */
export type OrderCustomer = {
  readonly __typename: 'OrderCustomer';
  /** Emailcustomer */
  readonly email?: Maybe<Scalars['String']>;
  /** External reference id Customer */
  readonly externalReferenceId?: Maybe<Scalars['String']>;
  /** Id customer */
  readonly id?: Maybe<Scalars['String']>;
  /** Name customer */
  readonly name: Scalars['String'];
  /** Phone customer */
  readonly phone?: Maybe<Scalars['String']>;
  /** Username customer */
  readonly username?: Maybe<Scalars['String']>;
};

/** Type for a driver objects that belongs to an order. */
export type OrderDriver = {
  readonly __typename: 'OrderDriver';
  /** ID of the driver. */
  readonly driverId?: Maybe<Scalars['String']>;
  /** Name of the driver. */
  readonly name?: Maybe<Scalars['String']>;
  /** Phone number of the driver. */
  readonly phoneNumber?: Maybe<Scalars['String']>;
};

/** Type for a OrderEvents */
export type OrderEvents = {
  readonly __typename: 'OrderEvents';
  /** Issue of the event, if there is one */
  readonly issue?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Event name */
  readonly name: Scalars['String'];
  /** Event time */
  readonly time: Scalars['String'];
};

/** Type for a OrderQuadrant */
export type OrderQuadrant = {
  readonly __typename: 'OrderQuadrant';
  /** Name quadrant */
  readonly name: Scalars['String'];
};

/** Order status */
export enum OrderStatus {
  CATERING_DECLINED = 'CATERING_DECLINED',
  CATERING_ERROR = 'CATERING_ERROR',
  CATERING_PLACED = 'CATERING_PLACED',
  CATERING_SUCCESSFUL = 'CATERING_SUCCESSFUL',
  COMMIT_ERROR = 'COMMIT_ERROR',
  INSERT_ERROR = 'INSERT_ERROR',
  INSERT_REQUESTED = 'INSERT_REQUESTED',
  INSERT_SUCCESSFUL = 'INSERT_SUCCESSFUL',
  KOUNT_DENIAL = 'KOUNT_DENIAL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PRICE_ERROR = 'PRICE_ERROR',
  PRICE_REQUESTED = 'PRICE_REQUESTED',
  PRICE_SUCCESSFUL = 'PRICE_SUCCESSFUL',
  QUEUED = 'QUEUED',
  QUEUE_ERROR = 'QUEUE_ERROR',
  REFUND_ERROR = 'REFUND_ERROR',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_SUCCESSFUL = 'UPDATE_SUCCESSFUL',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

/** Type for a DMP order */
export type OrdersHistory = {
  readonly __typename: 'OrdersHistory';
  /** Channel in which the order was made */
  readonly channel: Scalars['String'];
  /** Date of order creation */
  readonly createdAt: Scalars['Float'];
  /** Customer email */
  readonly customerEmail?: Maybe<Scalars['String']>;
  /** Order identifier to be displayed on the screen */
  readonly displayOrderId?: Maybe<Scalars['String']>;
  /** Id of the driver */
  readonly driverId?: Maybe<Scalars['String']>;
  /** Order events */
  readonly events: ReadonlyArray<OrderEvents>;
  /** Method used to pay the order */
  readonly paymentMethod?: Maybe<Scalars['String']>;
  /** Total of the order in cents */
  readonly paymentTotal?: Maybe<Scalars['Int']>;
  /** Order internal identifier */
  readonly rbiNumberId: Scalars['String'];
  /** Restaurant identifier */
  readonly restaurantId: Scalars['String'];
  /** Name of the restaurant */
  readonly restaurantName?: Maybe<Scalars['String']>;
  /** Current order status */
  readonly status: Scalars['String'];
};

export enum OverrideId {
  LATENIGHT = 'lateNight',
}

export type OverrideInput = {
  /** Id of the override */
  readonly id: OverrideId;
  /** Price of the override */
  readonly price: Scalars['Int'];
};

/** Hours in which the product will be available for a given store, service mode and channel. */
export type Overrides = {
  readonly __typename: 'Overrides';
  /** Id of the price override option */
  readonly id: Scalars['String'];
  /** Price that should override */
  readonly price: Scalars['Int'];
};

export type OverridesResponse = {
  readonly __typename: 'OverridesResponse';
  /** End time of the period of availability */
  readonly id: Scalars['String'];
  /** Start time of the period of availability */
  readonly price: Scalars['Int'];
};

export type ParentChildPlu = {
  readonly __typename: 'ParentChildPlu';
  /** Child PLU value */
  readonly childPlu?: Maybe<Scalars['String']>;
  /** PLU value */
  readonly plu?: Maybe<Scalars['String']>;
};

/** Type for a PaymentDmp */
export type PaymentDmp = {
  readonly __typename: 'PaymentDmp';
  /** Amount Left To Be Paid */
  readonly amountLeftToBePaid: Amount;
  /** Payment Method */
  readonly paymentMethod: Scalars['String'];
  /** Payment Method Brand */
  readonly paymentMethodBrand?: Maybe<Scalars['String']>;
  /** Tip */
  readonly tip: Amount;
  /** Total */
  readonly total: Amount;
};

/** Payment method */
export type PaymentMethod = {
  readonly __typename: 'PaymentMethod';
  /** If the payment is processed online or offline */
  readonly isOnlinePayment: Scalars['Boolean'];
  /** Payment method brand key */
  readonly paymentMethodBrand: Scalars['String'];
  /** Payment name */
  readonly paymentName: Scalars['String'];
};

export type PaymentMethodInput = {
  readonly isOnlinePayment: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly paymentMethodBrand: Scalars['String'];
  readonly state: Scalars['Boolean'];
};

/** Payment Method */
export enum PaymentType {
  APPLE_PAY = 'APPLE_PAY',
  CASH = 'CASH',
  CREDIT_ANONYMOUS = 'CREDIT_ANONYMOUS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  VAULTED_ACCOUNT = 'VAULTED_ACCOUNT',
}

/** Physical address of a restaurant. */
export type PhysicalAddress = {
  readonly __typename: 'PhysicalAddress';
  /** First line of the address */
  readonly address1?: Maybe<Scalars['String']>;
  /** Second line of the address */
  readonly address2?: Maybe<Scalars['String']>;
  /** City */
  readonly city?: Maybe<Scalars['String']>;
  /** Country */
  readonly country?: Maybe<Scalars['String']>;
  /** Postal or zip code */
  readonly postalCode?: Maybe<Scalars['String']>;
  /** State or province */
  readonly stateProvince?: Maybe<Scalars['String']>;
};

/**
 * PluField types.
 * deliveryPlus for delivery.
 * plus for the other service modes.
 */
export enum PluField {
  DELIVERYPLUS = 'deliveryPlus',
  PLUS = 'plus',
}

export type PlusInput = {
  /** Product availability */
  readonly availability?: InputMaybe<Scalars['Boolean']>;
  /** Determines whether a product is in draft state */
  readonly draft?: InputMaybe<Scalars['String']>;
  /** Pricing overrides */
  readonly overrides?: InputMaybe<ReadonlyArray<OverrideInput>>;
  /** Product plu */
  readonly plu: Scalars['String'];
  /** Product price */
  readonly price?: InputMaybe<Scalars['Int']>;
  /** Product priority */
  readonly priority?: InputMaybe<Scalars['Int']>;
  /** Repetition of the hour periods of availability */
  readonly repetition?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Sanity ID */
  readonly sanityId?: InputMaybe<Scalars['String']>;
};

export type PlusResponse = {
  readonly __typename: 'PlusResponse';
  /** Product availability */
  readonly availability?: Maybe<Scalars['Boolean']>;
  /** Determines whether a product is in draft state */
  readonly draft?: Maybe<Scalars['String']>;
  /** Hour periods in which the item will be available */
  readonly overrides?: Maybe<ReadonlyArray<OverridesResponse>>;
  /** Product plu */
  readonly plu: Scalars['String'];
  /** Product price */
  readonly price?: Maybe<Scalars['Int']>;
  /** Product priority */
  readonly priority?: Maybe<Scalars['Int']>;
  /** Repetition of the hour periods of availability */
  readonly repetition?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Sanity ID */
  readonly sanityId?: Maybe<Scalars['String']>;
};

/** Polygons grid quadrant. */
export type PolygonsGridQuadrant = {
  readonly __typename: 'PolygonsGridQuadrant';
  /** Identifier of the polygon. */
  readonly id: Scalars['String'];
  /** GeoJSON for the quadrant.. */
  readonly polygon: GeoJson;
};

/** POS Status */
export type PosStatus = {
  readonly __typename: 'PosStatus';
  /** Restaurant available */
  readonly available: Scalars['Boolean'];
  /** Update status */
  readonly updatedAt: Scalars['String'];
};

/** POS vendor of a restaurant. */
export type PosVendor = {
  readonly __typename: 'PosVendor';
  readonly vendor?: Maybe<Scalars['String']>;
};

/** Type for a powerBIToken. */
export type PowerBiToken = {
  readonly __typename: 'PowerBiToken';
  /** Access Token. */
  readonly accessToken: Scalars['String'];
  /** Embed Url. */
  readonly embedUrl: Scalars['String'];
  /** Expiry. */
  readonly expiry: Scalars['String'];
  /** Report id. */
  readonly reportId: Scalars['String'];
};

/** Union of all Products (distinguishable by the '__typename') */
export type Product =
  | Combo
  | ConfigOffer
  | Item
  | ModifierMultiplier
  | Offer
  | Reward
  | SystemwideOffer;

export type ProductAvailabilityToUpdate = {
  /** New availability of the product */
  readonly availability: Scalars['Boolean'];
  /** Composite Id containing restaurantNumber#productId */
  readonly id: Scalars['String'];
  /** PLU of the product */
  readonly plu: Scalars['String'];
  /** Current price of the product or null */
  readonly price?: InputMaybe<Scalars['Int']>;
};

/** An element in the hierarchy of products contained within a product. */
export type ProductHierarchy = {
  readonly __typename: 'ProductHierarchy';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Product Name */
  readonly name: Scalars['String'];
  /** List of related products */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** Product Type */
  readonly type: Scalars['String'];
};

export type ProductMutationResponse = {
  readonly __typename: 'ProductMutationResponse';
  /** Data of the products that were mutated correctly */
  readonly data: ReadonlyArray<ProductPrice>;
  /** Non-fatal errors that happened during the mutation */
  readonly errors?: Maybe<ReadonlyArray<Maybe<Error>>>;
};

/** A combination of item and price which corresponds to a given restaurant. */
export type ProductPrice = {
  readonly __typename: 'ProductPrice';
  /** Availability of an item for a given store. */
  readonly availability?: Maybe<Scalars['Boolean']>;
  /** A composite key in the format '{restaurant id}#{item id}'. */
  readonly id: Scalars['ID'];
  /** PLU of the product */
  readonly plu: Scalars['String'];
  /** Price of an item for a given store. */
  readonly price?: Maybe<Scalars['Int']>;
  /** Product id. */
  readonly productId: Scalars['String'];
};

export type ProductPriceToUpdate = {
  /** Current availability of the product */
  readonly availability: Scalars['Boolean'];
  /** Composite Id containing restaurantNumber#productId */
  readonly id: Scalars['String'];
  /** PLU of the product */
  readonly plu: Scalars['String'];
  /** New price of the product */
  readonly price: Scalars['Int'];
};

/**
 * A combination of product and price/availability which corresponds to a given restaurant, service
 * mode and channel.
 */
export type ProductPriceV3 = {
  readonly __typename: 'ProductPriceV3';
  /** Availability of the product for a given store, service mode and channel. */
  readonly availability?: Maybe<Scalars['Boolean']>;
  /** Channel. */
  readonly channel: Scalars['String'];
  /** Draft status of the product. */
  readonly draft?: Maybe<Scalars['String']>;
  /** A composite key in the format '{restaurant id}#{service mode}#{channel}'. */
  readonly id: Scalars['ID'];
  /** Price overrides */
  readonly overrides?: Maybe<ReadonlyArray<Overrides>>;
  /** Product PLU (depends on the POS vendor of the restauran, service mode and channel). */
  readonly plu: Scalars['String'];
  /** Price of the product for a given store, service mode and channel. */
  readonly price?: Maybe<Scalars['Int']>;
  /** Priority of the price/availability applied to the product. */
  readonly priority?: Maybe<Scalars['Int']>;
  /** Repetition of the hour periods of availability. */
  readonly repetition?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Restaurant id (number) */
  readonly restaurantId: Scalars['String'];
  /** Service mode. */
  readonly serviceMode: Scalars['String'];
};

export type QuantityBasedPlu = {
  readonly __typename: 'QuantityBasedPlu';
  /** PLU value */
  readonly plu?: Maybe<Scalars['String']>;
  /** This field is only used in Tim Horton's because their PLUs need to have two separate values. */
  readonly qualifier?: Maybe<Scalars['String']>;
  /** Quantity */
  readonly quantity?: Maybe<Scalars['Int']>;
};

export type Query = {
  readonly __typename: 'Query';
  readonly _: Scalars['Boolean'];
  /** Get restaurants. */
  readonly allChannels: ReadonlyArray<ChannelConfigOption>;
  /** Get dmp active orders from all restaurants in the list */
  readonly allDmpOrders: AllDmpOrders;
  /** Get drivers. */
  readonly allDrivers?: Maybe<AllDrivers>;
  /** Get orders. */
  readonly allOrders?: Maybe<AllOrders>;
  /** Get all restaurant groups from sanity */
  readonly allRestaurantGroups: AllRestaurantGroups;
  /** Get restaurants. */
  readonly allRestaurants?: Maybe<AllRestaurants>;
  /** Get restaurants. */
  readonly allRestaurantsDelivery?: Maybe<AllRestaurantsDelivery>;
  /** Get service status for all restaurants in the list */
  readonly allRestaurantsServiceStatus: ReadonlyArray<Maybe<AllRestaurantsServiceStatus>>;
  /** Get closed orders from a given restaurant in the last 24 hours. */
  readonly closedOrders: ReadonlyArray<RestaurantOrderDelivery>;
  /** Get possible comments */
  readonly complaintComments: ReadonlyArray<ComplaintPredefinedComment>;
  /** Get complaint details */
  readonly complaintDetails: Complaint;
  /** Get complaints */
  readonly complaints: ReadonlyArray<Complaint>;
  /** Get driver by Id. */
  readonly driver?: Maybe<Driver>;
  /** Get eta possible values for a restaurant */
  readonly etaValues: EtaValues;
  /** Get order by Id. */
  readonly getDmpOrderById: DmpOrderById;
  /** Get last mile providers by a given country and brand. */
  readonly getLastMileProviders?: Maybe<LastMileProvidersObject>;
  /** Get market payment methods. */
  readonly getPaymentMethods?: Maybe<ReadonlyArray<Maybe<PaymentMethod>>>;
  /** Get restaurant group by id from sanity */
  readonly getRestaurantGroupsByIds?: Maybe<ReadonlyArray<Maybe<RestaurantGroup>>>;
  /** Get voucher for complaint */
  readonly getVoucherComplaint?: Maybe<ReadonlyArray<Maybe<VoucherComplaint>>>;
  /** Get all history. */
  readonly history?: Maybe<AllHistory>;
  /** Get master restaurants. */
  readonly masterRestaurantsDelivery: ReadonlyArray<DmpMasterRelations>;
  /** Get all products of the default menu, including offers and rewards. */
  readonly menu?: Maybe<Scalars['String']>;
  /** Get polygons for a list of restaurants */
  readonly multiplePolygons: ReadonlyArray<RestaurantPolygonList>;
  /** Get new notifications for logged user */
  readonly notifications: ReadonlyArray<Notification>;
  /** Get Orders history from Snowflake. */
  readonly ordersHistory: ReadonlyArray<Maybe<OrdersHistory>>;
  /** Get polygons. */
  readonly polygons?: Maybe<RestaurantPolygonList>;
  /** Get PowerBI token. */
  readonly powerBiToken?: Maybe<PowerBiToken>;
  /** Get restaurant by Id. */
  readonly restaurant: RestaurantDmp;
  /** Get the current rider app privacy policy */
  readonly riderAppPrivacyPolicy?: Maybe<RiderAppPrivacyPolicy>;
  /** Get all roles for the country of the logged user. */
  readonly roles?: Maybe<ReadonlyArray<Maybe<Role>>>;
  /** Get restaurants. */
  readonly userRestaurants?: Maybe<AllRestaurants>;
  /** Get the requested user or all users for the country of the logged user. */
  readonly users?: Maybe<ReadonlyArray<Maybe<OktaUser>>>;
};

export type QueryAllDmpOrdersArgs = {
  restaurantIds: ReadonlyArray<Scalars['String']>;
};

export type QueryAllDriversArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  restaurantId: Scalars['String'];
};

export type QueryAllOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type QueryAllRestaurantGroupsArgs = {
  where?: InputMaybe<AllRestaurantGroupsParams>;
};

export type QueryAllRestaurantsArgs = {
  where?: InputMaybe<AllRestaurantsArgs>;
};

export type QueryAllRestaurantsDeliveryArgs = {
  where?: InputMaybe<AllRestaurantsDeliveryArgs>;
};

export type QueryAllRestaurantsServiceStatusArgs = {
  restaurantIds: ReadonlyArray<Scalars['String']>;
};

export type QueryClosedOrdersArgs = {
  restaurantId: Scalars['String'];
};

export type QueryComplaintCommentsArgs = {
  complaintId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type QueryComplaintDetailsArgs = {
  complaintId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type QueryComplaintsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  restaurantsIds: ReadonlyArray<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryDriverArgs = {
  driverId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type QueryEtaValuesArgs = {
  restaurantId: Scalars['String'];
};

export type QueryGetDmpOrderByIdArgs = {
  orderId: Scalars['String'];
  restaurantId: Scalars['String'];
};

export type QueryGetRestaurantGroupsByIdsArgs = {
  groupId: ReadonlyArray<Scalars['String']>;
};

export type QueryHistoryArgs = {
  restaurantId: Scalars['String'];
  source: Scalars['String'];
  startKey: Scalars['String'];
  status: Scalars['String'];
  userOktaId: Scalars['String'];
};

export type QueryMenuArgs = {
  region?: InputMaybe<Scalars['String']>;
};

export type QueryOrdersHistoryArgs = {
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  evaluatePaymentMethodBrand?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPolygonsArgs = {
  restaurantId: Scalars['String'];
};

export type QueryPowerBiTokenArgs = {
  reportKey: ReportKey;
};

export type QueryRestaurantArgs = {
  restaurantId: Scalars['String'];
};

export type QueryUserRestaurantsArgs = {
  where?: InputMaybe<AllRestaurantsArgs>;
};

export type QueryUsersArgs = {
  oktaId?: InputMaybe<Scalars['String']>;
};

/** Region codes */
export enum RegionCode {
  AQ = 'AQ',
  CA = 'CA',
  CH = 'CH',
  DE = 'DE',
  ES = 'ES',
  GB = 'GB',
  IT = 'IT',
  NZ = 'NZ',
  PT = 'PT',
  US = 'US',
  ZA = 'ZA',
}

export enum ReportKey {
  APP_ENGAGEMENT = 'APP_ENGAGEMENT',
  CASH_FLOW = 'CASH_FLOW',
  CUSTOMIZED_REPORT = 'CUSTOMIZED_REPORT',
  ORDER_HISTORY = 'ORDER_HISTORY',
  PAUSE_SERVICE = 'PAUSE_SERVICE',
}

/**
 * Restaurant type containing restaurant data and prices of said restaurant for a given
 * product/service mode.
 */
export type Restaurant = {
  readonly __typename: 'Restaurant';
  /** Restaurant availability */
  readonly availability?: Maybe<AvailabilityModes>;
  /** Hours of operation for Curbside */
  readonly curbsideHours?: Maybe<HoursOfOperation>;
  /** Hours of operation for Delivery */
  readonly deliveryHours?: Maybe<HoursOfOperation>;
  /** Cash limitation configuration for restaurant */
  readonly deliveryOrderAmountLimit?: Maybe<DeliveryOrderAmountLimit>;
  /** Hours of operation for Dining Room */
  readonly diningRoomHours?: Maybe<HoursOfOperation>;
  /** Hours of operation for Drive Thru */
  readonly driveThruHours?: Maybe<HoursOfOperation>;
  /** Environment dev/staging/prod */
  readonly environment?: Maybe<Scalars['String']>;
  /** Groups */
  readonly groups?: Maybe<Scalars['String']>;
  /** True if Curbside is available */
  readonly hasCurbside?: Maybe<Scalars['Boolean']>;
  /** True if Delivery is available */
  readonly hasDelivery?: Maybe<Scalars['Boolean']>;
  /** True if Dine In is available */
  readonly hasDineIn?: Maybe<Scalars['Boolean']>;
  /** True if Drive Thru is available */
  readonly hasDriveThru?: Maybe<Scalars['Boolean']>;
  /** True if lateNight pricing override is available */
  readonly hasLateNightPricing?: Maybe<Scalars['Boolean']>;
  /** True if Mobile Ordering is available */
  readonly hasMobileOrdering?: Maybe<Scalars['Boolean']>;
  /** Restaurant number (serves as the Id) */
  readonly id: Scalars['ID'];
  /** Internal name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Latitude */
  readonly latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  readonly longitude?: Maybe<Scalars['Float']>;
  /** Prices on the restaurant for a given product/service mode */
  readonly menuPrices?: Maybe<ReadonlyArray<ProductPrice>>;
  /** Prices of the requested set of products for the restaurant for all service mode/channel combinations */
  readonly menuPricesV3?: Maybe<ReadonlyArray<ProductPriceV3>>;
  /** Status of Mobile Ordering */
  readonly mobileOrderingStatus?: Maybe<Scalars['String']>;
  /** Name (usually a composite of the address) */
  readonly name: Scalars['String'];
  /** Payment methods configured for the restaurant */
  readonly paymentMethods?: Maybe<ReadonlyArray<Maybe<RestaurantPaymentMethod>>>;
  /** Full address */
  readonly physicalAddress?: Maybe<PhysicalAddress>;
  /** POS Vendor of a restaurant */
  readonly pos?: Maybe<PosVendor>;
  /** Restaurant sanity document ID */
  readonly sanityDocumentId?: Maybe<Scalars['String']>;
  /** POS vendor */
  readonly vendor?: Maybe<Scalars['String']>;
};

/**
 * Restaurant type containing restaurant data and prices of said restaurant for a given
 * product/service mode.
 */
export type RestaurantMenuPricesArgs = {
  productIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  serviceMode?: InputMaybe<ServiceMode>;
};

/**
 * Restaurant type containing restaurant data and prices of said restaurant for a given
 * product/service mode.
 */
export type RestaurantMenuPricesV3Args = {
  channel: Channel;
  productIdPlus?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  serviceMode: ServiceMode;
};

/** Type for a Restaurant delivery settings. */
export type RestaurantCapacity = {
  readonly __typename: 'RestaurantCapacity';
  /** Restaurant Capacity */
  readonly capacity: Scalars['String'];
  /** Time that capacity was updated */
  readonly updatedAt: Scalars['String'];
};

/** RestaurantDelivery type containing restaurant with delivery. */
export type RestaurantDelivery = {
  readonly __typename: 'RestaurantDelivery';
  /** Restaurant availability */
  readonly availability?: Maybe<AvailabilityModes>;
  /** Hours of operation for Delivery */
  readonly deliveryHours?: Maybe<HoursOfOperation>;
  /** Environment dev/staging/prod */
  readonly environment?: Maybe<Scalars['String']>;
  /** Franchise Group Id */
  readonly franchiseGroupId?: Maybe<Scalars['Float']>;
  /** Franchise Group Name */
  readonly franchiseGroupName?: Maybe<Scalars['String']>;
  /** True if Delivery is available */
  readonly hasDelivery?: Maybe<Scalars['Boolean']>;
  /** Restaurant number (serves as the Id) */
  readonly id: Scalars['ID'];
  /** Internal restaurant name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Latitude */
  readonly latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  readonly longitude?: Maybe<Scalars['Float']>;
  /** Name (usually a composite of the address) */
  readonly name: Scalars['String'];
  /** Phone number */
  readonly phoneNumber?: Maybe<Scalars['String']>;
  /** Full address */
  readonly physicalAddress?: Maybe<PhysicalAddress>;
};

/** Restaurant delivery status */
export enum RestaurantDeliveryStatus {
  CLOSED = 'CLOSED',
  ONLINE = 'ONLINE',
  PAUSED = 'PAUSED',
  PAUSED_BY_CORP = 'PAUSED_BY_CORP',
}

/** Type for a Restaurant. */
export type RestaurantDmp = {
  readonly __typename: 'RestaurantDmp';
  /** Restaurant address */
  readonly address: Address;
  /** Restaurant brand */
  readonly brand: Brand;
  /** Country code in ALPHA-2 ISO code */
  readonly country: RegionCode;
  /** Restaurant id */
  readonly id: Scalars['String'];
  /** ID of the restaurant defined as late night restaurant */
  readonly lateNightRestaurantId: Scalars['String'];
  /** Restaurant name */
  readonly name: Scalars['String'];
  /** Restaurant phone number */
  readonly phoneNumber: Scalars['String'];
  /** Restaurant settings */
  readonly settings: RestaurantSettings;
};

/** Group of a restaurant. */
export type RestaurantGroup = {
  readonly __typename: 'RestaurantGroup';
  /** Id */
  readonly _id: Scalars['ID'];
  /** Name */
  readonly name: Scalars['String'];
  /** Restaurants */
  readonly restaurants?: Maybe<ReadonlyArray<Restaurant>>;
};

/** Type for an order belonging to a restaurant. */
export type RestaurantOrder = {
  readonly __typename: 'RestaurantOrder';
  /** Timestamp of when the order was created. */
  readonly createdAt?: Maybe<Scalars['String']>;
  /** Order Items. */
  readonly items?: Maybe<Scalars['String']>;
  /** ID of the order. */
  readonly orderId?: Maybe<Scalars['String']>;
  /** Status of the order. */
  readonly status?: Maybe<Scalars['String']>;
  /** ID of the store that received the order. */
  readonly storeId?: Maybe<Scalars['String']>;
  /** Total price of the order, in cents. */
  readonly totalCents?: Maybe<Scalars['Int']>;
  /** ID of the user that made the order. */
  readonly userId?: Maybe<Scalars['String']>;
};

/** Type for an order belonging to a restaurant. */
export type RestaurantOrderDelivery = {
  readonly __typename: 'RestaurantOrderDelivery';
  /** Timestamp of when the order was created. */
  readonly createdAt: Scalars['String'];
  /** Order delivery information. */
  readonly delivery?: Maybe<Delivery>;
  /** Order id */
  readonly id: Scalars['String'];
  /** Order Id formatted field. */
  readonly orderNumberFormatted?: Maybe<Scalars['String']>;
  /** Payment method brand. */
  readonly paymentMethodBrand?: Maybe<Scalars['String']>;
  /** Payment method. */
  readonly paymentType?: Maybe<Scalars['String']>;
  /** ID of the store that received the order. */
  readonly storeId?: Maybe<Scalars['String']>;
  /** Total price of the order, in cents. */
  readonly totalCents?: Maybe<Scalars['Int']>;
};

/** Restaurant payment method */
export type RestaurantPaymentMethod = {
  readonly __typename: 'RestaurantPaymentMethod';
  /** If it is an online or offline payment */
  readonly isOnlinePayment?: Maybe<Scalars['Boolean']>;
  /** Payment name */
  readonly name?: Maybe<Scalars['String']>;
  /** Payment Method Brand */
  readonly paymentMethodBrand?: Maybe<Scalars['String']>;
  /** Payment enabled/disabled */
  readonly state?: Maybe<Scalars['Boolean']>;
};

/** Type for a driver. */
export type RestaurantPolygon = {
  readonly __typename: 'RestaurantPolygon';
  /** Time that polygon was created. */
  readonly createdAt: Scalars['String'];
  /** Identifier of the user that created the polygon. */
  readonly createdBy: Scalars['String'];
  /** True if the polygon is the default one for the restaurant. */
  readonly default: Scalars['Boolean'];
  /** GeoJSON for each of the 16 quadrants. */
  readonly gridArea: ReadonlyArray<PolygonsGridQuadrant>;
  /** Identifier of the polygon. */
  readonly id: Scalars['String'];
  /** GeoJSON for the main area. */
  readonly mainArea: GeoJson;
  /** Name of the polygon. */
  readonly name: Scalars['String'];
  /** If the polygon is submitted for approval, this value will be set to true. */
  readonly pendingApproval: Scalars['Boolean'];
  /** GeoJSON for the reduced area. */
  readonly reducedArea?: Maybe<GeoJson>;
  /** Restaurant id */
  readonly restaurantId: Scalars['String'];
  /** Time that polygon was updated. */
  readonly updatedAt: Scalars['String'];
  /** Identifier of the user that updated the polygon. */
  readonly updatedBy: Scalars['String'];
};

/** Polygons result. */
export type RestaurantPolygonList = {
  readonly __typename: 'RestaurantPolygonList';
  /** Restaurant id of the polygons list */
  readonly id: Scalars['String'];
  /** Array of polygons returned. */
  readonly polygons: ReadonlyArray<RestaurantPolygon>;
};

/** Type for a Restaurant. */
export type RestaurantSettings = {
  readonly __typename: 'RestaurantSettings';
  /** Enable Auto Fire on the Expeditor Tablet. Default is false. Could be not exists */
  readonly allowAutoFire: Scalars['Boolean'];
  /** Allow the expeditor tablet to moves an order automatically to preparation */
  readonly allowAutoPrepare: Scalars['Boolean'];
  /** Allow the expeditor tablet to pause the delivery service */
  readonly allowPauseService: Scalars['Boolean'];
  /** Allow the expeditor tablet to change the delivery area to a reduced one */
  readonly allowReducedArea: Scalars['Boolean'];
  /** Enable Refund on the Expeditor Tablet. Default is false. Could be not exists */
  readonly allowRefund?: Maybe<Scalars['Boolean']>;
  /** Enable Voucher on the Expeditor Tablet. Default is false. Could be not exists */
  readonly allowVoucher?: Maybe<Scalars['Boolean']>;
  /** Auto Fire radius is the minimum distance in meters to auto fire an order to the kitchen */
  readonly autoFireRadius: Scalars['Int'];
  /** Restaurant Capacity */
  readonly capacitySettings: RestaurantCapacity;
  /** Delivery Settings (ETA + Costs) */
  readonly deliverySettings: DeliverySettings;
  /** Delivery Time */
  readonly deliveryTime: ReadonlyArray<Maybe<DeliveryTimeSettings>>;
  /** Limit of orders that the driver can deliver at a time */
  readonly driverOrderLimit: Scalars['Int'];
  /** Settings used for last mile deliveries on a restaurant */
  readonly lastMile: LastMileSettings;
  /** Notifications settings */
  readonly notifications: NotificationSettings;
  /** POS Status */
  readonly posStatus: PosStatus;
  /** Restaurant service status */
  readonly serviceStatus: ServiceStatusSettings;
};

/** A reward offered to the user, typically with price zero */
export type Reward = {
  readonly __typename: 'Reward';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** Type the rider app privacy policy */
export type RiderAppPrivacyPolicy = {
  readonly __typename: 'RiderAppPrivacyPolicy';
  /** The content of the policy */
  readonly content: Scalars['String'];
  /** Time that poolicy was created */
  readonly createdAt: Scalars['String'];
  /** User who created the policy */
  readonly createdBy: Scalars['String'];
  /** If the policy is the current one */
  readonly current?: Maybe<Scalars['Boolean']>;
  /** Time that poolicy was updated */
  readonly updatedAt: Scalars['String'];
  /** User who updated the policy */
  readonly updatedBy: Scalars['String'];
  /** Version of the policy */
  readonly version: Scalars['String'];
};

/** A specific combination of permissions that determine a role. */
export type Role = {
  readonly __typename: 'Role';
  /** Country where the role is available. */
  readonly Country: Scalars['String'];
  /** Creation date. */
  readonly CreatedAt?: Maybe<Scalars['String']>;
  /** User who created the role. */
  readonly CreatedBy?: Maybe<Scalars['String']>;
  /** List of permissions for the role. */
  readonly Permissions?: Maybe<ReadonlyArray<Scalars['String']>>;
  /** Id of the role. */
  readonly RoleId: Scalars['ID'];
  /** Name of the role. */
  readonly RoleName: Scalars['String'];
  /** Update date. */
  readonly UpdatedAt?: Maybe<Scalars['String']>;
  /** User who updated the role. */
  readonly UpdatedBy?: Maybe<Scalars['String']>;
};

export enum Roles {
  ADD_USERS = 'ADD_USERS',
  COPY_STORE_PLUS = 'COPY_STORE_PLUS',
  DELIVERY_AREAS = 'DELIVERY_AREAS',
  DELIVERY_COMPLAINTS_COMPENSATIONS = 'DELIVERY_COMPLAINTS_COMPENSATIONS',
  DELIVERY_DMP_INSPECTOR = 'DELIVERY_DMP_INSPECTOR',
  DELIVERY_HISTORY = 'DELIVERY_HISTORY',
  DELIVERY_MASTER_RESTAURANT = 'DELIVERY_MASTER_RESTAURANT',
  DELIVERY_REPORTS = 'DELIVERY_REPORTS',
  DELIVERY_RESTAURANT_MANAGEMENT = 'DELIVERY_RESTAURANT_MANAGEMENT',
  DELIVERY_SUMMARY = 'DELIVERY_SUMMARY',
  DOWNLOAD_ORDERS = 'DOWNLOAD_ORDERS',
  DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS',
  EDIT_HISTORY = 'EDIT_HISTORY',
  EDIT_PAYMENT_METHODS = 'EDIT_PAYMENT_METHODS',
  EDIT_PRODUCTS_AVAILABILITY = 'EDIT_PRODUCTS_AVAILABILITY',
  EDIT_PRODUCTS_PRICE = 'EDIT_PRODUCTS_PRICE',
  EDIT_USERS = 'EDIT_USERS',
  REMOVE_USERS = 'REMOVE_USERS',
  VIEW_HISTORY = 'VIEW_HISTORY',
  VIEW_ORDERS = 'VIEW_ORDERS',
  VIEW_PAYMENT_METHODS = 'VIEW_PAYMENT_METHODS',
  VIEW_PRODUCTS_AND_RESTAURANTS = 'VIEW_PRODUCTS_AND_RESTAURANTS',
  VIEW_REPORTS = 'VIEW_REPORTS',
}

/**
 * All service modes.
 * Currently any non-DELIVERY service mode is treated as EAT_IN
 */
export enum ServiceMode {
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CATERING_PICKUP = 'CATERING_PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TABLE_SERVICE = 'TABLE_SERVICE',
  TAKEOUT = 'TAKEOUT',
}

/** Service Status */
export enum ServiceStatus {
  RESTAURANT_AVAILABLE = 'RESTAURANT_AVAILABLE',
  RESTAURANT_PAUSED = 'RESTAURANT_PAUSED',
  RESTAURANT_PAUSED_BY_CORP = 'RESTAURANT_PAUSED_BY_CORP',
  RESTAURANT_UNAVAILABLE = 'RESTAURANT_UNAVAILABLE',
}

/** Type for a Restaurant service Status. */
export type ServiceStatusSettings = {
  readonly __typename: 'ServiceStatusSettings';
  /** Reason that caused restaurant to be paused */
  readonly reason?: Maybe<Scalars['String']>;
  /** Actual status of the restaurant */
  readonly status: ServiceStatus;
  /** Id of the service update */
  readonly updateId?: Maybe<Scalars['String']>;
  /** Time the service was updated */
  readonly updatedAt: Scalars['String'];
};

export type Settings = {
  /** Allow the expeditor tablet to auto fire an order to the kitchen */
  readonly allowAutoFire?: InputMaybe<Scalars['Boolean']>;
  /** Allow the expeditor tablet to moves an order automatically to preparation */
  readonly allowAutoPrepare?: InputMaybe<Scalars['Boolean']>;
  /** Allow the expeditor tablet to pause the delivery service */
  readonly allowPauseService?: InputMaybe<Scalars['Boolean']>;
  /** Allow the expeditor tablet to change the delivery area to a reduced one */
  readonly allowReducedArea?: InputMaybe<Scalars['Boolean']>;
  /** Enable the Refund feature on the Expeditor Tablet */
  readonly allowRefund?: InputMaybe<Scalars['Boolean']>;
  /** Enable the Voucher feature on the Expeditor Tablet */
  readonly allowVoucher?: InputMaybe<Scalars['Boolean']>;
  /** The minimum distance in meters to auto fire an order to the kitchen */
  readonly autoFireRadius?: InputMaybe<Scalars['Int']>;
  /** Delivery settings */
  readonly deliverySettings?: InputMaybe<DeliverySettingsInput>;
  /** Sets the maximum number of orders that can be carried at a time */
  readonly driverOrderLimit?: InputMaybe<Scalars['Int']>;
  /** Last mile delivery settings for the restaurant */
  readonly lastMile?: InputMaybe<LastMileSettingsInput>;
  /** Notification settings */
  readonly notifications?: InputMaybe<NotificationSettingsInput>;
};

export type SizeBasedPlu = {
  readonly __typename: 'SizeBasedPlu';
  /** PLU value */
  readonly comboPlu?: Maybe<Scalars['String']>;
  /** Size */
  readonly comboSize?: Maybe<Scalars['String']>;
};

/** A systemwide offer. */
export type SystemwideOffer = {
  readonly __typename: 'SystemwideOffer';
  /** Sanity Id */
  readonly id: Scalars['ID'];
  /** Internal Name */
  readonly internalName?: Maybe<Scalars['String']>;
  /** Name */
  readonly name: Scalars['String'];
  /** Composite of all the PLUs of the product, for easier FE filtering by PLU */
  readonly plusComposite?: Maybe<Scalars['String']>;
  /** Hierarchy of products contained within the product */
  readonly productHierarchy?: Maybe<ReadonlyArray<Maybe<ProductHierarchy>>>;
  /** URL of the element in Sanity */
  readonly sanityUrl?: Maybe<Scalars['String']>;
  /** Vendor configurations */
  readonly vendorConfigs?: Maybe<VendorConfigs>;
};

/** Tablet status */
export enum TabletStatus {
  CASHIER_INPUT_FAILED = 'CASHIER_INPUT_FAILED',
  CASHIER_INPUT_REQUESTED = 'CASHIER_INPUT_REQUESTED',
  CASHIER_INPUT_SUCCESSFUL = 'CASHIER_INPUT_SUCCESSFUL',
}

/** Type for a TimeWindow */
export type TimeWindow = {
  readonly __typename: 'TimeWindow';
  /** End */
  readonly end: Scalars['String'];
  /** Start */
  readonly start: Scalars['String'];
};

export type UpdateDriverPayload = {
  /** Allow geolocation from the driver to be sent to the restaurant */
  readonly allowGeolocation?: InputMaybe<Scalars['Boolean']>;
  /** Driver availability */
  readonly availabilityStatus?: InputMaybe<AvailabilityStatus>;
  /** Driver's DNI or passport */
  readonly document?: InputMaybe<Scalars['String']>;
  /** E-mail of the driver */
  readonly email?: InputMaybe<Scalars['String']>;
  /** First name of the driver */
  readonly firstName?: InputMaybe<Scalars['String']>;
  /** Last name of the driver */
  readonly lastName?: InputMaybe<Scalars['String']>;
  /** Driver's order limit */
  readonly orderLimit?: InputMaybe<Scalars['Int']>;
  /** Driver's mobile phone number */
  readonly phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateMenuFromCsvInput = {
  /** Product availability */
  readonly availability?: InputMaybe<Scalars['Boolean']>;
  /** Product late night price */
  readonly lateNight?: InputMaybe<Scalars['Int']>;
  /** Product id */
  readonly plu: Scalars['String'];
  /** Product price */
  readonly price?: InputMaybe<Scalars['Int']>;
  /** Service mode */
  readonly serviceMode: Scalars['String'];
};

/** Info needed for updating the menu */
export type UpdatesInput = {
  /** Channel for the price and availability */
  readonly channel: Channel;
  /** Plus for the update */
  readonly plus: ReadonlyArray<PlusInput>;
  /** Region of the stores to update */
  readonly region: Scalars['String'];
  /** POS Vendor of the restaurant */
  readonly restaurantPos?: InputMaybe<Scalars['String']>;
  /** Service mode for the price and availability */
  readonly serviceMode: Scalars['String'];
  /** Ids of the stores to update the price and availability for */
  readonly storeIds: ReadonlyArray<Scalars['String']>;
  /** Use new schema of validate response (will be removed in follow-up) */
  readonly useNewSchema?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  readonly __typename: 'User';
  readonly FranCountry?: Maybe<Scalars['String']>;
  readonly UserID?: Maybe<Scalars['String']>;
  readonly UserType?: Maybe<Scalars['String']>;
};

/** User roles */
export enum UserRole {
  CONTRACTOR = 'Contractor',
  FRANCHISE = 'Franchise',
  REGULAR = 'Regular',
}

export type ValidateProductsData = {
  readonly __typename: 'ValidateProductsData';
  /** Plus for the update */
  readonly plus: ReadonlyArray<PlusResponse>;
  /** Ids of the stores to update the price and availability for */
  readonly storeIds: ReadonlyArray<Scalars['String']>;
};

export type ValidateProductsError = {
  readonly __typename: 'ValidateProductsError';
  /** Error code */
  readonly code: Scalars['String'];
  /** Error message */
  readonly message: Scalars['String'];
  /** PLU value */
  readonly plu: Scalars['String'];
  /** List of restaurant ids */
  readonly restaurantIds: ReadonlyArray<Scalars['String']>;
};

export type ValidateProductsMutationResponse = {
  readonly __typename: 'ValidateProductsMutationResponse';
  /** Data of the validation */
  readonly data?: Maybe<ValidateProductsResponse>;
  /** Non-fatal errors that happened during the mutation */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ValidateProductsError>>>;
};

export type ValidateProductsResponse =
  | ValidateProductsResponseNewSchema
  | ValidateProductsResponseOldSchema;

export type ValidateProductsResponseNewSchema = {
  readonly __typename: 'ValidateProductsResponseNewSchema';
  /** Channel for the price and availability */
  readonly channel: Scalars['String'];
  /** List of side-effects to carry out further updates */
  readonly data: ReadonlyArray<ValidateProductsData>;
  /** Region of the stores to update */
  readonly region: Scalars['String'];
  /** Service mode for the price and availability */
  readonly serviceMode: Scalars['String'];
};

/** Response from validating menu update */
export type ValidateProductsResponseOldSchema = {
  readonly __typename: 'ValidateProductsResponseOldSchema';
  /** Channel for the price and availability */
  readonly channel: Scalars['String'];
  /** Plus for the update */
  readonly plus: ReadonlyArray<PlusResponse>;
  /** Region of the stores to update */
  readonly region: Scalars['String'];
  /** Service mode for the price and availability */
  readonly serviceMode: Scalars['String'];
  /** Ids of the stores to update the price and availability for */
  readonly storeIds: ReadonlyArray<Scalars['String']>;
};

/** Individual vendor config data for a product. */
export type VendorConfig = {
  readonly __typename: 'VendorConfig';
  /** Constant is for a single value PLU */
  readonly constantPlu?: Maybe<Scalars['String']>;
  /** PLU data if the product is a multi constant */
  readonly multiConstantPlus?: Maybe<ReadonlyArray<Maybe<MultiConstantPlus>>>;
  /** PLU data with a child */
  readonly parentChildPlu?: Maybe<ParentChildPlu>;
  /**
   * If provided this vendorConfig will be moved under the cartEntry with Sanity ID equal to
   * parentSanityId for injection.
   */
  readonly parentSanityId?: Maybe<Scalars['String']>;
  /**
   * This indicates the type of PLU the Vendor needs. Ignore means that this is just for UI and not
   * used by POS.
   */
  readonly pluType?: Maybe<Scalars['String']>;
  /** This field indicates how many levels up the PLU belongs. */
  readonly pullUpLevels?: Maybe<Scalars['Int']>;
  /** PLU data if the product is quantity based */
  readonly quantityBasedPlu?: Maybe<ReadonlyArray<Maybe<QuantityBasedPlu>>>;
  /** PLU data if the product is sized based */
  readonly sizeBasedPlu?: Maybe<SizeBasedPlu>;
};

/** Vendor configs of a product. */
export type VendorConfigs = {
  readonly __typename: 'VendorConfigs';
  /** Carrols POS vendor */
  readonly carrols?: Maybe<VendorConfig>;
  /** Carrols POS vendor (delivery mode) */
  readonly carrolsDelivery?: Maybe<VendorConfig>;
  /** NCR POS vendor */
  readonly ncr?: Maybe<VendorConfig>;
  /** NCR POS vendor (delivery mode) */
  readonly ncrDelivery?: Maybe<VendorConfig>;
  /** OHEICS POS vendor */
  readonly oheics?: Maybe<VendorConfig>;
  /** OHEICS POS vendor (delivery mode) */
  readonly oheicsDelivery?: Maybe<VendorConfig>;
  /** Partner POS vendor */
  readonly partner?: Maybe<VendorConfig>;
  /** Partner POS vendor (delivery mode) */
  readonly partnerDelivery?: Maybe<VendorConfig>;
  /** ProductNumber POS vendor */
  readonly productNumber?: Maybe<VendorConfig>;
  /** ProductNumber POS vendor (delivery mode) */
  readonly productNumberDelivery?: Maybe<VendorConfig>;
  /** QDI POS vendor */
  readonly qdi?: Maybe<VendorConfig>;
  /** QDI POS vendor (delivery mode) */
  readonly qdiDelivery?: Maybe<VendorConfig>;
  /** QST POS vendor */
  readonly qst?: Maybe<VendorConfig>;
  /** QST POS vendor (delivery mode) */
  readonly qstDelivery?: Maybe<VendorConfig>;
  /** rPOS POS vendor */
  readonly rpos?: Maybe<VendorConfig>;
  /** rPOS POS vendor (delivery mode) */
  readonly rposDelivery?: Maybe<VendorConfig>;
  /** Sicom POS vendor */
  readonly sicom?: Maybe<VendorConfig>;
  /** Sicom POS vendor (delivery mode) */
  readonly sicomDelivery?: Maybe<VendorConfig>;
  /** SimplyDelivery POS vendor */
  readonly simplyDelivery?: Maybe<VendorConfig>;
  /** SimplyDelivery POS vendor (delivery mode) */
  readonly simplyDeliveryDelivery?: Maybe<VendorConfig>;
  /** Tablet POS vendor */
  readonly tablet?: Maybe<VendorConfig>;
  /** Tablet POS vendor (delivery mode) */
  readonly tabletDelivery?: Maybe<VendorConfig>;
};

/** List voucher for complaint */
export type VoucherComplaint = {
  readonly __typename: 'VoucherComplaint';
  /** Loyalty Engine Id */
  readonly loyaltyEngineId: Scalars['String'];
  /** Text */
  readonly text: Scalars['String'];
};

/** Type for a Restaurant workingPeriod. */
export type WorkingPeriod = {
  readonly __typename: 'WorkingPeriod';
  /** End of working period */
  readonly finish: Scalars['String'];
  /** Start working hour */
  readonly start: Scalars['String'];
};

export type CopyStorePlusPayload = {
  /** The store whose plus will be copied */
  readonly fromStoreId: Scalars['String'];
  /** The store group id's that will receive the copied plus */
  readonly toGroupIds: ReadonlyArray<InputMaybe<Scalars['String']>>;
  /** The stores that will receive the copied plus */
  readonly toStoreIds: ReadonlyArray<Scalars['String']>;
};

export type CreateDriverPayload = {
  /** Document number of the driver */
  readonly document: Scalars['String'];
  /** E-mail of the driver */
  readonly email: Scalars['String'];
  /** First name of the driver */
  readonly firstName: Scalars['String'];
  /** Driver's identification number */
  readonly identifier: Scalars['String'];
  /** Last name of the driver */
  readonly lastName: Scalars['String'];
  /** Maximum number of orders that the driver can deliver at a time */
  readonly orderLimit?: InputMaybe<Scalars['Int']>;
  /** Driver's mobile phone number */
  readonly phoneNumber: Scalars['String'];
};

export type CreatePolygonPayload = {
  /** GeoJSON for each of the 16 quadrants */
  readonly gridArea: ReadonlyArray<GridAreaType>;
  /** GeoJSON for the main area */
  readonly mainArea: GeoJsonType;
  /** Name of the polygon */
  readonly name: Scalars['String'];
  /** GeoJSON for the reduced area */
  readonly reducedArea?: InputMaybe<GeoJsonType>;
};

export type GeoJsonType = {
  /** Color that represents these coordinates on the map. */
  readonly color: Scalars['String'];
  /** Geometry coordinates list. */
  readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Scalars['Float']>>>;
};

export type GridAreaType = {
  /** Identifier of the polygon. */
  readonly id: Scalars['String'];
  /** GeoJSON for the quadrant. */
  readonly polygon: GeoJsonType;
};

export type UpdatePolygonPayload = {
  /** GeoJSON for each of the 16 quadrants */
  readonly gridArea: ReadonlyArray<GridAreaType>;
  /** GeoJSON for the main area */
  readonly mainArea: GeoJsonType;
  /** Name of the polygon */
  readonly name: Scalars['String'];
  /** GeoJSON for the reduced area */
  readonly reducedArea?: InputMaybe<GeoJsonType>;
};

export type RewardFragmentFragment = {
  readonly __typename: 'Reward';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type ItemFragmentFragment = {
  readonly __typename: 'Item';
  readonly id: string;
  readonly internalName?: string | null;
  readonly name: string;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  readonly parentProducts?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null> | null;
};

export type ComboFragmentFragment = {
  readonly __typename: 'Combo';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  readonly parentProducts?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null> | null;
};

export type OfferFragmentFragment = {
  readonly __typename: 'Offer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SystemwideOfferFragmentFragment = {
  readonly __typename: 'SystemwideOffer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type ConfigOfferFragmentFragment = {
  readonly __typename: 'ConfigOffer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type ModifierFragmentFragment = {
  readonly __typename: 'Modifier';
  readonly id: string;
  readonly name: string;
};

export type ModifierMultiplierFragmentFragment = {
  readonly __typename: 'ModifierMultiplier';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly prefix: string;
  readonly quantity: number;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly modifier: {
    readonly __typename: 'Modifier';
    readonly id: string;
    readonly name: string;
  };
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
    } | null> | null;
  } | null> | null;
};

type ProductFragmentComboFragment = {
  readonly __typename: 'Combo';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly productHierarchy?: ReadonlyArray<{
          readonly __typename: 'ProductHierarchy';
          readonly id: string;
          readonly name: string;
          readonly type: string;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  readonly parentProducts?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null> | null;
};

type ProductFragmentConfigOfferFragment = {
  readonly __typename: 'ConfigOffer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

type ProductFragmentItemFragment = {
  readonly __typename: 'Item';
  readonly id: string;
  readonly internalName?: string | null;
  readonly name: string;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
  readonly parentProducts?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null> | null;
};

type ProductFragmentModifierMultiplierFragment = {
  readonly __typename: 'ModifierMultiplier';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly prefix: string;
  readonly quantity: number;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly sections?: ReadonlyArray<string | null> | null;
  readonly modifier: {
    readonly __typename: 'Modifier';
    readonly id: string;
    readonly name: string;
  };
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
    } | null> | null;
  } | null> | null;
};

type ProductFragmentOfferFragment = {
  readonly __typename: 'Offer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

type ProductFragmentRewardFragment = {
  readonly __typename: 'Reward';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

type ProductFragmentSystemwideOfferFragment = {
  readonly __typename: 'SystemwideOffer';
  readonly id: string;
  readonly name: string;
  readonly internalName?: string | null;
  readonly plusComposite?: string | null;
  readonly sanityUrl?: string | null;
  readonly vendorConfigs?: {
    readonly __typename: 'VendorConfigs';
    readonly carrols?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly carrolsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncr?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly ncrDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheics?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly oheicsDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partner?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly partnerDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumber?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicom?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly sicomDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdi?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qdiDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qst?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly qstDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rpos?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly rposDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tablet?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
    readonly tabletDelivery?: {
      readonly __typename: 'VendorConfig';
      readonly pluType?: string | null;
      readonly parentSanityId?: string | null;
      readonly pullUpLevels?: number | null;
      readonly constantPlu?: string | null;
      readonly quantityBasedPlu?: ReadonlyArray<{
        readonly __typename: 'QuantityBasedPlu';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly multiConstantPlus?: ReadonlyArray<{
        readonly __typename: 'MultiConstantPlus';
        readonly quantity?: number | null;
        readonly plu?: string | null;
        readonly qualifier?: string | null;
      } | null> | null;
      readonly parentChildPlu?: {
        readonly __typename: 'ParentChildPlu';
        readonly plu?: string | null;
        readonly childPlu?: string | null;
      } | null;
      readonly sizeBasedPlu?: {
        readonly __typename: 'SizeBasedPlu';
        readonly comboPlu?: string | null;
        readonly comboSize?: string | null;
      } | null;
    } | null;
  } | null;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly productHierarchy?: ReadonlyArray<{
      readonly __typename: 'ProductHierarchy';
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly productHierarchy?: ReadonlyArray<{
        readonly __typename: 'ProductHierarchy';
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type ProductFragmentFragment =
  | ProductFragmentComboFragment
  | ProductFragmentConfigOfferFragment
  | ProductFragmentItemFragment
  | ProductFragmentModifierMultiplierFragment
  | ProductFragmentOfferFragment
  | ProductFragmentRewardFragment
  | ProductFragmentSystemwideOfferFragment;

export type ProductHierarchyFragmentFragment = {
  readonly __typename: 'ProductHierarchy';
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly productHierarchy?: ReadonlyArray<{
    readonly __typename: 'ProductHierarchy';
    readonly id: string;
    readonly name: string;
    readonly type: string;
  } | null> | null;
};

export type VendorConfigFragmentFragment = {
  readonly __typename: 'VendorConfig';
  readonly pluType?: string | null;
  readonly parentSanityId?: string | null;
  readonly pullUpLevels?: number | null;
  readonly constantPlu?: string | null;
  readonly quantityBasedPlu?: ReadonlyArray<{
    readonly __typename: 'QuantityBasedPlu';
    readonly quantity?: number | null;
    readonly plu?: string | null;
    readonly qualifier?: string | null;
  } | null> | null;
  readonly multiConstantPlus?: ReadonlyArray<{
    readonly __typename: 'MultiConstantPlus';
    readonly quantity?: number | null;
    readonly plu?: string | null;
    readonly qualifier?: string | null;
  } | null> | null;
  readonly parentChildPlu?: {
    readonly __typename: 'ParentChildPlu';
    readonly plu?: string | null;
    readonly childPlu?: string | null;
  } | null;
  readonly sizeBasedPlu?: {
    readonly __typename: 'SizeBasedPlu';
    readonly comboPlu?: string | null;
    readonly comboSize?: string | null;
  } | null;
};

export type VendorConfigsFragmentFragment = {
  readonly __typename: 'VendorConfigs';
  readonly carrols?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly carrolsDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly ncr?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly ncrDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly oheics?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly oheicsDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly partner?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly partnerDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly productNumber?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly productNumberDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly sicom?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly sicomDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly qdi?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly qdiDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly qst?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly qstDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly rpos?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly rposDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly simplyDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly simplyDeliveryDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly tablet?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
  readonly tabletDelivery?: {
    readonly __typename: 'VendorConfig';
    readonly pluType?: string | null;
    readonly parentSanityId?: string | null;
    readonly pullUpLevels?: number | null;
    readonly constantPlu?: string | null;
    readonly quantityBasedPlu?: ReadonlyArray<{
      readonly __typename: 'QuantityBasedPlu';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly multiConstantPlus?: ReadonlyArray<{
      readonly __typename: 'MultiConstantPlus';
      readonly quantity?: number | null;
      readonly plu?: string | null;
      readonly qualifier?: string | null;
    } | null> | null;
    readonly parentChildPlu?: {
      readonly __typename: 'ParentChildPlu';
      readonly plu?: string | null;
      readonly childPlu?: string | null;
    } | null;
    readonly sizeBasedPlu?: {
      readonly __typename: 'SizeBasedPlu';
      readonly comboPlu?: string | null;
      readonly comboSize?: string | null;
    } | null;
  } | null;
};

export type ProductPriceFragmentFragment = {
  readonly __typename: 'ProductPrice';
  readonly id: string;
  readonly productId: string;
  readonly plu: string;
  readonly price?: number | null;
  readonly availability?: boolean | null;
};

export type ProductPriceV3FragmentFragment = {
  readonly __typename: 'ProductPriceV3';
  readonly id: string;
  readonly restaurantId: string;
  readonly serviceMode: string;
  readonly channel: string;
  readonly plu: string;
  readonly price?: number | null;
  readonly availability?: boolean | null;
  readonly draft?: string | null;
  readonly priority?: number | null;
  readonly repetition?: ReadonlyArray<string> | null;
  readonly overrides?: ReadonlyArray<{
    readonly __typename: 'Overrides';
    readonly id: string;
    readonly price: number;
  }> | null;
};

export type ErrorFragmentFragment = {
  readonly __typename: 'Error';
  readonly code: string;
  readonly message: string;
  readonly productId?: string | null;
  readonly restaurantId?: string | null;
};

export type ProductMutationResponseFragmentFragment = {
  readonly __typename: 'ProductMutationResponse';
  readonly data: ReadonlyArray<{
    readonly __typename: 'ProductPrice';
    readonly id: string;
    readonly productId: string;
    readonly plu: string;
    readonly price?: number | null;
    readonly availability?: boolean | null;
  }>;
  readonly errors?: ReadonlyArray<{
    readonly __typename: 'Error';
    readonly code: string;
    readonly message: string;
    readonly productId?: string | null;
    readonly restaurantId?: string | null;
  } | null> | null;
};

export type OverridesResponseFragmentFragment = {
  readonly __typename: 'OverridesResponse';
  readonly id: string;
  readonly price: number;
};

export type PlusResponseFragmentFragment = {
  readonly __typename: 'PlusResponse';
  readonly availability?: boolean | null;
  readonly draft?: string | null;
  readonly plu: string;
  readonly price?: number | null;
  readonly priority?: number | null;
  readonly repetition?: ReadonlyArray<string> | null;
  readonly sanityId?: string | null;
  readonly overrides?: ReadonlyArray<{
    readonly __typename: 'OverridesResponse';
    readonly id: string;
    readonly price: number;
  }> | null;
};

export type ValidateProductsErrorFragmentFragment = {
  readonly __typename: 'ValidateProductsError';
  readonly code: string;
  readonly message: string;
  readonly plu: string;
  readonly restaurantIds: ReadonlyArray<string>;
};

export type ValidateProductsMutationResponseFragmentFragment = {
  readonly __typename: 'ValidateProductsMutationResponse';
  readonly data?:
    | {
        readonly __typename: 'ValidateProductsResponseNewSchema';
        readonly channel: string;
        readonly region: string;
        readonly serviceMode: string;
        readonly data: ReadonlyArray<{
          readonly __typename: 'ValidateProductsData';
          readonly storeIds: ReadonlyArray<string>;
          readonly plus: ReadonlyArray<{
            readonly __typename: 'PlusResponse';
            readonly availability?: boolean | null;
            readonly draft?: string | null;
            readonly plu: string;
            readonly price?: number | null;
            readonly priority?: number | null;
            readonly repetition?: ReadonlyArray<string> | null;
            readonly sanityId?: string | null;
            readonly overrides?: ReadonlyArray<{
              readonly __typename: 'OverridesResponse';
              readonly id: string;
              readonly price: number;
            }> | null;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidateProductsResponseOldSchema';
        readonly channel: string;
        readonly region: string;
        readonly serviceMode: string;
        readonly storeIds: ReadonlyArray<string>;
        readonly plus: ReadonlyArray<{
          readonly __typename: 'PlusResponse';
          readonly availability?: boolean | null;
          readonly draft?: string | null;
          readonly plu: string;
          readonly price?: number | null;
          readonly priority?: number | null;
          readonly repetition?: ReadonlyArray<string> | null;
          readonly sanityId?: string | null;
          readonly overrides?: ReadonlyArray<{
            readonly __typename: 'OverridesResponse';
            readonly id: string;
            readonly price: number;
          }> | null;
        }>;
      }
    | null;
  readonly errors?: ReadonlyArray<{
    readonly __typename: 'ValidateProductsError';
    readonly code: string;
    readonly message: string;
    readonly plu: string;
    readonly restaurantIds: ReadonlyArray<string>;
  } | null> | null;
};

export type ValidateProductsResponseFragmentOldSchemaFragment = {
  readonly __typename: 'ValidateProductsResponseOldSchema';
  readonly channel: string;
  readonly region: string;
  readonly serviceMode: string;
  readonly storeIds: ReadonlyArray<string>;
  readonly plus: ReadonlyArray<{
    readonly __typename: 'PlusResponse';
    readonly availability?: boolean | null;
    readonly draft?: string | null;
    readonly plu: string;
    readonly price?: number | null;
    readonly priority?: number | null;
    readonly repetition?: ReadonlyArray<string> | null;
    readonly sanityId?: string | null;
    readonly overrides?: ReadonlyArray<{
      readonly __typename: 'OverridesResponse';
      readonly id: string;
      readonly price: number;
    }> | null;
  }>;
};

export type ValidateProductsResponseFragmentNewSchemaFragment = {
  readonly __typename: 'ValidateProductsResponseNewSchema';
  readonly channel: string;
  readonly region: string;
  readonly serviceMode: string;
  readonly data: ReadonlyArray<{
    readonly __typename: 'ValidateProductsData';
    readonly storeIds: ReadonlyArray<string>;
    readonly plus: ReadonlyArray<{
      readonly __typename: 'PlusResponse';
      readonly availability?: boolean | null;
      readonly draft?: string | null;
      readonly plu: string;
      readonly price?: number | null;
      readonly priority?: number | null;
      readonly repetition?: ReadonlyArray<string> | null;
      readonly sanityId?: string | null;
      readonly overrides?: ReadonlyArray<{
        readonly __typename: 'OverridesResponse';
        readonly id: string;
        readonly price: number;
      }> | null;
    }>;
  }>;
};

export type AdditionalTimeSlotFragment = {
  readonly __typename: 'AdditionalTimeSlot';
  readonly open?: string | null;
  readonly close?: string | null;
};

export type HoursFragmentFragment = {
  readonly __typename: 'HoursOfOperation';
  readonly friClose?: string | null;
  readonly friOpen?: string | null;
  readonly monClose?: string | null;
  readonly monOpen?: string | null;
  readonly satClose?: string | null;
  readonly satOpen?: string | null;
  readonly sunClose?: string | null;
  readonly sunOpen?: string | null;
  readonly thrClose?: string | null;
  readonly thrOpen?: string | null;
  readonly tueClose?: string | null;
  readonly tueOpen?: string | null;
  readonly wedClose?: string | null;
  readonly wedOpen?: string | null;
  readonly friAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly monAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly satAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly sunAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly thrAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly tueAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
  readonly wedAdditionalTimeSlot?: {
    readonly __typename: 'AdditionalTimeSlot';
    readonly open?: string | null;
    readonly close?: string | null;
  } | null;
};

export type LightRestaurantFragmentFragment = {
  readonly __typename: 'Restaurant';
  readonly id: string;
  readonly groups?: string | null;
  readonly name: string;
  readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
};

export type RestaurantFragmentFragment = {
  readonly __typename: 'Restaurant';
  readonly id: string;
  readonly hasCurbside?: boolean | null;
  readonly hasDelivery?: boolean | null;
  readonly hasDineIn?: boolean | null;
  readonly hasDriveThru?: boolean | null;
  readonly hasLateNightPricing?: boolean | null;
  readonly hasMobileOrdering?: boolean | null;
  readonly latitude?: number | null;
  readonly longitude?: number | null;
  readonly environment?: string | null;
  readonly mobileOrderingStatus?: string | null;
  readonly name: string;
  readonly sanityDocumentId?: string | null;
  readonly vendor?: string | null;
  readonly groups?: string | null;
  readonly availability?: AvailabilityModes | null;
  readonly deliveryHours?: {
    readonly __typename: 'HoursOfOperation';
    readonly friClose?: string | null;
    readonly friOpen?: string | null;
    readonly monClose?: string | null;
    readonly monOpen?: string | null;
    readonly satClose?: string | null;
    readonly satOpen?: string | null;
    readonly sunClose?: string | null;
    readonly sunOpen?: string | null;
    readonly thrClose?: string | null;
    readonly thrOpen?: string | null;
    readonly tueClose?: string | null;
    readonly tueOpen?: string | null;
    readonly wedClose?: string | null;
    readonly wedOpen?: string | null;
    readonly friAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly monAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly satAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly sunAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly thrAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly tueAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly wedAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
  } | null;
  readonly diningRoomHours?: {
    readonly __typename: 'HoursOfOperation';
    readonly friClose?: string | null;
    readonly friOpen?: string | null;
    readonly monClose?: string | null;
    readonly monOpen?: string | null;
    readonly satClose?: string | null;
    readonly satOpen?: string | null;
    readonly sunClose?: string | null;
    readonly sunOpen?: string | null;
    readonly thrClose?: string | null;
    readonly thrOpen?: string | null;
    readonly tueClose?: string | null;
    readonly tueOpen?: string | null;
    readonly wedClose?: string | null;
    readonly wedOpen?: string | null;
    readonly friAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly monAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly satAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly sunAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly thrAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly tueAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly wedAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
  } | null;
  readonly curbsideHours?: {
    readonly __typename: 'HoursOfOperation';
    readonly friClose?: string | null;
    readonly friOpen?: string | null;
    readonly monClose?: string | null;
    readonly monOpen?: string | null;
    readonly satClose?: string | null;
    readonly satOpen?: string | null;
    readonly sunClose?: string | null;
    readonly sunOpen?: string | null;
    readonly thrClose?: string | null;
    readonly thrOpen?: string | null;
    readonly tueClose?: string | null;
    readonly tueOpen?: string | null;
    readonly wedClose?: string | null;
    readonly wedOpen?: string | null;
    readonly friAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly monAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly satAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly sunAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly thrAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly tueAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly wedAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
  } | null;
  readonly driveThruHours?: {
    readonly __typename: 'HoursOfOperation';
    readonly friClose?: string | null;
    readonly friOpen?: string | null;
    readonly monClose?: string | null;
    readonly monOpen?: string | null;
    readonly satClose?: string | null;
    readonly satOpen?: string | null;
    readonly sunClose?: string | null;
    readonly sunOpen?: string | null;
    readonly thrClose?: string | null;
    readonly thrOpen?: string | null;
    readonly tueClose?: string | null;
    readonly tueOpen?: string | null;
    readonly wedClose?: string | null;
    readonly wedOpen?: string | null;
    readonly friAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly monAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly satAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly sunAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly thrAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly tueAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly wedAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
  } | null;
  readonly physicalAddress?: {
    readonly __typename: 'PhysicalAddress';
    readonly address1?: string | null;
    readonly address2?: string | null;
    readonly city?: string | null;
    readonly country?: string | null;
    readonly postalCode?: string | null;
    readonly stateProvince?: string | null;
  } | null;
  readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
};

export type RestaurantOrderFragmentFragment = {
  readonly __typename: 'RestaurantOrder';
  readonly orderId?: string | null;
  readonly storeId?: string | null;
  readonly totalCents?: number | null;
  readonly createdAt?: string | null;
  readonly items?: string | null;
};

export type HistoryFragmentFragment = {
  readonly __typename: 'History';
  readonly date: string;
  readonly status: string;
  readonly storeId: string;
  readonly userOktaId: string;
  readonly userEmail: string;
  readonly serviceMode: string;
  readonly channel: string;
  readonly updates: ReadonlyArray<{
    readonly __typename: 'HistoryUpdate';
    readonly product: string;
    readonly price?: number | null;
    readonly availability?: boolean | null;
  } | null>;
};

export type RestaurantDeliveryFragmentFragment = {
  readonly __typename: 'RestaurantDelivery';
  readonly id: string;
  readonly hasDelivery?: boolean | null;
  readonly latitude?: number | null;
  readonly longitude?: number | null;
  readonly internalName?: string | null;
  readonly name: string;
  readonly franchiseGroupId?: number | null;
  readonly franchiseGroupName?: string | null;
  readonly availability?: AvailabilityModes | null;
  readonly deliveryHours?: {
    readonly __typename: 'HoursOfOperation';
    readonly friClose?: string | null;
    readonly friOpen?: string | null;
    readonly monClose?: string | null;
    readonly monOpen?: string | null;
    readonly satClose?: string | null;
    readonly satOpen?: string | null;
    readonly sunClose?: string | null;
    readonly sunOpen?: string | null;
    readonly thrClose?: string | null;
    readonly thrOpen?: string | null;
    readonly tueClose?: string | null;
    readonly tueOpen?: string | null;
    readonly wedClose?: string | null;
    readonly wedOpen?: string | null;
    readonly friAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly monAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly satAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly sunAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly thrAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly tueAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
    readonly wedAdditionalTimeSlot?: {
      readonly __typename: 'AdditionalTimeSlot';
      readonly open?: string | null;
      readonly close?: string | null;
    } | null;
  } | null;
  readonly physicalAddress?: {
    readonly __typename: 'PhysicalAddress';
    readonly address1?: string | null;
    readonly address2?: string | null;
    readonly city?: string | null;
    readonly country?: string | null;
    readonly postalCode?: string | null;
    readonly stateProvince?: string | null;
  } | null;
};

export type AmountFragmentFragment = {
  readonly __typename: 'Amount';
  readonly amount: number;
  readonly currency: string;
};

export type MenuSelectionFieldsFragment = {
  readonly __typename: 'MenuSelections';
  readonly name?: string | null;
  readonly externalReferenceId?: string | null;
  readonly quantity: number;
  readonly dmpId?: string | null;
  readonly price: {
    readonly __typename: 'Amount';
    readonly amount: number;
    readonly currency: string;
  };
};

export type MenuSelectionsFragmentFragment = {
  readonly __typename: 'OrderCartMenuSelections';
  readonly name?: string | null;
  readonly externalReferenceId?: string | null;
  readonly quantity: number;
  readonly dmpId?: string | null;
  readonly price: {
    readonly __typename: 'Amount';
    readonly amount: number;
    readonly currency: string;
  };
  readonly menuSelections?: ReadonlyArray<{
    readonly __typename: 'MenuSelections';
    readonly name?: string | null;
    readonly externalReferenceId?: string | null;
    readonly quantity: number;
    readonly dmpId?: string | null;
    readonly price: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
  } | null> | null;
};

export type DmpOrderByIdFragmentFragment = {
  readonly __typename: 'DmpOrderById';
  readonly brand: Brand;
  readonly channel: string;
  readonly createdAt: string;
  readonly deliveryId?: string | null;
  readonly displayOrderId?: string | null;
  readonly driverId?: string | null;
  readonly eventsUrl?: string | null;
  readonly quoteId: string;
  readonly rbiNumberId: string;
  readonly regionCode: string;
  readonly restaurantId: string;
  readonly status: string;
  readonly updatedAt: string;
  readonly cart?: {
    readonly __typename: 'OrderCart';
    readonly menuSelections?: ReadonlyArray<{
      readonly __typename: 'OrderCartMenuSelections';
      readonly name?: string | null;
      readonly externalReferenceId?: string | null;
      readonly quantity: number;
      readonly dmpId?: string | null;
      readonly price: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'MenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null> | null;
    } | null> | null;
  } | null;
  readonly compensation?: {
    readonly __typename: 'OrderCompesation';
    readonly menuSelections?: ReadonlyArray<{
      readonly __typename: 'OrderCartMenuSelections';
      readonly name?: string | null;
      readonly externalReferenceId?: string | null;
      readonly quantity: number;
      readonly dmpId?: string | null;
      readonly price: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'MenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null> | null;
    } | null> | null;
  } | null;
  readonly customer: {
    readonly __typename: 'OrderCustomer';
    readonly name: string;
    readonly id?: string | null;
    readonly phone?: string | null;
    readonly email?: string | null;
    readonly username?: string | null;
  };
  readonly deliveryAddress: {
    readonly __typename: 'Address';
    readonly administrativeArea?: string | null;
    readonly formattedAddress: string;
    readonly instructions?: string | null;
    readonly locality?: string | null;
    readonly phoneNumber?: string | null;
    readonly postalCode?: string | null;
    readonly regionCode: RegionCode;
    readonly route: string;
    readonly streetNumber: string;
    readonly sublocality?: string | null;
    readonly subpremise?: string | null;
  };
  readonly dropoffWindow?: {
    readonly __typename: 'TimeWindow';
    readonly end: string;
    readonly start: string;
  } | null;
  readonly events: ReadonlyArray<{
    readonly __typename: 'OrderEvents';
    readonly name: string;
    readonly time: string;
    readonly issue?: ReadonlyArray<string> | null;
  }>;
  readonly payment?: {
    readonly __typename: 'PaymentDmp';
    readonly paymentMethod: string;
    readonly paymentMethodBrand?: string | null;
    readonly amountLeftToBePaid: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
    readonly tip: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
    readonly total: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
  } | null;
  readonly pickupWindow?: {
    readonly __typename: 'TimeWindow';
    readonly end: string;
    readonly start: string;
  } | null;
  readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
};

export type ComplaintsRowFragment = {
  readonly __typename: 'Complaint';
  readonly id: string;
  readonly orderId?: string | null;
  readonly dmpCategory?: ComplaintDmpCategory | null;
  readonly status: ComplaintStatus;
  readonly created: string;
  readonly comments?: string | null;
  readonly resolution?: string | null;
  readonly resolutionComments?: string | null;
  readonly payment?: {
    readonly __typename: 'PaymentDmp';
    readonly paymentMethod: string;
    readonly paymentMethodBrand?: string | null;
    readonly amountLeftToBePaid: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
    readonly tip: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
    readonly total: {
      readonly __typename: 'Amount';
      readonly amount: number;
      readonly currency: string;
    };
  } | null;
  readonly order?: {
    readonly __typename: 'DmpOrderById';
    readonly brand: Brand;
    readonly channel: string;
    readonly createdAt: string;
    readonly deliveryId?: string | null;
    readonly displayOrderId?: string | null;
    readonly driverId?: string | null;
    readonly eventsUrl?: string | null;
    readonly quoteId: string;
    readonly rbiNumberId: string;
    readonly regionCode: string;
    readonly restaurantId: string;
    readonly status: string;
    readonly updatedAt: string;
    readonly cart?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
    readonly compensation?: {
      readonly __typename: 'OrderCompesation';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
    readonly customer: {
      readonly __typename: 'OrderCustomer';
      readonly name: string;
      readonly id?: string | null;
      readonly phone?: string | null;
      readonly email?: string | null;
      readonly username?: string | null;
    };
    readonly deliveryAddress: {
      readonly __typename: 'Address';
      readonly administrativeArea?: string | null;
      readonly formattedAddress: string;
      readonly instructions?: string | null;
      readonly locality?: string | null;
      readonly phoneNumber?: string | null;
      readonly postalCode?: string | null;
      readonly regionCode: RegionCode;
      readonly route: string;
      readonly streetNumber: string;
      readonly sublocality?: string | null;
      readonly subpremise?: string | null;
    };
    readonly dropoffWindow?: {
      readonly __typename: 'TimeWindow';
      readonly end: string;
      readonly start: string;
    } | null;
    readonly events: ReadonlyArray<{
      readonly __typename: 'OrderEvents';
      readonly name: string;
      readonly time: string;
      readonly issue?: ReadonlyArray<string> | null;
    }>;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly pickupWindow?: {
      readonly __typename: 'TimeWindow';
      readonly end: string;
      readonly start: string;
    } | null;
    readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
  } | null;
  readonly customer: {
    readonly __typename: 'ComplaintsCustomer';
    readonly name: string;
    readonly email: string;
    readonly phoneNumber?: string | null;
  };
  readonly compensation?: {
    readonly __typename: 'OrderCart';
    readonly menuSelections?: ReadonlyArray<{
      readonly __typename: 'OrderCartMenuSelections';
      readonly name?: string | null;
      readonly externalReferenceId?: string | null;
      readonly quantity: number;
      readonly dmpId?: string | null;
      readonly price: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'MenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DmpMasterRelationsFragmentFragment = {
  readonly __typename: 'DmpMasterRelations';
  readonly dayPeriod: string;
  readonly masterRestaurants: ReadonlyArray<{
    readonly __typename: 'MasterRestaurantLinks';
    readonly id: string;
    readonly allowModification: boolean;
    readonly count: number;
    readonly restaurant: {
      readonly __typename: 'RestaurantDelivery';
      readonly phoneNumber?: string | null;
      readonly id: string;
      readonly hasDelivery?: boolean | null;
      readonly latitude?: number | null;
      readonly longitude?: number | null;
      readonly internalName?: string | null;
      readonly name: string;
      readonly franchiseGroupId?: number | null;
      readonly franchiseGroupName?: string | null;
      readonly availability?: AvailabilityModes | null;
      readonly deliveryHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly physicalAddress?: {
        readonly __typename: 'PhysicalAddress';
        readonly address1?: string | null;
        readonly address2?: string | null;
        readonly city?: string | null;
        readonly country?: string | null;
        readonly postalCode?: string | null;
        readonly stateProvince?: string | null;
      } | null;
    };
    readonly children: ReadonlyArray<{
      readonly __typename: 'ChildRestaurantLink';
      readonly id: string;
      readonly allowModification: boolean;
      readonly restaurant: {
        readonly __typename: 'RestaurantDelivery';
        readonly phoneNumber?: string | null;
        readonly id: string;
        readonly hasDelivery?: boolean | null;
        readonly latitude?: number | null;
        readonly longitude?: number | null;
        readonly internalName?: string | null;
        readonly name: string;
        readonly franchiseGroupId?: number | null;
        readonly franchiseGroupName?: string | null;
        readonly availability?: AvailabilityModes | null;
        readonly deliveryHours?: {
          readonly __typename: 'HoursOfOperation';
          readonly friClose?: string | null;
          readonly friOpen?: string | null;
          readonly monClose?: string | null;
          readonly monOpen?: string | null;
          readonly satClose?: string | null;
          readonly satOpen?: string | null;
          readonly sunClose?: string | null;
          readonly sunOpen?: string | null;
          readonly thrClose?: string | null;
          readonly thrOpen?: string | null;
          readonly tueClose?: string | null;
          readonly tueOpen?: string | null;
          readonly wedClose?: string | null;
          readonly wedOpen?: string | null;
          readonly friAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly monAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly satAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly sunAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly thrAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly tueAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly wedAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
        } | null;
        readonly physicalAddress?: {
          readonly __typename: 'PhysicalAddress';
          readonly address1?: string | null;
          readonly address2?: string | null;
          readonly city?: string | null;
          readonly country?: string | null;
          readonly postalCode?: string | null;
          readonly stateProvince?: string | null;
        } | null;
      };
    }>;
  }>;
};

export type MasterRestaurantLinksFragmentFragment = {
  readonly __typename: 'MasterRestaurantLinks';
  readonly id: string;
  readonly allowModification: boolean;
  readonly count: number;
  readonly restaurant: {
    readonly __typename: 'RestaurantDelivery';
    readonly phoneNumber?: string | null;
    readonly id: string;
    readonly hasDelivery?: boolean | null;
    readonly latitude?: number | null;
    readonly longitude?: number | null;
    readonly internalName?: string | null;
    readonly name: string;
    readonly franchiseGroupId?: number | null;
    readonly franchiseGroupName?: string | null;
    readonly availability?: AvailabilityModes | null;
    readonly deliveryHours?: {
      readonly __typename: 'HoursOfOperation';
      readonly friClose?: string | null;
      readonly friOpen?: string | null;
      readonly monClose?: string | null;
      readonly monOpen?: string | null;
      readonly satClose?: string | null;
      readonly satOpen?: string | null;
      readonly sunClose?: string | null;
      readonly sunOpen?: string | null;
      readonly thrClose?: string | null;
      readonly thrOpen?: string | null;
      readonly tueClose?: string | null;
      readonly tueOpen?: string | null;
      readonly wedClose?: string | null;
      readonly wedOpen?: string | null;
      readonly friAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly monAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly satAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly sunAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly thrAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly tueAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly wedAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
    } | null;
    readonly physicalAddress?: {
      readonly __typename: 'PhysicalAddress';
      readonly address1?: string | null;
      readonly address2?: string | null;
      readonly city?: string | null;
      readonly country?: string | null;
      readonly postalCode?: string | null;
      readonly stateProvince?: string | null;
    } | null;
  };
  readonly children: ReadonlyArray<{
    readonly __typename: 'ChildRestaurantLink';
    readonly id: string;
    readonly allowModification: boolean;
    readonly restaurant: {
      readonly __typename: 'RestaurantDelivery';
      readonly phoneNumber?: string | null;
      readonly id: string;
      readonly hasDelivery?: boolean | null;
      readonly latitude?: number | null;
      readonly longitude?: number | null;
      readonly internalName?: string | null;
      readonly name: string;
      readonly franchiseGroupId?: number | null;
      readonly franchiseGroupName?: string | null;
      readonly availability?: AvailabilityModes | null;
      readonly deliveryHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly physicalAddress?: {
        readonly __typename: 'PhysicalAddress';
        readonly address1?: string | null;
        readonly address2?: string | null;
        readonly city?: string | null;
        readonly country?: string | null;
        readonly postalCode?: string | null;
        readonly stateProvince?: string | null;
      } | null;
    };
  }>;
};

export type ChildRestaurantLinkFragmentFragment = {
  readonly __typename: 'ChildRestaurantLink';
  readonly id: string;
  readonly allowModification: boolean;
  readonly restaurant: {
    readonly __typename: 'RestaurantDelivery';
    readonly phoneNumber?: string | null;
    readonly id: string;
    readonly hasDelivery?: boolean | null;
    readonly latitude?: number | null;
    readonly longitude?: number | null;
    readonly internalName?: string | null;
    readonly name: string;
    readonly franchiseGroupId?: number | null;
    readonly franchiseGroupName?: string | null;
    readonly availability?: AvailabilityModes | null;
    readonly deliveryHours?: {
      readonly __typename: 'HoursOfOperation';
      readonly friClose?: string | null;
      readonly friOpen?: string | null;
      readonly monClose?: string | null;
      readonly monOpen?: string | null;
      readonly satClose?: string | null;
      readonly satOpen?: string | null;
      readonly sunClose?: string | null;
      readonly sunOpen?: string | null;
      readonly thrClose?: string | null;
      readonly thrOpen?: string | null;
      readonly tueClose?: string | null;
      readonly tueOpen?: string | null;
      readonly wedClose?: string | null;
      readonly wedOpen?: string | null;
      readonly friAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly monAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly satAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly sunAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly thrAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly tueAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
      readonly wedAdditionalTimeSlot?: {
        readonly __typename: 'AdditionalTimeSlot';
        readonly open?: string | null;
        readonly close?: string | null;
      } | null;
    } | null;
    readonly physicalAddress?: {
      readonly __typename: 'PhysicalAddress';
      readonly address1?: string | null;
      readonly address2?: string | null;
      readonly city?: string | null;
      readonly country?: string | null;
      readonly postalCode?: string | null;
      readonly stateProvince?: string | null;
    } | null;
  };
};

export type CopyStorePlusMutationVariables = Exact<{
  fromStoreId: Scalars['String'];
  toStoreIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  toGroupIds: ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type CopyStorePlusMutation = {
  readonly __typename: 'Mutation';
  readonly copyStorePlus?: string | null;
};

export type UpdateMenuFromCsvMutationVariables = Exact<{
  storeId: Scalars['String'];
  channel: Channel;
  items: ReadonlyArray<UpdateMenuFromCsvInput> | UpdateMenuFromCsvInput;
}>;

export type UpdateMenuFromCsvMutation = {
  readonly __typename: 'Mutation';
  readonly updateMenuFromCSV: string;
};

export type OldUpdateMenuFromCsvMutationVariables = Exact<{
  storeId: Scalars['String'];
  items: ReadonlyArray<OldUpdateMenuFromCsvInput> | OldUpdateMenuFromCsvInput;
}>;

export type OldUpdateMenuFromCsvMutation = {
  readonly __typename: 'Mutation';
  readonly oldUpdateMenuFromCSV: string;
};

export type UpdateProductPricesMutationVariables = Exact<{
  products: ReadonlyArray<ProductPriceToUpdate> | ProductPriceToUpdate;
  serviceMode?: InputMaybe<ServiceMode>;
  v3?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateProductPricesMutation = {
  readonly __typename: 'Mutation';
  readonly updateProductPrices: {
    readonly __typename: 'ProductMutationResponse';
    readonly data: ReadonlyArray<{
      readonly __typename: 'ProductPrice';
      readonly id: string;
      readonly productId: string;
      readonly plu: string;
      readonly price?: number | null;
      readonly availability?: boolean | null;
    }>;
    readonly errors?: ReadonlyArray<{
      readonly __typename: 'Error';
      readonly code: string;
      readonly message: string;
      readonly productId?: string | null;
      readonly restaurantId?: string | null;
    } | null> | null;
  };
};

export type UpdateProductAvailabilityMutationVariables = Exact<{
  products: ReadonlyArray<ProductAvailabilityToUpdate> | ProductAvailabilityToUpdate;
  serviceMode?: InputMaybe<ServiceMode>;
  v3?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateProductAvailabilityMutation = {
  readonly __typename: 'Mutation';
  readonly updateProductAvailability: {
    readonly __typename: 'ProductMutationResponse';
    readonly data: ReadonlyArray<{
      readonly __typename: 'ProductPrice';
      readonly id: string;
      readonly productId: string;
      readonly plu: string;
      readonly price?: number | null;
      readonly availability?: boolean | null;
    }>;
    readonly errors?: ReadonlyArray<{
      readonly __typename: 'Error';
      readonly code: string;
      readonly message: string;
      readonly productId?: string | null;
      readonly restaurantId?: string | null;
    } | null> | null;
  };
};

export type UpdateProductsMutationVariables = Exact<{
  updates: UpdatesInput;
  v3?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateProductsMutation = {
  readonly __typename: 'Mutation';
  readonly updateProducts: {
    readonly __typename: 'ProductMutationResponse';
    readonly data: ReadonlyArray<{
      readonly __typename: 'ProductPrice';
      readonly id: string;
      readonly productId: string;
      readonly plu: string;
      readonly price?: number | null;
      readonly availability?: boolean | null;
    }>;
    readonly errors?: ReadonlyArray<{
      readonly __typename: 'Error';
      readonly code: string;
      readonly message: string;
      readonly productId?: string | null;
      readonly restaurantId?: string | null;
    } | null> | null;
  };
};

export type ValidateProductsMutationVariables = Exact<{
  updates: UpdatesInput;
  v3?: InputMaybe<Scalars['Boolean']>;
}>;

export type ValidateProductsMutation = {
  readonly __typename: 'Mutation';
  readonly validateProducts: {
    readonly __typename: 'ValidateProductsMutationResponse';
    readonly data?:
      | {
          readonly __typename: 'ValidateProductsResponseNewSchema';
          readonly channel: string;
          readonly region: string;
          readonly serviceMode: string;
          readonly data: ReadonlyArray<{
            readonly __typename: 'ValidateProductsData';
            readonly storeIds: ReadonlyArray<string>;
            readonly plus: ReadonlyArray<{
              readonly __typename: 'PlusResponse';
              readonly availability?: boolean | null;
              readonly draft?: string | null;
              readonly plu: string;
              readonly price?: number | null;
              readonly priority?: number | null;
              readonly repetition?: ReadonlyArray<string> | null;
              readonly sanityId?: string | null;
              readonly overrides?: ReadonlyArray<{
                readonly __typename: 'OverridesResponse';
                readonly id: string;
                readonly price: number;
              }> | null;
            }>;
          }>;
        }
      | {
          readonly __typename: 'ValidateProductsResponseOldSchema';
          readonly channel: string;
          readonly region: string;
          readonly serviceMode: string;
          readonly storeIds: ReadonlyArray<string>;
          readonly plus: ReadonlyArray<{
            readonly __typename: 'PlusResponse';
            readonly availability?: boolean | null;
            readonly draft?: string | null;
            readonly plu: string;
            readonly price?: number | null;
            readonly priority?: number | null;
            readonly repetition?: ReadonlyArray<string> | null;
            readonly sanityId?: string | null;
            readonly overrides?: ReadonlyArray<{
              readonly __typename: 'OverridesResponse';
              readonly id: string;
              readonly price: number;
            }> | null;
          }>;
        }
      | null;
    readonly errors?: ReadonlyArray<{
      readonly __typename: 'ValidateProductsError';
      readonly code: string;
      readonly message: string;
      readonly plu: string;
      readonly restaurantIds: ReadonlyArray<string>;
    } | null> | null;
  };
};

export type UpdateCashLimitationMutationVariables = Exact<{
  documentIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  updatePayload: DeliveryOrderAmountLimitInput;
}>;

export type UpdateCashLimitationMutation = {
  readonly __typename: 'Mutation';
  readonly updateRestaurantCashLimitation?: ReadonlyArray<{
    readonly __typename: 'Restaurant';
    readonly id: string;
    readonly deliveryOrderAmountLimit?: {
      readonly __typename: 'DeliveryOrderAmountLimit';
      readonly deliveryOrderAmountLimit?: number | null;
      readonly deliveryOrderAmountLimitEnabled?: boolean | null;
      readonly deliveryOrderRepeatedFailureLimitation?: boolean | null;
      readonly firstDeliveryOrder?: number | null;
      readonly firstDeliveryOrderEnabled?: boolean | null;
    } | null;
  }> | null;
};

export type UpdateAllowPaymentsMutationVariables = Exact<{
  documentIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  updatePayload: ReadonlyArray<PaymentMethodInput> | PaymentMethodInput;
  marketPayments: ReadonlyArray<MarketPaymentsInput> | MarketPaymentsInput;
}>;

export type UpdateAllowPaymentsMutation = {
  readonly __typename: 'Mutation';
  readonly updateRestaurantPayments?: ReadonlyArray<{
    readonly __typename: 'Restaurant';
    readonly id: string;
    readonly paymentMethods?: ReadonlyArray<{
      readonly __typename: 'RestaurantPaymentMethod';
      readonly paymentMethodBrand?: string | null;
      readonly state?: boolean | null;
    } | null> | null;
  }> | null;
};

export type UpsertUserMutationVariables = Exact<{
  id: Scalars['String'];
  restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  roleId: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  isNewUser?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpsertUserMutation = {
  readonly __typename: 'Mutation';
  readonly upsertUser: {
    readonly __typename: 'OktaUser';
    readonly OktaId: string;
    readonly Country: string;
    readonly RestaurantIds?: ReadonlyArray<string | null> | null;
    readonly RoleId: string;
    readonly Status?: boolean | null;
    readonly UserName?: string | null;
    readonly Email?: string | null;
  };
};

export type ChangeUserStatusMutationVariables = Exact<{
  oktaId: Scalars['String'];
  restaurantIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  role: Scalars['String'];
  isActive: Scalars['Boolean'];
  userName: Scalars['String'];
  email: Scalars['String'];
}>;

export type ChangeUserStatusMutation = {
  readonly __typename: 'Mutation';
  readonly changeUserStatus: {
    readonly __typename: 'OktaUser';
    readonly OktaId: string;
    readonly Country: string;
    readonly RoleId: string;
    readonly RestaurantIds?: ReadonlyArray<string | null> | null;
    readonly Status?: boolean | null;
    readonly UserName?: string | null;
    readonly Email?: string | null;
  };
};

export type UpsertRoleMutationVariables = Exact<{
  roleName: Scalars['String'];
  permissions: ReadonlyArray<Roles> | Roles;
  roleId?: InputMaybe<Scalars['String']>;
}>;

export type UpsertRoleMutation = {
  readonly __typename: 'Mutation';
  readonly upsertRole: {
    readonly __typename: 'Role';
    readonly RoleId: string;
    readonly RoleName: string;
    readonly Permissions?: ReadonlyArray<string> | null;
  };
};

export type DeleteRoleMutationVariables = Exact<{
  roleName: Scalars['String'];
}>;

export type DeleteRoleMutation = { readonly __typename: 'Mutation'; readonly deleteRole: string };

export type CreateDriverMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  body: CreateDriverPayload;
}>;

export type CreateDriverMutation = {
  readonly __typename: 'Mutation';
  readonly createDriver?: string | null;
};

export type UpdateDriverMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  driverId: Scalars['String'];
  body?: InputMaybe<UpdateDriverPayload>;
  password?: InputMaybe<Scalars['String']>;
  confirmPassword?: InputMaybe<Scalars['String']>;
}>;

export type UpdateDriverMutation = {
  readonly __typename: 'Mutation';
  readonly updateDriver?: string | null;
};

export type DeleteDriverMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  driverId: Scalars['String'];
}>;

export type DeleteDriverMutation = {
  readonly __typename: 'Mutation';
  readonly deleteDriver?: string | null;
};

export type CreatePolygonMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  body: CreatePolygonPayload;
}>;

export type CreatePolygonMutation = {
  readonly __typename: 'Mutation';
  readonly createPolygon?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly createdAt: string;
      readonly createdBy: string;
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly restaurantId: string;
      readonly id: string;
      readonly name: string;
      readonly updatedAt: string;
      readonly updatedBy: string;
      readonly gridArea: ReadonlyArray<{
        readonly __typename: 'PolygonsGridQuadrant';
        readonly id: string;
        readonly polygon: {
          readonly __typename: 'GeoJson';
          readonly color: string;
          readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
        };
      }>;
      readonly mainArea: {
        readonly __typename: 'GeoJson';
        readonly color: string;
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
      };
      readonly reducedArea?: {
        readonly __typename: 'GeoJson';
        readonly color: string;
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
      } | null;
    }>;
  } | null;
};

export type UpdatePolygonMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
  body: UpdatePolygonPayload;
}>;

export type UpdatePolygonMutation = {
  readonly __typename: 'Mutation';
  readonly updatePolygon?: {
    readonly __typename: 'RestaurantPolygon';
    readonly createdAt: string;
    readonly createdBy: string;
    readonly default: boolean;
    readonly pendingApproval: boolean;
    readonly restaurantId: string;
    readonly id: string;
    readonly name: string;
    readonly updatedAt: string;
    readonly updatedBy: string;
    readonly gridArea: ReadonlyArray<{
      readonly __typename: 'PolygonsGridQuadrant';
      readonly id: string;
      readonly polygon: {
        readonly __typename: 'GeoJson';
        readonly color: string;
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
      };
    }>;
    readonly mainArea: {
      readonly __typename: 'GeoJson';
      readonly color: string;
      readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
    };
    readonly reducedArea?: {
      readonly __typename: 'GeoJson';
      readonly color: string;
      readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
    } | null;
  } | null;
};

export type CancelPolygonRequestForApprovalMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
}>;

export type CancelPolygonRequestForApprovalMutation = {
  readonly __typename: 'Mutation';
  readonly cancelPolygonRequestForApproval?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly createdAt: string;
      readonly createdBy: string;
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly restaurantId: string;
      readonly id: string;
      readonly name: string;
      readonly updatedAt: string;
      readonly updatedBy: string;
    }>;
  } | null;
};

export type DeletePolygonMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
}>;

export type DeletePolygonMutation = {
  readonly __typename: 'Mutation';
  readonly deletePolygon?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly id: string;
    }>;
  } | null;
};

export type SendPolygonForApprovalMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
}>;

export type SendPolygonForApprovalMutation = {
  readonly __typename: 'Mutation';
  readonly sendPolygonForApproval?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly id: string;
      readonly name: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly createdBy: string;
      readonly updatedBy: string;
      readonly restaurantId: string;
    }>;
  } | null;
};

export type ApprovePolygonMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
}>;

export type ApprovePolygonMutation = {
  readonly __typename: 'Mutation';
  readonly approvePolygon?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly id: string;
      readonly name: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly createdBy: string;
      readonly updatedBy: string;
      readonly restaurantId: string;
      readonly gridArea: ReadonlyArray<{
        readonly __typename: 'PolygonsGridQuadrant';
        readonly id: string;
        readonly polygon: {
          readonly __typename: 'GeoJson';
          readonly color: string;
          readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
        };
      }>;
    }>;
  } | null;
};

export type RejectPolygonMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  polygonId: Scalars['String'];
}>;

export type RejectPolygonMutation = {
  readonly __typename: 'Mutation';
  readonly rejectPolygon?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly id: string;
      readonly name: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly createdBy: string;
      readonly updatedBy: string;
      readonly restaurantId: string;
    }>;
  } | null;
};

export type UpdateRestaurantSettingsMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  body: Settings;
}>;

export type UpdateRestaurantSettingsMutation = {
  readonly __typename: 'Mutation';
  readonly updateRestaurantSettings: { readonly __typename: 'RestaurantDmp'; readonly id: string };
};

export type UpdateOrderDriverMutationVariables = Exact<{
  orderId: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;

export type UpdateOrderDriverMutation = {
  readonly __typename: 'Mutation';
  readonly changeOrderDriver: {
    readonly __typename: 'RestaurantOrderDelivery';
    readonly storeId?: string | null;
    readonly createdAt: string;
    readonly totalCents?: number | null;
    readonly orderNumberFormatted?: string | null;
    readonly id: string;
    readonly delivery?: {
      readonly __typename: 'Delivery';
      readonly status: string;
      readonly driver?: {
        readonly __typename: 'OrderDriver';
        readonly driverId?: string | null;
        readonly name?: string | null;
        readonly phoneNumber?: string | null;
      } | null;
    } | null;
  };
};

export type RejectComplaintMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  complaintId: Scalars['String'];
}>;

export type RejectComplaintMutation = {
  readonly __typename: 'Mutation';
  readonly rejectComplaint?: {
    readonly __typename: 'Complaint';
    readonly id: string;
    readonly orderId?: string | null;
    readonly dmpCategory?: ComplaintDmpCategory | null;
    readonly status: ComplaintStatus;
    readonly created: string;
    readonly comments?: string | null;
    readonly resolution?: string | null;
    readonly resolutionComments?: string | null;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly order?: {
      readonly __typename: 'DmpOrderById';
      readonly brand: Brand;
      readonly channel: string;
      readonly createdAt: string;
      readonly deliveryId?: string | null;
      readonly displayOrderId?: string | null;
      readonly driverId?: string | null;
      readonly eventsUrl?: string | null;
      readonly quoteId: string;
      readonly rbiNumberId: string;
      readonly regionCode: string;
      readonly restaurantId: string;
      readonly status: string;
      readonly updatedAt: string;
      readonly cart?: {
        readonly __typename: 'OrderCart';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly compensation?: {
        readonly __typename: 'OrderCompesation';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly customer: {
        readonly __typename: 'OrderCustomer';
        readonly name: string;
        readonly id?: string | null;
        readonly phone?: string | null;
        readonly email?: string | null;
        readonly username?: string | null;
      };
      readonly deliveryAddress: {
        readonly __typename: 'Address';
        readonly administrativeArea?: string | null;
        readonly formattedAddress: string;
        readonly instructions?: string | null;
        readonly locality?: string | null;
        readonly phoneNumber?: string | null;
        readonly postalCode?: string | null;
        readonly regionCode: RegionCode;
        readonly route: string;
        readonly streetNumber: string;
        readonly sublocality?: string | null;
        readonly subpremise?: string | null;
      };
      readonly dropoffWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly events: ReadonlyArray<{
        readonly __typename: 'OrderEvents';
        readonly name: string;
        readonly time: string;
        readonly issue?: ReadonlyArray<string> | null;
      }>;
      readonly payment?: {
        readonly __typename: 'PaymentDmp';
        readonly paymentMethod: string;
        readonly paymentMethodBrand?: string | null;
        readonly amountLeftToBePaid: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly tip: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly total: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null;
      readonly pickupWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
    } | null;
    readonly customer: {
      readonly __typename: 'ComplaintsCustomer';
      readonly name: string;
      readonly email: string;
      readonly phoneNumber?: string | null;
    };
    readonly compensation?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ApproveComplaintMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  complaintId: Scalars['String'];
  orderId: Scalars['String'];
  loyaltyEngineId?: InputMaybe<Scalars['String']>;
  refundAmount?: InputMaybe<Scalars['Int']>;
}>;

export type ApproveComplaintMutation = {
  readonly __typename: 'Mutation';
  readonly approveComplaint?: {
    readonly __typename: 'Complaint';
    readonly id: string;
    readonly orderId?: string | null;
    readonly dmpCategory?: ComplaintDmpCategory | null;
    readonly status: ComplaintStatus;
    readonly created: string;
    readonly comments?: string | null;
    readonly resolution?: string | null;
    readonly resolutionComments?: string | null;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly order?: {
      readonly __typename: 'DmpOrderById';
      readonly brand: Brand;
      readonly channel: string;
      readonly createdAt: string;
      readonly deliveryId?: string | null;
      readonly displayOrderId?: string | null;
      readonly driverId?: string | null;
      readonly eventsUrl?: string | null;
      readonly quoteId: string;
      readonly rbiNumberId: string;
      readonly regionCode: string;
      readonly restaurantId: string;
      readonly status: string;
      readonly updatedAt: string;
      readonly cart?: {
        readonly __typename: 'OrderCart';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly compensation?: {
        readonly __typename: 'OrderCompesation';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly customer: {
        readonly __typename: 'OrderCustomer';
        readonly name: string;
        readonly id?: string | null;
        readonly phone?: string | null;
        readonly email?: string | null;
        readonly username?: string | null;
      };
      readonly deliveryAddress: {
        readonly __typename: 'Address';
        readonly administrativeArea?: string | null;
        readonly formattedAddress: string;
        readonly instructions?: string | null;
        readonly locality?: string | null;
        readonly phoneNumber?: string | null;
        readonly postalCode?: string | null;
        readonly regionCode: RegionCode;
        readonly route: string;
        readonly streetNumber: string;
        readonly sublocality?: string | null;
        readonly subpremise?: string | null;
      };
      readonly dropoffWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly events: ReadonlyArray<{
        readonly __typename: 'OrderEvents';
        readonly name: string;
        readonly time: string;
        readonly issue?: ReadonlyArray<string> | null;
      }>;
      readonly payment?: {
        readonly __typename: 'PaymentDmp';
        readonly paymentMethod: string;
        readonly paymentMethodBrand?: string | null;
        readonly amountLeftToBePaid: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly tip: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly total: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null;
      readonly pickupWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
    } | null;
    readonly customer: {
      readonly __typename: 'ComplaintsCustomer';
      readonly name: string;
      readonly email: string;
      readonly phoneNumber?: string | null;
    };
    readonly compensation?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CreateMasterRestaurantRelationMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  masterId: Scalars['String'];
  dayPeriod: DayPeriod;
}>;

export type CreateMasterRestaurantRelationMutation = {
  readonly __typename: 'Mutation';
  readonly createMasterRestaurantRelation: {
    readonly __typename: 'MasterRestaurantRelation';
    readonly masterId: string;
    readonly restaurantId: string;
    readonly dayPeriod: DayPeriod;
  };
};

export type DeleteMasterRestaurantRelationMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  masterId: Scalars['String'];
  dayPeriod: DayPeriod;
}>;

export type DeleteMasterRestaurantRelationMutation = {
  readonly __typename: 'Mutation';
  readonly deleteMasterRestaurantRelation: string;
};

export type UpdateDmpPasswordMutationVariables = Exact<{
  restaurantId: Scalars['String'];
  body: ChangeDmpPasswordInput;
}>;

export type UpdateDmpPasswordMutation = {
  readonly __typename: 'Mutation';
  readonly updateDmpPassword?: string | null;
};

export type PutRiderAppPolicyMutationVariables = Exact<{
  body: CreatePolicy;
}>;

export type PutRiderAppPolicyMutation = {
  readonly __typename: 'Mutation';
  readonly putRiderAppPolicy?: string | null;
};

export type DeleteRiderAppPolicyMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteRiderAppPolicyMutation = {
  readonly __typename: 'Mutation';
  readonly deleteRiderAppPolicy?: string | null;
};

export type GetMenuQueryVariables = Exact<{
  region?: InputMaybe<Scalars['String']>;
}>;

export type GetMenuQuery = { readonly __typename: 'Query'; readonly menu?: string | null };

export type GetUserManagementRestaurantsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pos?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
}>;

export type GetUserManagementRestaurantsQuery = {
  readonly __typename: 'Query';
  readonly allRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly groups?: string | null;
      readonly name: string;
      readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
    }>;
  } | null;
};

export type GetRestaurantGroupByIdQueryVariables = Exact<{
  groupIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;

export type GetRestaurantGroupByIdQuery = {
  readonly __typename: 'Query';
  readonly getRestaurantGroupsByIds?: ReadonlyArray<{
    readonly __typename: 'RestaurantGroup';
    readonly _id: string;
    readonly name: string;
    readonly restaurants?: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
    }> | null;
  } | null> | null;
};

export type GetRestaurantsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pos?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  availability?: InputMaybe<
    ReadonlyArray<InputMaybe<AvailabilityModes>> | InputMaybe<AvailabilityModes>
  >;
  environment?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
}>;

export type GetRestaurantsQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly totalCount?: number | null;
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly hasCurbside?: boolean | null;
      readonly hasDelivery?: boolean | null;
      readonly hasDineIn?: boolean | null;
      readonly hasDriveThru?: boolean | null;
      readonly hasLateNightPricing?: boolean | null;
      readonly hasMobileOrdering?: boolean | null;
      readonly latitude?: number | null;
      readonly longitude?: number | null;
      readonly environment?: string | null;
      readonly mobileOrderingStatus?: string | null;
      readonly name: string;
      readonly sanityDocumentId?: string | null;
      readonly vendor?: string | null;
      readonly groups?: string | null;
      readonly availability?: AvailabilityModes | null;
      readonly deliveryHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly diningRoomHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly curbsideHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly driveThruHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly physicalAddress?: {
        readonly __typename: 'PhysicalAddress';
        readonly address1?: string | null;
        readonly address2?: string | null;
        readonly city?: string | null;
        readonly country?: string | null;
        readonly postalCode?: string | null;
        readonly stateProvince?: string | null;
      } | null;
      readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
    }>;
  } | null;
};

export type GetRestaurantsShortQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pos?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  availability?: InputMaybe<
    ReadonlyArray<InputMaybe<AvailabilityModes>> | InputMaybe<AvailabilityModes>
  >;
  region?: InputMaybe<Scalars['String']>;
}>;

export type GetRestaurantsShortQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly totalCount?: number | null;
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GetRestaurantsShortWithMenuPricesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  restaurantsIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  serviceMode?: InputMaybe<ServiceMode>;
}>;

export type GetRestaurantsShortWithMenuPricesQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly totalCount?: number | null;
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly name: string;
      readonly menuPrices?: ReadonlyArray<{
        readonly __typename: 'ProductPrice';
        readonly id: string;
        readonly productId: string;
        readonly plu: string;
        readonly price?: number | null;
        readonly availability?: boolean | null;
      }> | null;
    }>;
  } | null;
};

export type GetRestaurantsWithMenuItemsQueryVariables = Exact<{
  productIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  serviceMode?: InputMaybe<ServiceMode>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetRestaurantsWithMenuItemsQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly totalCount?: number | null;
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly groups?: string | null;
      readonly name: string;
      readonly menuPrices?: ReadonlyArray<{
        readonly __typename: 'ProductPrice';
        readonly id: string;
        readonly productId: string;
        readonly plu: string;
        readonly price?: number | null;
        readonly availability?: boolean | null;
      }> | null;
      readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
    }>;
  } | null;
};

export type GetRestaurantsPaymentConfigQueryVariables = Exact<{
  restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
}>;

export type GetRestaurantsPaymentConfigQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly deliveryOrderAmountLimit?: {
        readonly __typename: 'DeliveryOrderAmountLimit';
        readonly deliveryOrderAmountLimit?: number | null;
        readonly deliveryOrderAmountLimitEnabled?: boolean | null;
        readonly deliveryOrderRepeatedFailureLimitation?: boolean | null;
        readonly firstDeliveryOrder?: number | null;
        readonly firstDeliveryOrderEnabled?: boolean | null;
      } | null;
      readonly paymentMethods?: ReadonlyArray<{
        readonly __typename: 'RestaurantPaymentMethod';
        readonly name?: string | null;
        readonly paymentMethodBrand?: string | null;
        readonly isOnlinePayment?: boolean | null;
        readonly state?: boolean | null;
      } | null> | null;
    }>;
  } | null;
};

export type GetMenuPricesForEditorQueryVariables = Exact<{
  restaurantsIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  productIdPlus?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  channel: Channel;
  serviceMode: ServiceMode;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetMenuPricesForEditorQuery = {
  readonly __typename: 'Query';
  readonly userRestaurants?: {
    readonly __typename: 'AllRestaurants';
    readonly totalCount?: number | null;
    readonly allRestaurants: ReadonlyArray<{
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly groups?: string | null;
      readonly name: string;
      readonly menuPricesV3?: ReadonlyArray<{
        readonly __typename: 'ProductPriceV3';
        readonly id: string;
        readonly restaurantId: string;
        readonly serviceMode: string;
        readonly channel: string;
        readonly plu: string;
        readonly price?: number | null;
        readonly availability?: boolean | null;
        readonly draft?: string | null;
        readonly priority?: number | null;
        readonly repetition?: ReadonlyArray<string> | null;
        readonly overrides?: ReadonlyArray<{
          readonly __typename: 'Overrides';
          readonly id: string;
          readonly price: number;
        }> | null;
      }> | null;
      readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
    }>;
  } | null;
};

export type GetRestaurantGroupsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
}>;

export type GetRestaurantGroupsQuery = {
  readonly __typename: 'Query';
  readonly allRestaurantGroups: {
    readonly __typename: 'AllRestaurantGroups';
    readonly groups: ReadonlyArray<{
      readonly __typename: 'RestaurantGroup';
      readonly _id: string;
      readonly name: string;
      readonly restaurants?: ReadonlyArray<{
        readonly __typename: 'Restaurant';
        readonly id: string;
        readonly pos?: { readonly __typename: 'PosVendor'; readonly vendor?: string | null } | null;
      }> | null;
    }>;
  };
};

export type GetOrdersQueryVariables = Exact<{
  restaurantIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrdersQuery = {
  readonly __typename: 'Query';
  readonly allOrders?: {
    readonly __typename: 'AllOrders';
    readonly restaurantsIds?: ReadonlyArray<string> | null;
    readonly allOrders: ReadonlyArray<{
      readonly __typename: 'RestaurantOrder';
      readonly orderId?: string | null;
      readonly storeId?: string | null;
      readonly totalCents?: number | null;
      readonly createdAt?: string | null;
      readonly items?: string | null;
    }>;
  } | null;
};

export type GetHistoryQueryVariables = Exact<{
  restaurantId: Scalars['String'];
  userOktaId: Scalars['String'];
  status: Scalars['String'];
  startKey: Scalars['String'];
  source: Scalars['String'];
}>;

export type GetHistoryQuery = {
  readonly __typename: 'Query';
  readonly history?: {
    readonly __typename: 'AllHistory';
    readonly nextPageKey?: string | null;
    readonly source?: string | null;
    readonly allHistory: ReadonlyArray<{
      readonly __typename: 'History';
      readonly date: string;
      readonly status: string;
      readonly storeId: string;
      readonly userOktaId: string;
      readonly userEmail: string;
      readonly serviceMode: string;
      readonly channel: string;
      readonly updates: ReadonlyArray<{
        readonly __typename: 'HistoryUpdate';
        readonly product: string;
        readonly price?: number | null;
        readonly availability?: boolean | null;
      } | null>;
    } | null>;
  } | null;
};

export type GetAllRestaurantsDeliveryQueryQueryVariables = Exact<{
  restaurantsIds?: InputMaybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllRestaurantsDeliveryQueryQuery = {
  readonly __typename: 'Query';
  readonly allRestaurantsDelivery?: {
    readonly __typename: 'AllRestaurantsDelivery';
    readonly totalCount?: number | null;
    readonly allRestaurantsDelivery: ReadonlyArray<{
      readonly __typename: 'RestaurantDelivery';
      readonly id: string;
      readonly hasDelivery?: boolean | null;
      readonly latitude?: number | null;
      readonly longitude?: number | null;
      readonly internalName?: string | null;
      readonly name: string;
      readonly franchiseGroupId?: number | null;
      readonly franchiseGroupName?: string | null;
      readonly availability?: AvailabilityModes | null;
      readonly deliveryHours?: {
        readonly __typename: 'HoursOfOperation';
        readonly friClose?: string | null;
        readonly friOpen?: string | null;
        readonly monClose?: string | null;
        readonly monOpen?: string | null;
        readonly satClose?: string | null;
        readonly satOpen?: string | null;
        readonly sunClose?: string | null;
        readonly sunOpen?: string | null;
        readonly thrClose?: string | null;
        readonly thrOpen?: string | null;
        readonly tueClose?: string | null;
        readonly tueOpen?: string | null;
        readonly wedClose?: string | null;
        readonly wedOpen?: string | null;
        readonly friAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly monAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly satAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly sunAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly thrAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly tueAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
        readonly wedAdditionalTimeSlot?: {
          readonly __typename: 'AdditionalTimeSlot';
          readonly open?: string | null;
          readonly close?: string | null;
        } | null;
      } | null;
      readonly physicalAddress?: {
        readonly __typename: 'PhysicalAddress';
        readonly address1?: string | null;
        readonly address2?: string | null;
        readonly city?: string | null;
        readonly country?: string | null;
        readonly postalCode?: string | null;
        readonly stateProvince?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetUserByOktaIdQueryVariables = Exact<{
  oktaId: Scalars['String'];
}>;

export type GetUserByOktaIdQuery = {
  readonly __typename: 'Query';
  readonly users?: ReadonlyArray<{
    readonly __typename: 'OktaUser';
    readonly OktaId: string;
    readonly Country: string;
    readonly RestaurantIds?: ReadonlyArray<string | null> | null;
    readonly RoleId: string;
    readonly RoleName?: string | null;
    readonly UserName?: string | null;
    readonly Email?: string | null;
  } | null> | null;
};

export type GetUserPermissionsByOktaIdQueryVariables = Exact<{
  oktaId: Scalars['String'];
}>;

export type GetUserPermissionsByOktaIdQuery = {
  readonly __typename: 'Query';
  readonly users?: ReadonlyArray<{
    readonly __typename: 'OktaUser';
    readonly OktaId: string;
    readonly Country: string;
    readonly RestaurantIds?: ReadonlyArray<string | null> | null;
    readonly RoleId: string;
    readonly RoleName?: string | null;
    readonly Permissions?: ReadonlyArray<string | null> | null;
    readonly UserName?: string | null;
    readonly Email?: string | null;
  } | null> | null;
};

export type GetAllDriversQueryVariables = Exact<{
  restaurantId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllDriversQuery = {
  readonly __typename: 'Query';
  readonly allDrivers?: {
    readonly __typename: 'AllDrivers';
    readonly allDrivers: ReadonlyArray<{
      readonly __typename: 'Driver';
      readonly availabilityStatus?: DriverAvailabilityStatus | null;
      readonly deliveryAvailabilityStatus?: DriverDeliveryAvailabilityStatus | null;
      readonly document?: string | null;
      readonly email?: string | null;
      readonly createdAt?: string | null;
      readonly updatedAt?: string | null;
      readonly firstName?: string | null;
      readonly lastName?: string | null;
      readonly identifier?: string | null;
      readonly orderLimit?: number | null;
      readonly motorbike?: string | null;
      readonly phoneNumber?: string | null;
      readonly realtimeStatus?: DriverRealtimeStatus | null;
      readonly restaurant?: string | null;
    }>;
  } | null;
};

export type GetDriverByIdQueryVariables = Exact<{
  driverId: Scalars['String'];
  restaurantId: Scalars['String'];
}>;

export type GetDriverByIdQuery = {
  readonly __typename: 'Query';
  readonly driver?: {
    readonly __typename: 'Driver';
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly document?: string | null;
    readonly identifier?: string | null;
    readonly phoneNumber?: string | null;
    readonly orderLimit?: number | null;
    readonly email?: string | null;
  } | null;
};

export type GetDriverNameQueryVariables = Exact<{
  driverId: Scalars['String'];
  restaurantId: Scalars['String'];
}>;

export type GetDriverNameQuery = {
  readonly __typename: 'Query';
  readonly driver?: {
    readonly __typename: 'Driver';
    readonly firstName?: string | null;
    readonly lastName?: string | null;
  } | null;
};

export type PolygonsQueryVariables = Exact<{
  restaurantId: Scalars['String'];
}>;

export type PolygonsQuery = {
  readonly __typename: 'Query';
  readonly polygons?: {
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly restaurantId: string;
      readonly createdAt: string;
      readonly createdBy: string;
      readonly updatedAt: string;
      readonly updatedBy: string;
      readonly id: string;
      readonly name: string;
      readonly gridArea: ReadonlyArray<{
        readonly __typename: 'PolygonsGridQuadrant';
        readonly id: string;
        readonly polygon: {
          readonly __typename: 'GeoJson';
          readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
          readonly color: string;
        };
      }>;
      readonly mainArea: {
        readonly __typename: 'GeoJson';
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
        readonly color: string;
      };
      readonly reducedArea?: {
        readonly __typename: 'GeoJson';
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
        readonly color: string;
      } | null;
    }>;
  } | null;
};

export type MultiplePolygonsQueryVariables = Exact<{ [key: string]: never }>;

export type MultiplePolygonsQuery = {
  readonly __typename: 'Query';
  readonly multiplePolygons: ReadonlyArray<{
    readonly __typename: 'RestaurantPolygonList';
    readonly id: string;
    readonly polygons: ReadonlyArray<{
      readonly __typename: 'RestaurantPolygon';
      readonly default: boolean;
      readonly pendingApproval: boolean;
      readonly id: string;
      readonly name: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly createdBy: string;
      readonly updatedBy: string;
      readonly restaurantId: string;
      readonly gridArea: ReadonlyArray<{
        readonly __typename: 'PolygonsGridQuadrant';
        readonly id: string;
        readonly polygon: {
          readonly __typename: 'GeoJson';
          readonly color: string;
          readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
        };
      }>;
      readonly mainArea: {
        readonly __typename: 'GeoJson';
        readonly color: string;
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
      };
      readonly reducedArea?: {
        readonly __typename: 'GeoJson';
        readonly color: string;
        readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<number>>>;
      } | null;
    }>;
  }>;
};

export type GetUsersByCountryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersByCountryQuery = {
  readonly __typename: 'Query';
  readonly users?: ReadonlyArray<{
    readonly __typename: 'OktaUser';
    readonly OktaId: string;
    readonly UserName?: string | null;
    readonly Country: string;
    readonly RoleId: string;
    readonly RoleName?: string | null;
    readonly Email?: string | null;
    readonly RestaurantIds?: ReadonlyArray<string | null> | null;
    readonly Status?: boolean | null;
  } | null> | null;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  readonly __typename: 'Query';
  readonly roles?: ReadonlyArray<{
    readonly __typename: 'Role';
    readonly RoleId: string;
    readonly RoleName: string;
    readonly Permissions?: ReadonlyArray<string> | null;
    readonly CreatedAt?: string | null;
  } | null> | null;
};

export type GetRestaurantSettingsQueryVariables = Exact<{
  restaurantId: Scalars['String'];
}>;

export type GetRestaurantSettingsQuery = {
  readonly __typename: 'Query';
  readonly restaurant: {
    readonly __typename: 'RestaurantDmp';
    readonly id: string;
    readonly name: string;
    readonly settings: {
      readonly __typename: 'RestaurantSettings';
      readonly driverOrderLimit: number;
      readonly autoFireRadius: number;
      readonly allowAutoPrepare: boolean;
      readonly allowAutoFire: boolean;
      readonly allowPauseService: boolean;
      readonly allowReducedArea: boolean;
      readonly allowRefund?: boolean | null;
      readonly allowVoucher?: boolean | null;
      readonly deliverySettings: {
        readonly __typename: 'DeliverySettings';
        readonly deliveryCost: string;
        readonly eta: number;
      };
      readonly notifications: {
        readonly __typename: 'NotificationSettings';
        readonly deliveryArea: boolean;
        readonly eta: boolean;
        readonly pauseService: boolean;
        readonly posOffline: boolean;
      };
      readonly lastMile: {
        readonly __typename: 'LastMileSettings';
        readonly providers: ReadonlyArray<LastMileProviders | null>;
        readonly catcherLocationId?: string | null;
        readonly settings?: ReadonlyArray<{
          readonly __typename: 'LastMileProviderConfiguration';
          readonly provider: LastMileProviders;
          readonly disabledPaymentMethods: ReadonlyArray<string>;
        } | null> | null;
      };
    };
  };
  readonly etaValues: {
    readonly __typename: 'EtaValues';
    readonly etaValues: ReadonlyArray<string>;
  };
};

export type GetLastMileProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastMileProvidersQuery = {
  readonly __typename: 'Query';
  readonly getLastMileProviders?: {
    readonly __typename: 'LastMileProvidersObject';
    readonly lastMileProviders?: ReadonlyArray<LastMileProviders | null> | null;
  } | null;
};

export type GetRestaurantOrderLimitQueryVariables = Exact<{
  restaurantId: Scalars['String'];
}>;

export type GetRestaurantOrderLimitQuery = {
  readonly __typename: 'Query';
  readonly restaurant: {
    readonly __typename: 'RestaurantDmp';
    readonly settings: {
      readonly __typename: 'RestaurantSettings';
      readonly driverOrderLimit: number;
    };
  };
};

export type GetAllChangeDriversQueryVariables = Exact<{
  restaurantId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllChangeDriversQuery = {
  readonly __typename: 'Query';
  readonly allDrivers?: {
    readonly __typename: 'AllDrivers';
    readonly allDrivers: ReadonlyArray<{
      readonly __typename: 'Driver';
      readonly firstName?: string | null;
      readonly lastName?: string | null;
      readonly identifier?: string | null;
      readonly phoneNumber?: string | null;
    }>;
  } | null;
};

export type GetClosedOrdersQueryVariables = Exact<{
  restaurantId: Scalars['String'];
}>;

export type GetClosedOrdersQuery = {
  readonly __typename: 'Query';
  readonly closedOrders: ReadonlyArray<{
    readonly __typename: 'RestaurantOrderDelivery';
    readonly createdAt: string;
    readonly orderNumberFormatted?: string | null;
    readonly totalCents?: number | null;
    readonly paymentType?: string | null;
    readonly paymentMethodBrand?: string | null;
    readonly id: string;
    readonly delivery?: {
      readonly __typename: 'Delivery';
      readonly status: string;
      readonly driver?: {
        readonly __typename: 'OrderDriver';
        readonly name?: string | null;
      } | null;
    } | null;
  }>;
};

export type GetAllRestaurantsDeliveryNameQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRestaurantsDeliveryNameQuery = {
  readonly __typename: 'Query';
  readonly allRestaurantsDelivery?: {
    readonly __typename: 'AllRestaurantsDelivery';
    readonly allRestaurantsDelivery: ReadonlyArray<{
      readonly __typename: 'RestaurantDelivery';
      readonly internalName?: string | null;
      readonly name: string;
      readonly id: string;
    }>;
  } | null;
};

export type GetRestaurantServiceStatusQueryVariables = Exact<{
  restaurantId: Scalars['String'];
}>;

export type GetRestaurantServiceStatusQuery = {
  readonly __typename: 'Query';
  readonly restaurant: {
    readonly __typename: 'RestaurantDmp';
    readonly id: string;
    readonly name: string;
    readonly settings: {
      readonly __typename: 'RestaurantSettings';
      readonly serviceStatus: {
        readonly __typename: 'ServiceStatusSettings';
        readonly status: ServiceStatus;
      };
    };
  };
};

export type GetRestaurantsServiceStatusQueryVariables = Exact<{
  restaurantIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;

export type GetRestaurantsServiceStatusQuery = {
  readonly __typename: 'Query';
  readonly allRestaurantsServiceStatus: ReadonlyArray<{
    readonly __typename: 'AllRestaurantsServiceStatus';
    readonly restaurantId: string;
    readonly restaurantName: string;
    readonly dmpServiceStatus: string;
  } | null>;
};

export type AllDmpOrdersQueryVariables = Exact<{
  restaurantIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
}>;

export type AllDmpOrdersQuery = {
  readonly __typename: 'Query';
  readonly allDmpOrders: {
    readonly __typename: 'AllDmpOrders';
    readonly numberOfActiveDrivers: string;
    readonly orders: ReadonlyArray<{
      readonly __typename: 'DmpOrder';
      readonly deliveryMode: DeliveryMode;
      readonly lastMileDeliveryId?: string | null;
      readonly lastMileProvider?: string | null;
      readonly channel: string;
      readonly createdAt: string;
      readonly lastEventTime: string;
      readonly driverName: string;
      readonly formattedAddress: string;
      readonly orderId: string;
      readonly displayOrderId?: string | null;
      readonly quadrant: string;
      readonly restaurantId: string;
      readonly status: string;
    }>;
  };
};

export type GetDmpOrderByIdQueryVariables = Exact<{
  restaurantId: Scalars['String'];
  orderId: Scalars['String'];
}>;

export type GetDmpOrderByIdQuery = {
  readonly __typename: 'Query';
  readonly getDmpOrderById: {
    readonly __typename: 'DmpOrderById';
    readonly brand: Brand;
    readonly channel: string;
    readonly createdAt: string;
    readonly deliveryId?: string | null;
    readonly displayOrderId?: string | null;
    readonly driverId?: string | null;
    readonly eventsUrl?: string | null;
    readonly quoteId: string;
    readonly rbiNumberId: string;
    readonly regionCode: string;
    readonly restaurantId: string;
    readonly status: string;
    readonly updatedAt: string;
    readonly cart?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
    readonly compensation?: {
      readonly __typename: 'OrderCompesation';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
    readonly customer: {
      readonly __typename: 'OrderCustomer';
      readonly name: string;
      readonly id?: string | null;
      readonly phone?: string | null;
      readonly email?: string | null;
      readonly username?: string | null;
    };
    readonly deliveryAddress: {
      readonly __typename: 'Address';
      readonly administrativeArea?: string | null;
      readonly formattedAddress: string;
      readonly instructions?: string | null;
      readonly locality?: string | null;
      readonly phoneNumber?: string | null;
      readonly postalCode?: string | null;
      readonly regionCode: RegionCode;
      readonly route: string;
      readonly streetNumber: string;
      readonly sublocality?: string | null;
      readonly subpremise?: string | null;
    };
    readonly dropoffWindow?: {
      readonly __typename: 'TimeWindow';
      readonly end: string;
      readonly start: string;
    } | null;
    readonly events: ReadonlyArray<{
      readonly __typename: 'OrderEvents';
      readonly name: string;
      readonly time: string;
      readonly issue?: ReadonlyArray<string> | null;
    }>;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly pickupWindow?: {
      readonly __typename: 'TimeWindow';
      readonly end: string;
      readonly start: string;
    } | null;
    readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
  };
};

export type GetNotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNotificationsQuery = {
  readonly __typename: 'Query';
  readonly notifications: ReadonlyArray<{
    readonly __typename: 'Notification';
    readonly createdAt: string;
    readonly eventType: string;
    readonly id: string;
    readonly restaurantId: string;
    readonly restaurantName: string;
    readonly eventValue: {
      readonly __typename: 'RestaurantSettings';
      readonly driverOrderLimit: number;
      readonly allowPauseService: boolean;
      readonly allowReducedArea: boolean;
      readonly capacitySettings: {
        readonly __typename: 'RestaurantCapacity';
        readonly capacity: string;
        readonly updatedAt: string;
      };
      readonly deliverySettings: {
        readonly __typename: 'DeliverySettings';
        readonly deliveryCost: string;
        readonly eta: number;
      };
      readonly deliveryTime: ReadonlyArray<{
        readonly __typename: 'DeliveryTimeSettings';
        readonly weekday: string;
        readonly periods: ReadonlyArray<{
          readonly __typename: 'WorkingPeriod';
          readonly finish: string;
          readonly start: string;
        } | null>;
      } | null>;
      readonly serviceStatus: {
        readonly __typename: 'ServiceStatusSettings';
        readonly reason?: string | null;
        readonly status: ServiceStatus;
        readonly updatedAt: string;
      };
      readonly notifications: {
        readonly __typename: 'NotificationSettings';
        readonly deliveryArea: boolean;
        readonly eta: boolean;
        readonly pauseService: boolean;
        readonly posOffline: boolean;
      };
      readonly posStatus: {
        readonly __typename: 'PosStatus';
        readonly available: boolean;
        readonly updatedAt: string;
      };
    };
  }>;
};

export type GetComplaintsQueryVariables = Exact<{
  restaurantsIds: ReadonlyArray<Scalars['String']> | Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;

export type GetComplaintsQuery = {
  readonly __typename: 'Query';
  readonly complaints: ReadonlyArray<{
    readonly __typename: 'Complaint';
    readonly id: string;
    readonly orderId?: string | null;
    readonly dmpCategory?: ComplaintDmpCategory | null;
    readonly status: ComplaintStatus;
    readonly created: string;
    readonly comments?: string | null;
    readonly resolution?: string | null;
    readonly resolutionComments?: string | null;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly order?: {
      readonly __typename: 'DmpOrderById';
      readonly brand: Brand;
      readonly channel: string;
      readonly createdAt: string;
      readonly deliveryId?: string | null;
      readonly displayOrderId?: string | null;
      readonly driverId?: string | null;
      readonly eventsUrl?: string | null;
      readonly quoteId: string;
      readonly rbiNumberId: string;
      readonly regionCode: string;
      readonly restaurantId: string;
      readonly status: string;
      readonly updatedAt: string;
      readonly cart?: {
        readonly __typename: 'OrderCart';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly compensation?: {
        readonly __typename: 'OrderCompesation';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly customer: {
        readonly __typename: 'OrderCustomer';
        readonly name: string;
        readonly id?: string | null;
        readonly phone?: string | null;
        readonly email?: string | null;
        readonly username?: string | null;
      };
      readonly deliveryAddress: {
        readonly __typename: 'Address';
        readonly administrativeArea?: string | null;
        readonly formattedAddress: string;
        readonly instructions?: string | null;
        readonly locality?: string | null;
        readonly phoneNumber?: string | null;
        readonly postalCode?: string | null;
        readonly regionCode: RegionCode;
        readonly route: string;
        readonly streetNumber: string;
        readonly sublocality?: string | null;
        readonly subpremise?: string | null;
      };
      readonly dropoffWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly events: ReadonlyArray<{
        readonly __typename: 'OrderEvents';
        readonly name: string;
        readonly time: string;
        readonly issue?: ReadonlyArray<string> | null;
      }>;
      readonly payment?: {
        readonly __typename: 'PaymentDmp';
        readonly paymentMethod: string;
        readonly paymentMethodBrand?: string | null;
        readonly amountLeftToBePaid: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly tip: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly total: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null;
      readonly pickupWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
    } | null;
    readonly customer: {
      readonly __typename: 'ComplaintsCustomer';
      readonly name: string;
      readonly email: string;
      readonly phoneNumber?: string | null;
    };
    readonly compensation?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
  }>;
};

export type GetComplaintDetailsQueryVariables = Exact<{
  restaurantId: Scalars['String'];
  complaintId: Scalars['String'];
}>;

export type GetComplaintDetailsQuery = {
  readonly __typename: 'Query';
  readonly complaintDetails: {
    readonly __typename: 'Complaint';
    readonly id: string;
    readonly orderId?: string | null;
    readonly dmpCategory?: ComplaintDmpCategory | null;
    readonly status: ComplaintStatus;
    readonly created: string;
    readonly comments?: string | null;
    readonly resolution?: string | null;
    readonly resolutionComments?: string | null;
    readonly payment?: {
      readonly __typename: 'PaymentDmp';
      readonly paymentMethod: string;
      readonly paymentMethodBrand?: string | null;
      readonly amountLeftToBePaid: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly tip: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
      readonly total: {
        readonly __typename: 'Amount';
        readonly amount: number;
        readonly currency: string;
      };
    } | null;
    readonly order?: {
      readonly __typename: 'DmpOrderById';
      readonly brand: Brand;
      readonly channel: string;
      readonly createdAt: string;
      readonly deliveryId?: string | null;
      readonly displayOrderId?: string | null;
      readonly driverId?: string | null;
      readonly eventsUrl?: string | null;
      readonly quoteId: string;
      readonly rbiNumberId: string;
      readonly regionCode: string;
      readonly restaurantId: string;
      readonly status: string;
      readonly updatedAt: string;
      readonly cart?: {
        readonly __typename: 'OrderCart';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly compensation?: {
        readonly __typename: 'OrderCompesation';
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'OrderCartMenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
          readonly menuSelections?: ReadonlyArray<{
            readonly __typename: 'MenuSelections';
            readonly name?: string | null;
            readonly externalReferenceId?: string | null;
            readonly quantity: number;
            readonly dmpId?: string | null;
            readonly price: {
              readonly __typename: 'Amount';
              readonly amount: number;
              readonly currency: string;
            };
          } | null> | null;
        } | null> | null;
      } | null;
      readonly customer: {
        readonly __typename: 'OrderCustomer';
        readonly name: string;
        readonly id?: string | null;
        readonly phone?: string | null;
        readonly email?: string | null;
        readonly username?: string | null;
      };
      readonly deliveryAddress: {
        readonly __typename: 'Address';
        readonly administrativeArea?: string | null;
        readonly formattedAddress: string;
        readonly instructions?: string | null;
        readonly locality?: string | null;
        readonly phoneNumber?: string | null;
        readonly postalCode?: string | null;
        readonly regionCode: RegionCode;
        readonly route: string;
        readonly streetNumber: string;
        readonly sublocality?: string | null;
        readonly subpremise?: string | null;
      };
      readonly dropoffWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly events: ReadonlyArray<{
        readonly __typename: 'OrderEvents';
        readonly name: string;
        readonly time: string;
        readonly issue?: ReadonlyArray<string> | null;
      }>;
      readonly payment?: {
        readonly __typename: 'PaymentDmp';
        readonly paymentMethod: string;
        readonly paymentMethodBrand?: string | null;
        readonly amountLeftToBePaid: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly tip: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly total: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
      } | null;
      readonly pickupWindow?: {
        readonly __typename: 'TimeWindow';
        readonly end: string;
        readonly start: string;
      } | null;
      readonly quadrant: { readonly __typename: 'OrderQuadrant'; readonly name: string };
    } | null;
    readonly customer: {
      readonly __typename: 'ComplaintsCustomer';
      readonly name: string;
      readonly email: string;
      readonly phoneNumber?: string | null;
    };
    readonly compensation?: {
      readonly __typename: 'OrderCart';
      readonly menuSelections?: ReadonlyArray<{
        readonly __typename: 'OrderCartMenuSelections';
        readonly name?: string | null;
        readonly externalReferenceId?: string | null;
        readonly quantity: number;
        readonly dmpId?: string | null;
        readonly price: {
          readonly __typename: 'Amount';
          readonly amount: number;
          readonly currency: string;
        };
        readonly menuSelections?: ReadonlyArray<{
          readonly __typename: 'MenuSelections';
          readonly name?: string | null;
          readonly externalReferenceId?: string | null;
          readonly quantity: number;
          readonly dmpId?: string | null;
          readonly price: {
            readonly __typename: 'Amount';
            readonly amount: number;
            readonly currency: string;
          };
        } | null> | null;
      } | null> | null;
    } | null;
  };
};

export type GetVoucherComplaintQueryVariables = Exact<{ [key: string]: never }>;

export type GetVoucherComplaintQuery = {
  readonly __typename: 'Query';
  readonly getVoucherComplaint?: ReadonlyArray<{
    readonly __typename: 'VoucherComplaint';
    readonly loyaltyEngineId: string;
    readonly text: string;
  } | null> | null;
};

export type GetMasterRestaurantsDeliveryQueryVariables = Exact<{ [key: string]: never }>;

export type GetMasterRestaurantsDeliveryQuery = {
  readonly __typename: 'Query';
  readonly masterRestaurantsDelivery: ReadonlyArray<{
    readonly __typename: 'DmpMasterRelations';
    readonly dayPeriod: string;
    readonly masterRestaurants: ReadonlyArray<{
      readonly __typename: 'MasterRestaurantLinks';
      readonly id: string;
      readonly allowModification: boolean;
      readonly count: number;
      readonly restaurant: {
        readonly __typename: 'RestaurantDelivery';
        readonly phoneNumber?: string | null;
        readonly id: string;
        readonly hasDelivery?: boolean | null;
        readonly latitude?: number | null;
        readonly longitude?: number | null;
        readonly internalName?: string | null;
        readonly name: string;
        readonly franchiseGroupId?: number | null;
        readonly franchiseGroupName?: string | null;
        readonly availability?: AvailabilityModes | null;
        readonly deliveryHours?: {
          readonly __typename: 'HoursOfOperation';
          readonly friClose?: string | null;
          readonly friOpen?: string | null;
          readonly monClose?: string | null;
          readonly monOpen?: string | null;
          readonly satClose?: string | null;
          readonly satOpen?: string | null;
          readonly sunClose?: string | null;
          readonly sunOpen?: string | null;
          readonly thrClose?: string | null;
          readonly thrOpen?: string | null;
          readonly tueClose?: string | null;
          readonly tueOpen?: string | null;
          readonly wedClose?: string | null;
          readonly wedOpen?: string | null;
          readonly friAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly monAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly satAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly sunAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly thrAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly tueAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
          readonly wedAdditionalTimeSlot?: {
            readonly __typename: 'AdditionalTimeSlot';
            readonly open?: string | null;
            readonly close?: string | null;
          } | null;
        } | null;
        readonly physicalAddress?: {
          readonly __typename: 'PhysicalAddress';
          readonly address1?: string | null;
          readonly address2?: string | null;
          readonly city?: string | null;
          readonly country?: string | null;
          readonly postalCode?: string | null;
          readonly stateProvince?: string | null;
        } | null;
      };
      readonly children: ReadonlyArray<{
        readonly __typename: 'ChildRestaurantLink';
        readonly id: string;
        readonly allowModification: boolean;
        readonly restaurant: {
          readonly __typename: 'RestaurantDelivery';
          readonly phoneNumber?: string | null;
          readonly id: string;
          readonly hasDelivery?: boolean | null;
          readonly latitude?: number | null;
          readonly longitude?: number | null;
          readonly internalName?: string | null;
          readonly name: string;
          readonly franchiseGroupId?: number | null;
          readonly franchiseGroupName?: string | null;
          readonly availability?: AvailabilityModes | null;
          readonly deliveryHours?: {
            readonly __typename: 'HoursOfOperation';
            readonly friClose?: string | null;
            readonly friOpen?: string | null;
            readonly monClose?: string | null;
            readonly monOpen?: string | null;
            readonly satClose?: string | null;
            readonly satOpen?: string | null;
            readonly sunClose?: string | null;
            readonly sunOpen?: string | null;
            readonly thrClose?: string | null;
            readonly thrOpen?: string | null;
            readonly tueClose?: string | null;
            readonly tueOpen?: string | null;
            readonly wedClose?: string | null;
            readonly wedOpen?: string | null;
            readonly friAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly monAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly satAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly sunAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly thrAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly tueAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
            readonly wedAdditionalTimeSlot?: {
              readonly __typename: 'AdditionalTimeSlot';
              readonly open?: string | null;
              readonly close?: string | null;
            } | null;
          } | null;
          readonly physicalAddress?: {
            readonly __typename: 'PhysicalAddress';
            readonly address1?: string | null;
            readonly address2?: string | null;
            readonly city?: string | null;
            readonly country?: string | null;
            readonly postalCode?: string | null;
            readonly stateProvince?: string | null;
          } | null;
        };
      }>;
    }>;
  }>;
};

export type PowerBiTokenQueryVariables = Exact<{
  reportKey: ReportKey;
}>;

export type PowerBiTokenQuery = {
  readonly __typename: 'Query';
  readonly powerBiToken?: {
    readonly __typename: 'PowerBiToken';
    readonly embedUrl: string;
    readonly expiry: string;
    readonly reportId: string;
    readonly accessToken: string;
  } | null;
};

export type GetAllChannelsConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllChannelsConfigQuery = {
  readonly __typename: 'Query';
  readonly allChannels: ReadonlyArray<{
    readonly __typename: 'ChannelConfigOption';
    readonly channel: Channel;
    readonly serviceModes: ReadonlyArray<ServiceMode>;
  }>;
};

export type GetOrdersHistoryQueryVariables = Exact<{
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  evaluatePaymentMethodBrand?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOrdersHistoryQuery = {
  readonly __typename: 'Query';
  readonly ordersHistory: ReadonlyArray<{
    readonly __typename: 'OrdersHistory';
    readonly channel: string;
    readonly createdAt: number;
    readonly customerEmail?: string | null;
    readonly driverId?: string | null;
    readonly displayOrderId?: string | null;
    readonly rbiNumberId: string;
    readonly paymentMethod?: string | null;
    readonly paymentTotal?: number | null;
    readonly restaurantId: string;
    readonly restaurantName?: string | null;
    readonly status: string;
    readonly events: ReadonlyArray<{
      readonly __typename: 'OrderEvents';
      readonly name: string;
      readonly time: string;
    }>;
  } | null>;
};

export type GetRiderAppPrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type GetRiderAppPrivacyPolicyQuery = {
  readonly __typename: 'Query';
  readonly riderAppPrivacyPolicy?: {
    readonly __typename: 'RiderAppPrivacyPolicy';
    readonly content: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly current?: boolean | null;
    readonly updatedAt: string;
    readonly updatedBy: string;
    readonly version: string;
  } | null;
};

export type GetMarketAvailablePaymentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMarketAvailablePaymentsQuery = {
  readonly __typename: 'Query';
  readonly getPaymentMethods?: ReadonlyArray<{
    readonly __typename: 'PaymentMethod';
    readonly paymentName: string;
    readonly paymentMethodBrand: string;
    readonly isOnlinePayment: boolean;
  } | null> | null;
};

export const VendorConfigFragmentFragmentDoc = gql`
  fragment VendorConfigFragment on VendorConfig {
    pluType
    parentSanityId
    pullUpLevels
    constantPlu
    quantityBasedPlu {
      quantity
      plu
      qualifier
    }
    multiConstantPlus {
      quantity
      plu
      qualifier
    }
    parentChildPlu {
      plu
      childPlu
    }
    sizeBasedPlu {
      comboPlu
      comboSize
    }
  }
`;
export const VendorConfigsFragmentFragmentDoc = gql`
  fragment VendorConfigsFragment on VendorConfigs {
    carrols {
      ...VendorConfigFragment
    }
    carrolsDelivery {
      ...VendorConfigFragment
    }
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    oheics {
      ...VendorConfigFragment
    }
    oheicsDelivery {
      ...VendorConfigFragment
    }
    partner {
      ...VendorConfigFragment
    }
    partnerDelivery {
      ...VendorConfigFragment
    }
    productNumber {
      ...VendorConfigFragment
    }
    productNumberDelivery {
      ...VendorConfigFragment
    }
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    qdi {
      ...VendorConfigFragment
    }
    qdiDelivery {
      ...VendorConfigFragment
    }
    qst {
      ...VendorConfigFragment
    }
    qstDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
    simplyDelivery {
      ...VendorConfigFragment
    }
    simplyDeliveryDelivery {
      ...VendorConfigFragment
    }
    tablet {
      ...VendorConfigFragment
    }
    tabletDelivery {
      ...VendorConfigFragment
    }
  }
  ${VendorConfigFragmentFragmentDoc}
`;
export const ProductHierarchyFragmentFragmentDoc = gql`
  fragment ProductHierarchyFragment on ProductHierarchy {
    id
    name
    type
    productHierarchy {
      id
      name
      type
    }
  }
`;
export const ItemFragmentFragmentDoc = gql`
  fragment ItemFragment on Item {
    id
    internalName
    name
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    sanityUrl
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
      }
    }
    parentProducts {
      id
      name
      type
    }
    sections
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const ModifierFragmentFragmentDoc = gql`
  fragment ModifierFragment on Modifier {
    id
    name
  }
`;
export const ModifierMultiplierFragmentFragmentDoc = gql`
  fragment ModifierMultiplierFragment on ModifierMultiplier {
    id
    name
    internalName
    prefix
    quantity
    modifier {
      ...ModifierFragment
    }
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    sanityUrl
    productHierarchy {
      ...ProductHierarchyFragment
    }
    sections
  }
  ${ModifierFragmentFragmentDoc}
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const ComboFragmentFragmentDoc = gql`
  fragment ComboFragment on Combo {
    id
    name
    internalName
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    sanityUrl
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
        productHierarchy {
          ...ProductHierarchyFragment
        }
      }
    }
    parentProducts {
      id
      name
      type
    }
    sections
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const OfferFragmentFragmentDoc = gql`
  fragment OfferFragment on Offer {
    id
    name
    internalName
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
      }
    }
    sanityUrl
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const SystemwideOfferFragmentFragmentDoc = gql`
  fragment SystemwideOfferFragment on SystemwideOffer {
    id
    name
    internalName
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
      }
    }
    sanityUrl
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const ConfigOfferFragmentFragmentDoc = gql`
  fragment ConfigOfferFragment on ConfigOffer {
    id
    name
    internalName
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
      }
    }
    sanityUrl
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const RewardFragmentFragmentDoc = gql`
  fragment RewardFragment on Reward {
    id
    name
    internalName
    vendorConfigs {
      ...VendorConfigsFragment
    }
    plusComposite
    sanityUrl
    productHierarchy {
      ...ProductHierarchyFragment
      productHierarchy {
        ...ProductHierarchyFragment
      }
    }
  }
  ${VendorConfigsFragmentFragmentDoc}
  ${ProductHierarchyFragmentFragmentDoc}
`;
export const ProductFragmentFragmentDoc = gql`
  fragment ProductFragment on Product {
    ...ItemFragment
    ...ModifierMultiplierFragment
    ...ComboFragment
    ...OfferFragment
    ...SystemwideOfferFragment
    ...ConfigOfferFragment
    ...RewardFragment
  }
  ${ItemFragmentFragmentDoc}
  ${ModifierMultiplierFragmentFragmentDoc}
  ${ComboFragmentFragmentDoc}
  ${OfferFragmentFragmentDoc}
  ${SystemwideOfferFragmentFragmentDoc}
  ${ConfigOfferFragmentFragmentDoc}
  ${RewardFragmentFragmentDoc}
`;
export const ProductPriceV3FragmentFragmentDoc = gql`
  fragment ProductPriceV3Fragment on ProductPriceV3 {
    id
    restaurantId
    serviceMode
    channel
    plu
    price
    availability
    draft
    overrides {
      id
      price
    }
    priority
    repetition
  }
`;
export const ProductPriceFragmentFragmentDoc = gql`
  fragment ProductPriceFragment on ProductPrice {
    id
    productId
    plu
    price
    availability
  }
`;
export const ErrorFragmentFragmentDoc = gql`
  fragment ErrorFragment on Error {
    code
    message
    productId
    restaurantId
  }
`;
export const ProductMutationResponseFragmentFragmentDoc = gql`
  fragment ProductMutationResponseFragment on ProductMutationResponse {
    data {
      ...ProductPriceFragment
    }
    errors {
      ...ErrorFragment
    }
  }
  ${ProductPriceFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`;
export const OverridesResponseFragmentFragmentDoc = gql`
  fragment OverridesResponseFragment on OverridesResponse {
    id
    price
  }
`;
export const PlusResponseFragmentFragmentDoc = gql`
  fragment PlusResponseFragment on PlusResponse {
    availability
    draft
    overrides {
      ...OverridesResponseFragment
    }
    plu
    price
    priority
    repetition
    sanityId
  }
  ${OverridesResponseFragmentFragmentDoc}
`;
export const ValidateProductsResponseFragmentNewSchemaFragmentDoc = gql`
  fragment ValidateProductsResponseFragmentNewSchema on ValidateProductsResponseNewSchema {
    channel
    region
    serviceMode
    data {
      plus {
        ...PlusResponseFragment
      }
      storeIds
    }
  }
  ${PlusResponseFragmentFragmentDoc}
`;
export const ValidateProductsResponseFragmentOldSchemaFragmentDoc = gql`
  fragment ValidateProductsResponseFragmentOldSchema on ValidateProductsResponseOldSchema {
    channel
    plus {
      ...PlusResponseFragment
    }
    region
    serviceMode
    storeIds
  }
  ${PlusResponseFragmentFragmentDoc}
`;
export const ValidateProductsErrorFragmentFragmentDoc = gql`
  fragment ValidateProductsErrorFragment on ValidateProductsError {
    code
    message
    plu
    restaurantIds
  }
`;
export const ValidateProductsMutationResponseFragmentFragmentDoc = gql`
  fragment ValidateProductsMutationResponseFragment on ValidateProductsMutationResponse {
    data {
      ...ValidateProductsResponseFragmentNewSchema
      ...ValidateProductsResponseFragmentOldSchema
    }
    errors {
      ...ValidateProductsErrorFragment
    }
  }
  ${ValidateProductsResponseFragmentNewSchemaFragmentDoc}
  ${ValidateProductsResponseFragmentOldSchemaFragmentDoc}
  ${ValidateProductsErrorFragmentFragmentDoc}
`;
export const LightRestaurantFragmentFragmentDoc = gql`
  fragment LightRestaurantFragment on Restaurant {
    id
    groups
    pos {
      vendor
    }
    name
  }
`;
export const AdditionalTimeSlotFragmentDoc = gql`
  fragment AdditionalTimeSlot on AdditionalTimeSlot {
    open
    close
  }
`;
export const HoursFragmentFragmentDoc = gql`
  fragment HoursFragment on HoursOfOperation {
    friClose
    friOpen
    friAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    monClose
    monOpen
    monAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    satClose
    satOpen
    satAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    sunClose
    sunOpen
    sunAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    thrClose
    thrOpen
    thrAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    tueClose
    tueOpen
    tueAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
    wedClose
    wedOpen
    wedAdditionalTimeSlot {
      ...AdditionalTimeSlot
    }
  }
  ${AdditionalTimeSlotFragmentDoc}
`;
export const RestaurantFragmentFragmentDoc = gql`
  fragment RestaurantFragment on Restaurant {
    id
    deliveryHours {
      ...HoursFragment
    }
    diningRoomHours {
      ...HoursFragment
    }
    curbsideHours {
      ...HoursFragment
    }
    driveThruHours {
      ...HoursFragment
    }
    hasCurbside
    hasDelivery
    hasDineIn
    hasDriveThru
    hasLateNightPricing
    hasMobileOrdering
    latitude
    longitude
    environment
    mobileOrderingStatus
    name
    physicalAddress {
      address1
      address2
      city
      country
      postalCode
      stateProvince
    }
    sanityDocumentId
    vendor
    pos {
      vendor
    }
    groups
    availability
  }
  ${HoursFragmentFragmentDoc}
`;
export const RestaurantOrderFragmentFragmentDoc = gql`
  fragment RestaurantOrderFragment on RestaurantOrder {
    orderId
    storeId
    totalCents
    createdAt
    items
  }
`;
export const HistoryFragmentFragmentDoc = gql`
  fragment HistoryFragment on History {
    date
    status
    storeId
    userOktaId
    userEmail
    serviceMode
    channel
    updates {
      product
      price
      availability
    }
  }
`;
export const AmountFragmentFragmentDoc = gql`
  fragment AmountFragment on Amount {
    amount
    currency
  }
`;
export const MenuSelectionFieldsFragmentDoc = gql`
  fragment MenuSelectionFields on MenuSelections {
    name
    externalReferenceId
    price {
      ...AmountFragment
    }
    quantity
    dmpId
  }
  ${AmountFragmentFragmentDoc}
`;
export const MenuSelectionsFragmentFragmentDoc = gql`
  fragment MenuSelectionsFragment on OrderCartMenuSelections {
    name
    externalReferenceId
    price {
      ...AmountFragment
    }
    quantity
    dmpId
    menuSelections {
      ...MenuSelectionFields
    }
  }
  ${AmountFragmentFragmentDoc}
  ${MenuSelectionFieldsFragmentDoc}
`;
export const DmpOrderByIdFragmentFragmentDoc = gql`
  fragment DmpOrderByIdFragment on DmpOrderById {
    brand
    channel
    cart {
      menuSelections {
        ...MenuSelectionsFragment
      }
    }
    compensation {
      menuSelections {
        ...MenuSelectionsFragment
      }
    }
    createdAt
    customer {
      name
      id
      phone
      email
      username
    }
    deliveryAddress {
      administrativeArea
      formattedAddress
      instructions
      locality
      phoneNumber
      postalCode
      regionCode
      route
      streetNumber
      sublocality
      subpremise
    }
    deliveryId
    displayOrderId
    driverId
    dropoffWindow {
      end
      start
    }
    events {
      name
      time
      issue
    }
    eventsUrl
    payment {
      amountLeftToBePaid {
        ...AmountFragment
      }
      paymentMethod
      paymentMethodBrand
      tip {
        ...AmountFragment
      }
      total {
        ...AmountFragment
      }
    }
    pickupWindow {
      end
      start
    }
    quadrant {
      name
    }
    quoteId
    rbiNumberId
    regionCode
    restaurantId
    status
    updatedAt
  }
  ${MenuSelectionsFragmentFragmentDoc}
  ${AmountFragmentFragmentDoc}
`;
export const ComplaintsRowFragmentDoc = gql`
  fragment ComplaintsRow on Complaint {
    id
    orderId
    dmpCategory
    status
    created
    comments
    resolution
    resolutionComments
    payment {
      amountLeftToBePaid {
        ...AmountFragment
      }
      paymentMethod
      paymentMethodBrand
      tip {
        ...AmountFragment
      }
      total {
        ...AmountFragment
      }
    }
    order {
      ...DmpOrderByIdFragment
    }
    customer {
      name
      email
      phoneNumber
    }
    compensation {
      menuSelections {
        ...MenuSelectionsFragment
      }
    }
  }
  ${AmountFragmentFragmentDoc}
  ${DmpOrderByIdFragmentFragmentDoc}
  ${MenuSelectionsFragmentFragmentDoc}
`;
export const RestaurantDeliveryFragmentFragmentDoc = gql`
  fragment RestaurantDeliveryFragment on RestaurantDelivery {
    id
    deliveryHours {
      ...HoursFragment
    }
    hasDelivery
    latitude
    longitude
    internalName
    name
    franchiseGroupId
    franchiseGroupName
    availability
    physicalAddress {
      address1
      address2
      city
      country
      postalCode
      stateProvince
    }
  }
  ${HoursFragmentFragmentDoc}
`;
export const ChildRestaurantLinkFragmentFragmentDoc = gql`
  fragment ChildRestaurantLinkFragment on ChildRestaurantLink {
    id
    allowModification
    restaurant {
      ...RestaurantDeliveryFragment
      phoneNumber
    }
  }
  ${RestaurantDeliveryFragmentFragmentDoc}
`;
export const MasterRestaurantLinksFragmentFragmentDoc = gql`
  fragment MasterRestaurantLinksFragment on MasterRestaurantLinks {
    id
    allowModification
    restaurant {
      ...RestaurantDeliveryFragment
      phoneNumber
    }
    children {
      ...ChildRestaurantLinkFragment
    }
    count
  }
  ${RestaurantDeliveryFragmentFragmentDoc}
  ${ChildRestaurantLinkFragmentFragmentDoc}
`;
export const DmpMasterRelationsFragmentFragmentDoc = gql`
  fragment DmpMasterRelationsFragment on DmpMasterRelations {
    dayPeriod
    masterRestaurants {
      ...MasterRestaurantLinksFragment
    }
  }
  ${MasterRestaurantLinksFragmentFragmentDoc}
`;
export const CopyStorePlusDocument = gql`
  mutation CopyStorePlus($fromStoreId: String!, $toStoreIds: [String!]!, $toGroupIds: [String]!) {
    copyStorePlus(fromStoreId: $fromStoreId, toStoreIds: $toStoreIds, toGroupIds: $toGroupIds)
  }
`;
export type CopyStorePlusMutationFn = Apollo.MutationFunction<
  CopyStorePlusMutation,
  CopyStorePlusMutationVariables
>;

/**
 * __useCopyStorePlusMutation__
 *
 * To run a mutation, you first call `useCopyStorePlusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyStorePlusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyStorePlusMutation, { data, loading, error }] = useCopyStorePlusMutation({
 *   variables: {
 *      fromStoreId: // value for 'fromStoreId'
 *      toStoreIds: // value for 'toStoreIds'
 *      toGroupIds: // value for 'toGroupIds'
 *   },
 * });
 */
export function useCopyStorePlusMutation(
  baseOptions?: Apollo.MutationHookOptions<CopyStorePlusMutation, CopyStorePlusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CopyStorePlusMutation, CopyStorePlusMutationVariables>(
    CopyStorePlusDocument,
    options
  );
}
export type CopyStorePlusMutationHookResult = ReturnType<typeof useCopyStorePlusMutation>;
export type CopyStorePlusMutationResult = Apollo.MutationResult<CopyStorePlusMutation>;
export type CopyStorePlusMutationOptions = Apollo.BaseMutationOptions<
  CopyStorePlusMutation,
  CopyStorePlusMutationVariables
>;
export const UpdateMenuFromCsvDocument = gql`
  mutation UpdateMenuFromCSV(
    $storeId: String!
    $channel: Channel!
    $items: [UpdateMenuFromCSVInput!]!
  ) {
    updateMenuFromCSV(storeId: $storeId, channel: $channel, items: $items)
  }
`;
export type UpdateMenuFromCsvMutationFn = Apollo.MutationFunction<
  UpdateMenuFromCsvMutation,
  UpdateMenuFromCsvMutationVariables
>;

/**
 * __useUpdateMenuFromCsvMutation__
 *
 * To run a mutation, you first call `useUpdateMenuFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenuFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenuFromCsvMutation, { data, loading, error }] = useUpdateMenuFromCsvMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      channel: // value for 'channel'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateMenuFromCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMenuFromCsvMutation,
    UpdateMenuFromCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMenuFromCsvMutation, UpdateMenuFromCsvMutationVariables>(
    UpdateMenuFromCsvDocument,
    options
  );
}
export type UpdateMenuFromCsvMutationHookResult = ReturnType<typeof useUpdateMenuFromCsvMutation>;
export type UpdateMenuFromCsvMutationResult = Apollo.MutationResult<UpdateMenuFromCsvMutation>;
export type UpdateMenuFromCsvMutationOptions = Apollo.BaseMutationOptions<
  UpdateMenuFromCsvMutation,
  UpdateMenuFromCsvMutationVariables
>;
export const OldUpdateMenuFromCsvDocument = gql`
  mutation OldUpdateMenuFromCSV($storeId: String!, $items: [OldUpdateMenuFromCSVInput!]!) {
    oldUpdateMenuFromCSV(storeId: $storeId, items: $items)
  }
`;
export type OldUpdateMenuFromCsvMutationFn = Apollo.MutationFunction<
  OldUpdateMenuFromCsvMutation,
  OldUpdateMenuFromCsvMutationVariables
>;

/**
 * __useOldUpdateMenuFromCsvMutation__
 *
 * To run a mutation, you first call `useOldUpdateMenuFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOldUpdateMenuFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oldUpdateMenuFromCsvMutation, { data, loading, error }] = useOldUpdateMenuFromCsvMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useOldUpdateMenuFromCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OldUpdateMenuFromCsvMutation,
    OldUpdateMenuFromCsvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OldUpdateMenuFromCsvMutation, OldUpdateMenuFromCsvMutationVariables>(
    OldUpdateMenuFromCsvDocument,
    options
  );
}
export type OldUpdateMenuFromCsvMutationHookResult = ReturnType<
  typeof useOldUpdateMenuFromCsvMutation
>;
export type OldUpdateMenuFromCsvMutationResult =
  Apollo.MutationResult<OldUpdateMenuFromCsvMutation>;
export type OldUpdateMenuFromCsvMutationOptions = Apollo.BaseMutationOptions<
  OldUpdateMenuFromCsvMutation,
  OldUpdateMenuFromCsvMutationVariables
>;
export const UpdateProductPricesDocument = gql`
  mutation UpdateProductPrices(
    $products: [ProductPriceToUpdate!]!
    $serviceMode: ServiceMode
    $v3: Boolean = false
  ) {
    updateProductPrices(products: $products, serviceMode: $serviceMode, v3: $v3) {
      ...ProductMutationResponseFragment
    }
  }
  ${ProductMutationResponseFragmentFragmentDoc}
`;
export type UpdateProductPricesMutationFn = Apollo.MutationFunction<
  UpdateProductPricesMutation,
  UpdateProductPricesMutationVariables
>;

/**
 * __useUpdateProductPricesMutation__
 *
 * To run a mutation, you first call `useUpdateProductPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPricesMutation, { data, loading, error }] = useUpdateProductPricesMutation({
 *   variables: {
 *      products: // value for 'products'
 *      serviceMode: // value for 'serviceMode'
 *      v3: // value for 'v3'
 *   },
 * });
 */
export function useUpdateProductPricesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductPricesMutation,
    UpdateProductPricesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>(
    UpdateProductPricesDocument,
    options
  );
}
export type UpdateProductPricesMutationHookResult = ReturnType<
  typeof useUpdateProductPricesMutation
>;
export type UpdateProductPricesMutationResult = Apollo.MutationResult<UpdateProductPricesMutation>;
export type UpdateProductPricesMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductPricesMutation,
  UpdateProductPricesMutationVariables
>;
export const UpdateProductAvailabilityDocument = gql`
  mutation UpdateProductAvailability(
    $products: [ProductAvailabilityToUpdate!]!
    $serviceMode: ServiceMode
    $v3: Boolean = false
  ) {
    updateProductAvailability(products: $products, serviceMode: $serviceMode, v3: $v3) {
      ...ProductMutationResponseFragment
    }
  }
  ${ProductMutationResponseFragmentFragmentDoc}
`;
export type UpdateProductAvailabilityMutationFn = Apollo.MutationFunction<
  UpdateProductAvailabilityMutation,
  UpdateProductAvailabilityMutationVariables
>;

/**
 * __useUpdateProductAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateProductAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductAvailabilityMutation, { data, loading, error }] = useUpdateProductAvailabilityMutation({
 *   variables: {
 *      products: // value for 'products'
 *      serviceMode: // value for 'serviceMode'
 *      v3: // value for 'v3'
 *   },
 * });
 */
export function useUpdateProductAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProductAvailabilityMutation,
    UpdateProductAvailabilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProductAvailabilityMutation,
    UpdateProductAvailabilityMutationVariables
  >(UpdateProductAvailabilityDocument, options);
}
export type UpdateProductAvailabilityMutationHookResult = ReturnType<
  typeof useUpdateProductAvailabilityMutation
>;
export type UpdateProductAvailabilityMutationResult =
  Apollo.MutationResult<UpdateProductAvailabilityMutation>;
export type UpdateProductAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductAvailabilityMutation,
  UpdateProductAvailabilityMutationVariables
>;
export const UpdateProductsDocument = gql`
  mutation UpdateProducts($updates: UpdatesInput!, $v3: Boolean = false) {
    updateProducts(updates: $updates, v3: $v3) {
      ...ProductMutationResponseFragment
    }
  }
  ${ProductMutationResponseFragmentFragmentDoc}
`;
export type UpdateProductsMutationFn = Apollo.MutationFunction<
  UpdateProductsMutation,
  UpdateProductsMutationVariables
>;

/**
 * __useUpdateProductsMutation__
 *
 * To run a mutation, you first call `useUpdateProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductsMutation, { data, loading, error }] = useUpdateProductsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *      v3: // value for 'v3'
 *   },
 * });
 */
export function useUpdateProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductsMutation, UpdateProductsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductsMutation, UpdateProductsMutationVariables>(
    UpdateProductsDocument,
    options
  );
}
export type UpdateProductsMutationHookResult = ReturnType<typeof useUpdateProductsMutation>;
export type UpdateProductsMutationResult = Apollo.MutationResult<UpdateProductsMutation>;
export type UpdateProductsMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductsMutation,
  UpdateProductsMutationVariables
>;
export const ValidateProductsDocument = gql`
  mutation ValidateProducts($updates: UpdatesInput!, $v3: Boolean = false) {
    validateProducts(updates: $updates, v3: $v3) {
      ...ValidateProductsMutationResponseFragment
    }
  }
  ${ValidateProductsMutationResponseFragmentFragmentDoc}
`;
export type ValidateProductsMutationFn = Apollo.MutationFunction<
  ValidateProductsMutation,
  ValidateProductsMutationVariables
>;

/**
 * __useValidateProductsMutation__
 *
 * To run a mutation, you first call `useValidateProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateProductsMutation, { data, loading, error }] = useValidateProductsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *      v3: // value for 'v3'
 *   },
 * });
 */
export function useValidateProductsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateProductsMutation,
    ValidateProductsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateProductsMutation, ValidateProductsMutationVariables>(
    ValidateProductsDocument,
    options
  );
}
export type ValidateProductsMutationHookResult = ReturnType<typeof useValidateProductsMutation>;
export type ValidateProductsMutationResult = Apollo.MutationResult<ValidateProductsMutation>;
export type ValidateProductsMutationOptions = Apollo.BaseMutationOptions<
  ValidateProductsMutation,
  ValidateProductsMutationVariables
>;
export const UpdateCashLimitationDocument = gql`
  mutation UpdateCashLimitation(
    $documentIds: [String!]!
    $updatePayload: DeliveryOrderAmountLimitInput!
  ) {
    updateRestaurantCashLimitation(
      documentIds: $documentIds
      cashLimitationUpdate: $updatePayload
    ) {
      id
      deliveryOrderAmountLimit {
        deliveryOrderAmountLimit
        deliveryOrderAmountLimitEnabled
        deliveryOrderRepeatedFailureLimitation
        firstDeliveryOrder
        firstDeliveryOrderEnabled
      }
    }
  }
`;
export type UpdateCashLimitationMutationFn = Apollo.MutationFunction<
  UpdateCashLimitationMutation,
  UpdateCashLimitationMutationVariables
>;

/**
 * __useUpdateCashLimitationMutation__
 *
 * To run a mutation, you first call `useUpdateCashLimitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCashLimitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCashLimitationMutation, { data, loading, error }] = useUpdateCashLimitationMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      updatePayload: // value for 'updatePayload'
 *   },
 * });
 */
export function useUpdateCashLimitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCashLimitationMutation,
    UpdateCashLimitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCashLimitationMutation, UpdateCashLimitationMutationVariables>(
    UpdateCashLimitationDocument,
    options
  );
}
export type UpdateCashLimitationMutationHookResult = ReturnType<
  typeof useUpdateCashLimitationMutation
>;
export type UpdateCashLimitationMutationResult =
  Apollo.MutationResult<UpdateCashLimitationMutation>;
export type UpdateCashLimitationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCashLimitationMutation,
  UpdateCashLimitationMutationVariables
>;
export const UpdateAllowPaymentsDocument = gql`
  mutation UpdateAllowPayments(
    $documentIds: [String!]!
    $updatePayload: [PaymentMethodInput!]!
    $marketPayments: [MarketPaymentsInput!]!
  ) {
    updateRestaurantPayments(
      documentIds: $documentIds
      paymentsUpdate: $updatePayload
      marketPayments: $marketPayments
    ) {
      id
      paymentMethods {
        paymentMethodBrand
        state
      }
    }
  }
`;
export type UpdateAllowPaymentsMutationFn = Apollo.MutationFunction<
  UpdateAllowPaymentsMutation,
  UpdateAllowPaymentsMutationVariables
>;

/**
 * __useUpdateAllowPaymentsMutation__
 *
 * To run a mutation, you first call `useUpdateAllowPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllowPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllowPaymentsMutation, { data, loading, error }] = useUpdateAllowPaymentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      updatePayload: // value for 'updatePayload'
 *      marketPayments: // value for 'marketPayments'
 *   },
 * });
 */
export function useUpdateAllowPaymentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAllowPaymentsMutation,
    UpdateAllowPaymentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAllowPaymentsMutation, UpdateAllowPaymentsMutationVariables>(
    UpdateAllowPaymentsDocument,
    options
  );
}
export type UpdateAllowPaymentsMutationHookResult = ReturnType<
  typeof useUpdateAllowPaymentsMutation
>;
export type UpdateAllowPaymentsMutationResult = Apollo.MutationResult<UpdateAllowPaymentsMutation>;
export type UpdateAllowPaymentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAllowPaymentsMutation,
  UpdateAllowPaymentsMutationVariables
>;
export const UpsertUserDocument = gql`
  mutation UpsertUser(
    $id: String!
    $restaurantIds: [String!]
    $roleId: String!
    $userName: String!
    $email: String!
    $isNewUser: Boolean
  ) {
    upsertUser(
      oktaId: $id
      restaurantIds: $restaurantIds
      roleId: $roleId
      userName: $userName
      email: $email
      isNewUser: $isNewUser
    ) {
      OktaId
      Country
      RestaurantIds
      RoleId
      Status
      UserName
      Email
    }
  }
`;
export type UpsertUserMutationFn = Apollo.MutationFunction<
  UpsertUserMutation,
  UpsertUserMutationVariables
>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      restaurantIds: // value for 'restaurantIds'
 *      roleId: // value for 'roleId'
 *      userName: // value for 'userName'
 *      email: // value for 'email'
 *      isNewUser: // value for 'isNewUser'
 *   },
 * });
 */
export function useUpsertUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertUserMutation, UpsertUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(
    UpsertUserDocument,
    options
  );
}
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = Apollo.MutationResult<UpsertUserMutation>;
export type UpsertUserMutationOptions = Apollo.BaseMutationOptions<
  UpsertUserMutation,
  UpsertUserMutationVariables
>;
export const ChangeUserStatusDocument = gql`
  mutation ChangeUserStatus(
    $oktaId: String!
    $restaurantIds: [String!]!
    $role: String!
    $isActive: Boolean!
    $userName: String!
    $email: String!
  ) {
    changeUserStatus(
      oktaId: $oktaId
      restaurantIds: $restaurantIds
      role: $role
      isActive: $isActive
      userName: $userName
      email: $email
    ) {
      OktaId
      Country
      RoleId
      RestaurantIds
      Status
      UserName
      Email
    }
  }
`;
export type ChangeUserStatusMutationFn = Apollo.MutationFunction<
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables
>;

/**
 * __useChangeUserStatusMutation__
 *
 * To run a mutation, you first call `useChangeUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserStatusMutation, { data, loading, error }] = useChangeUserStatusMutation({
 *   variables: {
 *      oktaId: // value for 'oktaId'
 *      restaurantIds: // value for 'restaurantIds'
 *      role: // value for 'role'
 *      isActive: // value for 'isActive'
 *      userName: // value for 'userName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserStatusMutation,
    ChangeUserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserStatusMutation, ChangeUserStatusMutationVariables>(
    ChangeUserStatusDocument,
    options
  );
}
export type ChangeUserStatusMutationHookResult = ReturnType<typeof useChangeUserStatusMutation>;
export type ChangeUserStatusMutationResult = Apollo.MutationResult<ChangeUserStatusMutation>;
export type ChangeUserStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables
>;
export const UpsertRoleDocument = gql`
  mutation UpsertRole($roleName: String!, $permissions: [Roles!]!, $roleId: String) {
    upsertRole(roleName: $roleName, permissions: $permissions, roleId: $roleId) {
      RoleId
      RoleName
      Permissions
    }
  }
`;
export type UpsertRoleMutationFn = Apollo.MutationFunction<
  UpsertRoleMutation,
  UpsertRoleMutationVariables
>;

/**
 * __useUpsertRoleMutation__
 *
 * To run a mutation, you first call `useUpsertRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRoleMutation, { data, loading, error }] = useUpsertRoleMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *      permissions: // value for 'permissions'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useUpsertRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertRoleMutation, UpsertRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertRoleMutation, UpsertRoleMutationVariables>(
    UpsertRoleDocument,
    options
  );
}
export type UpsertRoleMutationHookResult = ReturnType<typeof useUpsertRoleMutation>;
export type UpsertRoleMutationResult = Apollo.MutationResult<UpsertRoleMutation>;
export type UpsertRoleMutationOptions = Apollo.BaseMutationOptions<
  UpsertRoleMutation,
  UpsertRoleMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($roleName: String!) {
    deleteRole(roleName: $roleName)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    options
  );
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const CreateDriverDocument = gql`
  mutation CreateDriver($restaurantId: String!, $body: createDriverPayload!) {
    createDriver(restaurantId: $restaurantId, body: $body)
  }
`;
export type CreateDriverMutationFn = Apollo.MutationFunction<
  CreateDriverMutation,
  CreateDriverMutationVariables
>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(
    CreateDriverDocument,
    options
  );
}
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverMutation,
  CreateDriverMutationVariables
>;
export const UpdateDriverDocument = gql`
  mutation UpdateDriver(
    $restaurantId: String!
    $driverId: String!
    $body: UpdateDriverPayload
    $password: String
    $confirmPassword: String
  ) {
    updateDriver(
      restaurantId: $restaurantId
      driverId: $driverId
      body: $body
      password: $password
      confirmPassword: $confirmPassword
    )
  }
`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      driverId: // value for 'driverId'
 *      body: // value for 'body'
 *      password: // value for 'password'
 *      confirmPassword: // value for 'confirmPassword'
 *   },
 * });
 */
export function useUpdateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(
    UpdateDriverDocument,
    options
  );
}
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;
export const DeleteDriverDocument = gql`
  mutation DeleteDriver($restaurantId: String!, $driverId: String!) {
    deleteDriver(restaurantId: $restaurantId, driverId: $driverId)
  }
`;
export type DeleteDriverMutationFn = Apollo.MutationFunction<
  DeleteDriverMutation,
  DeleteDriverMutationVariables
>;

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useDeleteDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDriverMutation, DeleteDriverMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDriverMutation, DeleteDriverMutationVariables>(
    DeleteDriverDocument,
    options
  );
}
export type DeleteDriverMutationHookResult = ReturnType<typeof useDeleteDriverMutation>;
export type DeleteDriverMutationResult = Apollo.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverMutation,
  DeleteDriverMutationVariables
>;
export const CreatePolygonDocument = gql`
  mutation CreatePolygon($restaurantId: String!, $body: createPolygonPayload!) {
    createPolygon(restaurantId: $restaurantId, body: $body) {
      id
      polygons {
        createdAt
        createdBy
        default
        pendingApproval
        restaurantId
        gridArea {
          id
          polygon {
            color
            coordinates
          }
        }
        id
        name
        mainArea {
          color
          coordinates
        }
        reducedArea {
          color
          coordinates
        }
        updatedAt
        updatedBy
      }
    }
  }
`;
export type CreatePolygonMutationFn = Apollo.MutationFunction<
  CreatePolygonMutation,
  CreatePolygonMutationVariables
>;

/**
 * __useCreatePolygonMutation__
 *
 * To run a mutation, you first call `useCreatePolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolygonMutation, { data, loading, error }] = useCreatePolygonMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreatePolygonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePolygonMutation, CreatePolygonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolygonMutation, CreatePolygonMutationVariables>(
    CreatePolygonDocument,
    options
  );
}
export type CreatePolygonMutationHookResult = ReturnType<typeof useCreatePolygonMutation>;
export type CreatePolygonMutationResult = Apollo.MutationResult<CreatePolygonMutation>;
export type CreatePolygonMutationOptions = Apollo.BaseMutationOptions<
  CreatePolygonMutation,
  CreatePolygonMutationVariables
>;
export const UpdatePolygonDocument = gql`
  mutation UpdatePolygon(
    $restaurantId: String!
    $polygonId: String!
    $body: updatePolygonPayload!
  ) {
    updatePolygon(restaurantId: $restaurantId, polygonId: $polygonId, body: $body) {
      createdAt
      createdBy
      default
      pendingApproval
      restaurantId
      gridArea {
        id
        polygon {
          color
          coordinates
        }
      }
      id
      name
      mainArea {
        color
        coordinates
      }
      reducedArea {
        color
        coordinates
      }
      updatedAt
      updatedBy
    }
  }
`;
export type UpdatePolygonMutationFn = Apollo.MutationFunction<
  UpdatePolygonMutation,
  UpdatePolygonMutationVariables
>;

/**
 * __useUpdatePolygonMutation__
 *
 * To run a mutation, you first call `useUpdatePolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolygonMutation, { data, loading, error }] = useUpdatePolygonMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePolygonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolygonMutation, UpdatePolygonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolygonMutation, UpdatePolygonMutationVariables>(
    UpdatePolygonDocument,
    options
  );
}
export type UpdatePolygonMutationHookResult = ReturnType<typeof useUpdatePolygonMutation>;
export type UpdatePolygonMutationResult = Apollo.MutationResult<UpdatePolygonMutation>;
export type UpdatePolygonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolygonMutation,
  UpdatePolygonMutationVariables
>;
export const CancelPolygonRequestForApprovalDocument = gql`
  mutation CancelPolygonRequestForApproval($restaurantId: String!, $polygonId: String!) {
    cancelPolygonRequestForApproval(restaurantId: $restaurantId, polygonId: $polygonId) {
      id
      polygons {
        createdAt
        createdBy
        default
        pendingApproval
        restaurantId
        id
        name
        updatedAt
        updatedBy
      }
    }
  }
`;
export type CancelPolygonRequestForApprovalMutationFn = Apollo.MutationFunction<
  CancelPolygonRequestForApprovalMutation,
  CancelPolygonRequestForApprovalMutationVariables
>;

/**
 * __useCancelPolygonRequestForApprovalMutation__
 *
 * To run a mutation, you first call `useCancelPolygonRequestForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPolygonRequestForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPolygonRequestForApprovalMutation, { data, loading, error }] = useCancelPolygonRequestForApprovalMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *   },
 * });
 */
export function useCancelPolygonRequestForApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelPolygonRequestForApprovalMutation,
    CancelPolygonRequestForApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelPolygonRequestForApprovalMutation,
    CancelPolygonRequestForApprovalMutationVariables
  >(CancelPolygonRequestForApprovalDocument, options);
}
export type CancelPolygonRequestForApprovalMutationHookResult = ReturnType<
  typeof useCancelPolygonRequestForApprovalMutation
>;
export type CancelPolygonRequestForApprovalMutationResult =
  Apollo.MutationResult<CancelPolygonRequestForApprovalMutation>;
export type CancelPolygonRequestForApprovalMutationOptions = Apollo.BaseMutationOptions<
  CancelPolygonRequestForApprovalMutation,
  CancelPolygonRequestForApprovalMutationVariables
>;
export const DeletePolygonDocument = gql`
  mutation DeletePolygon($restaurantId: String!, $polygonId: String!) {
    deletePolygon(restaurantId: $restaurantId, polygonId: $polygonId) {
      id
      polygons {
        id
      }
    }
  }
`;
export type DeletePolygonMutationFn = Apollo.MutationFunction<
  DeletePolygonMutation,
  DeletePolygonMutationVariables
>;

/**
 * __useDeletePolygonMutation__
 *
 * To run a mutation, you first call `useDeletePolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolygonMutation, { data, loading, error }] = useDeletePolygonMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *   },
 * });
 */
export function useDeletePolygonMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePolygonMutation, DeletePolygonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolygonMutation, DeletePolygonMutationVariables>(
    DeletePolygonDocument,
    options
  );
}
export type DeletePolygonMutationHookResult = ReturnType<typeof useDeletePolygonMutation>;
export type DeletePolygonMutationResult = Apollo.MutationResult<DeletePolygonMutation>;
export type DeletePolygonMutationOptions = Apollo.BaseMutationOptions<
  DeletePolygonMutation,
  DeletePolygonMutationVariables
>;
export const SendPolygonForApprovalDocument = gql`
  mutation SendPolygonForApproval($restaurantId: String!, $polygonId: String!) {
    sendPolygonForApproval(restaurantId: $restaurantId, polygonId: $polygonId) {
      id
      polygons {
        default
        pendingApproval
        id
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        restaurantId
      }
    }
  }
`;
export type SendPolygonForApprovalMutationFn = Apollo.MutationFunction<
  SendPolygonForApprovalMutation,
  SendPolygonForApprovalMutationVariables
>;

/**
 * __useSendPolygonForApprovalMutation__
 *
 * To run a mutation, you first call `useSendPolygonForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPolygonForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPolygonForApprovalMutation, { data, loading, error }] = useSendPolygonForApprovalMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *   },
 * });
 */
export function useSendPolygonForApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPolygonForApprovalMutation,
    SendPolygonForApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPolygonForApprovalMutation,
    SendPolygonForApprovalMutationVariables
  >(SendPolygonForApprovalDocument, options);
}
export type SendPolygonForApprovalMutationHookResult = ReturnType<
  typeof useSendPolygonForApprovalMutation
>;
export type SendPolygonForApprovalMutationResult =
  Apollo.MutationResult<SendPolygonForApprovalMutation>;
export type SendPolygonForApprovalMutationOptions = Apollo.BaseMutationOptions<
  SendPolygonForApprovalMutation,
  SendPolygonForApprovalMutationVariables
>;
export const ApprovePolygonDocument = gql`
  mutation ApprovePolygon($restaurantId: String!, $polygonId: String!) {
    approvePolygon(restaurantId: $restaurantId, polygonId: $polygonId) {
      id
      polygons {
        default
        pendingApproval
        gridArea {
          id
          polygon {
            color
            coordinates
          }
        }
        id
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        restaurantId
      }
    }
  }
`;
export type ApprovePolygonMutationFn = Apollo.MutationFunction<
  ApprovePolygonMutation,
  ApprovePolygonMutationVariables
>;

/**
 * __useApprovePolygonMutation__
 *
 * To run a mutation, you first call `useApprovePolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePolygonMutation, { data, loading, error }] = useApprovePolygonMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *   },
 * });
 */
export function useApprovePolygonMutation(
  baseOptions?: Apollo.MutationHookOptions<ApprovePolygonMutation, ApprovePolygonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApprovePolygonMutation, ApprovePolygonMutationVariables>(
    ApprovePolygonDocument,
    options
  );
}
export type ApprovePolygonMutationHookResult = ReturnType<typeof useApprovePolygonMutation>;
export type ApprovePolygonMutationResult = Apollo.MutationResult<ApprovePolygonMutation>;
export type ApprovePolygonMutationOptions = Apollo.BaseMutationOptions<
  ApprovePolygonMutation,
  ApprovePolygonMutationVariables
>;
export const RejectPolygonDocument = gql`
  mutation RejectPolygon($restaurantId: String!, $polygonId: String!) {
    rejectPolygon(restaurantId: $restaurantId, polygonId: $polygonId) {
      id
      polygons {
        default
        pendingApproval
        id
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        restaurantId
      }
    }
  }
`;
export type RejectPolygonMutationFn = Apollo.MutationFunction<
  RejectPolygonMutation,
  RejectPolygonMutationVariables
>;

/**
 * __useRejectPolygonMutation__
 *
 * To run a mutation, you first call `useRejectPolygonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPolygonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPolygonMutation, { data, loading, error }] = useRejectPolygonMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      polygonId: // value for 'polygonId'
 *   },
 * });
 */
export function useRejectPolygonMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectPolygonMutation, RejectPolygonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectPolygonMutation, RejectPolygonMutationVariables>(
    RejectPolygonDocument,
    options
  );
}
export type RejectPolygonMutationHookResult = ReturnType<typeof useRejectPolygonMutation>;
export type RejectPolygonMutationResult = Apollo.MutationResult<RejectPolygonMutation>;
export type RejectPolygonMutationOptions = Apollo.BaseMutationOptions<
  RejectPolygonMutation,
  RejectPolygonMutationVariables
>;
export const UpdateRestaurantSettingsDocument = gql`
  mutation UpdateRestaurantSettings($restaurantId: String!, $body: Settings!) {
    updateRestaurantSettings(restaurantId: $restaurantId, body: $body) {
      id
    }
  }
`;
export type UpdateRestaurantSettingsMutationFn = Apollo.MutationFunction<
  UpdateRestaurantSettingsMutation,
  UpdateRestaurantSettingsMutationVariables
>;

/**
 * __useUpdateRestaurantSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantSettingsMutation, { data, loading, error }] = useUpdateRestaurantSettingsMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateRestaurantSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRestaurantSettingsMutation,
    UpdateRestaurantSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRestaurantSettingsMutation,
    UpdateRestaurantSettingsMutationVariables
  >(UpdateRestaurantSettingsDocument, options);
}
export type UpdateRestaurantSettingsMutationHookResult = ReturnType<
  typeof useUpdateRestaurantSettingsMutation
>;
export type UpdateRestaurantSettingsMutationResult =
  Apollo.MutationResult<UpdateRestaurantSettingsMutation>;
export type UpdateRestaurantSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateRestaurantSettingsMutation,
  UpdateRestaurantSettingsMutationVariables
>;
export const UpdateOrderDriverDocument = gql`
  mutation UpdateOrderDriver($orderId: String!, $name: String!, $phoneNumber: String!) {
    changeOrderDriver(orderId: $orderId, name: $name, phoneNumber: $phoneNumber) {
      storeId
      createdAt
      totalCents
      orderNumberFormatted
      id
      delivery {
        driver {
          driverId
          name
          phoneNumber
        }
        status
      }
    }
  }
`;
export type UpdateOrderDriverMutationFn = Apollo.MutationFunction<
  UpdateOrderDriverMutation,
  UpdateOrderDriverMutationVariables
>;

/**
 * __useUpdateOrderDriverMutation__
 *
 * To run a mutation, you first call `useUpdateOrderDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderDriverMutation, { data, loading, error }] = useUpdateOrderDriverMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateOrderDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderDriverMutation,
    UpdateOrderDriverMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderDriverMutation, UpdateOrderDriverMutationVariables>(
    UpdateOrderDriverDocument,
    options
  );
}
export type UpdateOrderDriverMutationHookResult = ReturnType<typeof useUpdateOrderDriverMutation>;
export type UpdateOrderDriverMutationResult = Apollo.MutationResult<UpdateOrderDriverMutation>;
export type UpdateOrderDriverMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderDriverMutation,
  UpdateOrderDriverMutationVariables
>;
export const RejectComplaintDocument = gql`
  mutation RejectComplaint($restaurantId: String!, $complaintId: String!) {
    rejectComplaint(restaurantId: $restaurantId, complaintId: $complaintId) {
      ...ComplaintsRow
    }
  }
  ${ComplaintsRowFragmentDoc}
`;
export type RejectComplaintMutationFn = Apollo.MutationFunction<
  RejectComplaintMutation,
  RejectComplaintMutationVariables
>;

/**
 * __useRejectComplaintMutation__
 *
 * To run a mutation, you first call `useRejectComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectComplaintMutation, { data, loading, error }] = useRejectComplaintMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      complaintId: // value for 'complaintId'
 *   },
 * });
 */
export function useRejectComplaintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectComplaintMutation,
    RejectComplaintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejectComplaintMutation, RejectComplaintMutationVariables>(
    RejectComplaintDocument,
    options
  );
}
export type RejectComplaintMutationHookResult = ReturnType<typeof useRejectComplaintMutation>;
export type RejectComplaintMutationResult = Apollo.MutationResult<RejectComplaintMutation>;
export type RejectComplaintMutationOptions = Apollo.BaseMutationOptions<
  RejectComplaintMutation,
  RejectComplaintMutationVariables
>;
export const ApproveComplaintDocument = gql`
  mutation ApproveComplaint(
    $restaurantId: String!
    $complaintId: String!
    $orderId: String!
    $loyaltyEngineId: String
    $refundAmount: Int
  ) {
    approveComplaint(
      restaurantId: $restaurantId
      complaintId: $complaintId
      loyaltyEngineId: $loyaltyEngineId
      orderId: $orderId
      refundAmount: $refundAmount
    ) {
      ...ComplaintsRow
    }
  }
  ${ComplaintsRowFragmentDoc}
`;
export type ApproveComplaintMutationFn = Apollo.MutationFunction<
  ApproveComplaintMutation,
  ApproveComplaintMutationVariables
>;

/**
 * __useApproveComplaintMutation__
 *
 * To run a mutation, you first call `useApproveComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveComplaintMutation, { data, loading, error }] = useApproveComplaintMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      complaintId: // value for 'complaintId'
 *      orderId: // value for 'orderId'
 *      loyaltyEngineId: // value for 'loyaltyEngineId'
 *      refundAmount: // value for 'refundAmount'
 *   },
 * });
 */
export function useApproveComplaintMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveComplaintMutation,
    ApproveComplaintMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveComplaintMutation, ApproveComplaintMutationVariables>(
    ApproveComplaintDocument,
    options
  );
}
export type ApproveComplaintMutationHookResult = ReturnType<typeof useApproveComplaintMutation>;
export type ApproveComplaintMutationResult = Apollo.MutationResult<ApproveComplaintMutation>;
export type ApproveComplaintMutationOptions = Apollo.BaseMutationOptions<
  ApproveComplaintMutation,
  ApproveComplaintMutationVariables
>;
export const CreateMasterRestaurantRelationDocument = gql`
  mutation CreateMasterRestaurantRelation(
    $restaurantId: String!
    $masterId: String!
    $dayPeriod: DayPeriod!
  ) {
    createMasterRestaurantRelation(
      restaurantId: $restaurantId
      masterId: $masterId
      dayPeriod: $dayPeriod
    ) {
      masterId
      restaurantId
      dayPeriod
    }
  }
`;
export type CreateMasterRestaurantRelationMutationFn = Apollo.MutationFunction<
  CreateMasterRestaurantRelationMutation,
  CreateMasterRestaurantRelationMutationVariables
>;

/**
 * __useCreateMasterRestaurantRelationMutation__
 *
 * To run a mutation, you first call `useCreateMasterRestaurantRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterRestaurantRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterRestaurantRelationMutation, { data, loading, error }] = useCreateMasterRestaurantRelationMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      masterId: // value for 'masterId'
 *      dayPeriod: // value for 'dayPeriod'
 *   },
 * });
 */
export function useCreateMasterRestaurantRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMasterRestaurantRelationMutation,
    CreateMasterRestaurantRelationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMasterRestaurantRelationMutation,
    CreateMasterRestaurantRelationMutationVariables
  >(CreateMasterRestaurantRelationDocument, options);
}
export type CreateMasterRestaurantRelationMutationHookResult = ReturnType<
  typeof useCreateMasterRestaurantRelationMutation
>;
export type CreateMasterRestaurantRelationMutationResult =
  Apollo.MutationResult<CreateMasterRestaurantRelationMutation>;
export type CreateMasterRestaurantRelationMutationOptions = Apollo.BaseMutationOptions<
  CreateMasterRestaurantRelationMutation,
  CreateMasterRestaurantRelationMutationVariables
>;
export const DeleteMasterRestaurantRelationDocument = gql`
  mutation DeleteMasterRestaurantRelation(
    $restaurantId: String!
    $masterId: String!
    $dayPeriod: DayPeriod!
  ) {
    deleteMasterRestaurantRelation(
      restaurantId: $restaurantId
      masterId: $masterId
      dayPeriod: $dayPeriod
    )
  }
`;
export type DeleteMasterRestaurantRelationMutationFn = Apollo.MutationFunction<
  DeleteMasterRestaurantRelationMutation,
  DeleteMasterRestaurantRelationMutationVariables
>;

/**
 * __useDeleteMasterRestaurantRelationMutation__
 *
 * To run a mutation, you first call `useDeleteMasterRestaurantRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterRestaurantRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterRestaurantRelationMutation, { data, loading, error }] = useDeleteMasterRestaurantRelationMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      masterId: // value for 'masterId'
 *      dayPeriod: // value for 'dayPeriod'
 *   },
 * });
 */
export function useDeleteMasterRestaurantRelationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMasterRestaurantRelationMutation,
    DeleteMasterRestaurantRelationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMasterRestaurantRelationMutation,
    DeleteMasterRestaurantRelationMutationVariables
  >(DeleteMasterRestaurantRelationDocument, options);
}
export type DeleteMasterRestaurantRelationMutationHookResult = ReturnType<
  typeof useDeleteMasterRestaurantRelationMutation
>;
export type DeleteMasterRestaurantRelationMutationResult =
  Apollo.MutationResult<DeleteMasterRestaurantRelationMutation>;
export type DeleteMasterRestaurantRelationMutationOptions = Apollo.BaseMutationOptions<
  DeleteMasterRestaurantRelationMutation,
  DeleteMasterRestaurantRelationMutationVariables
>;
export const UpdateDmpPasswordDocument = gql`
  mutation UpdateDmpPassword($restaurantId: String!, $body: ChangeDmpPasswordInput!) {
    updateDmpPassword(restaurantId: $restaurantId, body: $body)
  }
`;
export type UpdateDmpPasswordMutationFn = Apollo.MutationFunction<
  UpdateDmpPasswordMutation,
  UpdateDmpPasswordMutationVariables
>;

/**
 * __useUpdateDmpPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateDmpPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDmpPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDmpPasswordMutation, { data, loading, error }] = useUpdateDmpPasswordMutation({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateDmpPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDmpPasswordMutation,
    UpdateDmpPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDmpPasswordMutation, UpdateDmpPasswordMutationVariables>(
    UpdateDmpPasswordDocument,
    options
  );
}
export type UpdateDmpPasswordMutationHookResult = ReturnType<typeof useUpdateDmpPasswordMutation>;
export type UpdateDmpPasswordMutationResult = Apollo.MutationResult<UpdateDmpPasswordMutation>;
export type UpdateDmpPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateDmpPasswordMutation,
  UpdateDmpPasswordMutationVariables
>;
export const PutRiderAppPolicyDocument = gql`
  mutation PutRiderAppPolicy($body: CreatePolicy!) {
    putRiderAppPolicy(body: $body)
  }
`;
export type PutRiderAppPolicyMutationFn = Apollo.MutationFunction<
  PutRiderAppPolicyMutation,
  PutRiderAppPolicyMutationVariables
>;

/**
 * __usePutRiderAppPolicyMutation__
 *
 * To run a mutation, you first call `usePutRiderAppPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutRiderAppPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putRiderAppPolicyMutation, { data, loading, error }] = usePutRiderAppPolicyMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function usePutRiderAppPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PutRiderAppPolicyMutation,
    PutRiderAppPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PutRiderAppPolicyMutation, PutRiderAppPolicyMutationVariables>(
    PutRiderAppPolicyDocument,
    options
  );
}
export type PutRiderAppPolicyMutationHookResult = ReturnType<typeof usePutRiderAppPolicyMutation>;
export type PutRiderAppPolicyMutationResult = Apollo.MutationResult<PutRiderAppPolicyMutation>;
export type PutRiderAppPolicyMutationOptions = Apollo.BaseMutationOptions<
  PutRiderAppPolicyMutation,
  PutRiderAppPolicyMutationVariables
>;
export const DeleteRiderAppPolicyDocument = gql`
  mutation DeleteRiderAppPolicy {
    deleteRiderAppPolicy
  }
`;
export type DeleteRiderAppPolicyMutationFn = Apollo.MutationFunction<
  DeleteRiderAppPolicyMutation,
  DeleteRiderAppPolicyMutationVariables
>;

/**
 * __useDeleteRiderAppPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteRiderAppPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRiderAppPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRiderAppPolicyMutation, { data, loading, error }] = useDeleteRiderAppPolicyMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteRiderAppPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRiderAppPolicyMutation,
    DeleteRiderAppPolicyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRiderAppPolicyMutation, DeleteRiderAppPolicyMutationVariables>(
    DeleteRiderAppPolicyDocument,
    options
  );
}
export type DeleteRiderAppPolicyMutationHookResult = ReturnType<
  typeof useDeleteRiderAppPolicyMutation
>;
export type DeleteRiderAppPolicyMutationResult =
  Apollo.MutationResult<DeleteRiderAppPolicyMutation>;
export type DeleteRiderAppPolicyMutationOptions = Apollo.BaseMutationOptions<
  DeleteRiderAppPolicyMutation,
  DeleteRiderAppPolicyMutationVariables
>;
export const GetMenuDocument = gql`
  query GetMenu($region: String) {
    menu(region: $region)
  }
`;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetMenuQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
}
export function useGetMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
}
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;
export const GetUserManagementRestaurantsDocument = gql`
  query GetUserManagementRestaurants($limit: Int, $offset: Int, $pos: [String!]) {
    allRestaurants(where: { limit: $limit, offset: $offset, pos: $pos }) {
      allRestaurants {
        ...LightRestaurantFragment
      }
    }
  }
  ${LightRestaurantFragmentFragmentDoc}
`;

/**
 * __useGetUserManagementRestaurantsQuery__
 *
 * To run a query within a React component, call `useGetUserManagementRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserManagementRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserManagementRestaurantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      pos: // value for 'pos'
 *   },
 * });
 */
export function useGetUserManagementRestaurantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserManagementRestaurantsQuery,
    GetUserManagementRestaurantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserManagementRestaurantsQuery,
    GetUserManagementRestaurantsQueryVariables
  >(GetUserManagementRestaurantsDocument, options);
}
export function useGetUserManagementRestaurantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserManagementRestaurantsQuery,
    GetUserManagementRestaurantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserManagementRestaurantsQuery,
    GetUserManagementRestaurantsQueryVariables
  >(GetUserManagementRestaurantsDocument, options);
}
export type GetUserManagementRestaurantsQueryHookResult = ReturnType<
  typeof useGetUserManagementRestaurantsQuery
>;
export type GetUserManagementRestaurantsLazyQueryHookResult = ReturnType<
  typeof useGetUserManagementRestaurantsLazyQuery
>;
export type GetUserManagementRestaurantsQueryResult = Apollo.QueryResult<
  GetUserManagementRestaurantsQuery,
  GetUserManagementRestaurantsQueryVariables
>;
export const GetRestaurantGroupByIdDocument = gql`
  query GetRestaurantGroupById($groupIds: [String!]!) {
    getRestaurantGroupsByIds(groupId: $groupIds) {
      _id
      name
      restaurants {
        id
      }
    }
  }
`;

/**
 * __useGetRestaurantGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetRestaurantGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantGroupByIdQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useGetRestaurantGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestaurantGroupByIdQuery,
    GetRestaurantGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantGroupByIdQuery, GetRestaurantGroupByIdQueryVariables>(
    GetRestaurantGroupByIdDocument,
    options
  );
}
export function useGetRestaurantGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantGroupByIdQuery,
    GetRestaurantGroupByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantGroupByIdQuery, GetRestaurantGroupByIdQueryVariables>(
    GetRestaurantGroupByIdDocument,
    options
  );
}
export type GetRestaurantGroupByIdQueryHookResult = ReturnType<
  typeof useGetRestaurantGroupByIdQuery
>;
export type GetRestaurantGroupByIdLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantGroupByIdLazyQuery
>;
export type GetRestaurantGroupByIdQueryResult = Apollo.QueryResult<
  GetRestaurantGroupByIdQuery,
  GetRestaurantGroupByIdQueryVariables
>;
export const GetRestaurantsDocument = gql`
  query GetRestaurants(
    $limit: Int
    $offset: Int
    $pos: [String!]
    $availability: [AvailabilityModes]
    $environment: [String!]
    $region: String
  ) {
    userRestaurants(
      where: {
        limit: $limit
        offset: $offset
        pos: $pos
        availability: $availability
        environment: $environment
        region: $region
      }
    ) {
      allRestaurants {
        ...RestaurantFragment
      }
      totalCount
    }
  }
  ${RestaurantFragmentFragmentDoc}
`;

/**
 * __useGetRestaurantsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      pos: // value for 'pos'
 *      availability: // value for 'availability'
 *      environment: // value for 'environment'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetRestaurantsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRestaurantsQuery, GetRestaurantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantsQuery, GetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export function useGetRestaurantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantsQuery, GetRestaurantsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantsQuery, GetRestaurantsQueryVariables>(
    GetRestaurantsDocument,
    options
  );
}
export type GetRestaurantsQueryHookResult = ReturnType<typeof useGetRestaurantsQuery>;
export type GetRestaurantsLazyQueryHookResult = ReturnType<typeof useGetRestaurantsLazyQuery>;
export type GetRestaurantsQueryResult = Apollo.QueryResult<
  GetRestaurantsQuery,
  GetRestaurantsQueryVariables
>;
export const GetRestaurantsShortDocument = gql`
  query GetRestaurantsShort(
    $limit: Int
    $offset: Int
    $pos: [String!]
    $availability: [AvailabilityModes]
    $region: String
  ) {
    userRestaurants(
      where: {
        limit: $limit
        offset: $offset
        pos: $pos
        availability: $availability
        region: $region
      }
    ) {
      allRestaurants {
        id
        name
      }
      totalCount
    }
  }
`;

/**
 * __useGetRestaurantsShortQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsShortQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      pos: // value for 'pos'
 *      availability: // value for 'availability'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetRestaurantsShortQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRestaurantsShortQuery, GetRestaurantsShortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantsShortQuery, GetRestaurantsShortQueryVariables>(
    GetRestaurantsShortDocument,
    options
  );
}
export function useGetRestaurantsShortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantsShortQuery,
    GetRestaurantsShortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantsShortQuery, GetRestaurantsShortQueryVariables>(
    GetRestaurantsShortDocument,
    options
  );
}
export type GetRestaurantsShortQueryHookResult = ReturnType<typeof useGetRestaurantsShortQuery>;
export type GetRestaurantsShortLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantsShortLazyQuery
>;
export type GetRestaurantsShortQueryResult = Apollo.QueryResult<
  GetRestaurantsShortQuery,
  GetRestaurantsShortQueryVariables
>;
export const GetRestaurantsShortWithMenuPricesDocument = gql`
  query GetRestaurantsShortWithMenuPrices(
    $limit: Int
    $offset: Int
    $restaurantsIds: [String!]
    $serviceMode: ServiceMode
  ) {
    userRestaurants(where: { limit: $limit, offset: $offset, restaurantIds: $restaurantsIds }) {
      allRestaurants {
        id
        name
        menuPrices(serviceMode: $serviceMode) {
          id
          productId
          plu
          price
          availability
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetRestaurantsShortWithMenuPricesQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsShortWithMenuPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsShortWithMenuPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsShortWithMenuPricesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      restaurantsIds: // value for 'restaurantsIds'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useGetRestaurantsShortWithMenuPricesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRestaurantsShortWithMenuPricesQuery,
    GetRestaurantsShortWithMenuPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRestaurantsShortWithMenuPricesQuery,
    GetRestaurantsShortWithMenuPricesQueryVariables
  >(GetRestaurantsShortWithMenuPricesDocument, options);
}
export function useGetRestaurantsShortWithMenuPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantsShortWithMenuPricesQuery,
    GetRestaurantsShortWithMenuPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestaurantsShortWithMenuPricesQuery,
    GetRestaurantsShortWithMenuPricesQueryVariables
  >(GetRestaurantsShortWithMenuPricesDocument, options);
}
export type GetRestaurantsShortWithMenuPricesQueryHookResult = ReturnType<
  typeof useGetRestaurantsShortWithMenuPricesQuery
>;
export type GetRestaurantsShortWithMenuPricesLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantsShortWithMenuPricesLazyQuery
>;
export type GetRestaurantsShortWithMenuPricesQueryResult = Apollo.QueryResult<
  GetRestaurantsShortWithMenuPricesQuery,
  GetRestaurantsShortWithMenuPricesQueryVariables
>;
export const GetRestaurantsWithMenuItemsDocument = gql`
  query GetRestaurantsWithMenuItems(
    $productIds: [String!]
    $serviceMode: ServiceMode
    $limit: Int
    $offset: Int
  ) {
    userRestaurants(where: { limit: $limit, offset: $offset }) {
      allRestaurants {
        ...LightRestaurantFragment
        menuPrices(productIds: $productIds, serviceMode: $serviceMode) {
          ...ProductPriceFragment
        }
      }
      totalCount
    }
  }
  ${LightRestaurantFragmentFragmentDoc}
  ${ProductPriceFragmentFragmentDoc}
`;

/**
 * __useGetRestaurantsWithMenuItemsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsWithMenuItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsWithMenuItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsWithMenuItemsQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      serviceMode: // value for 'serviceMode'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetRestaurantsWithMenuItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRestaurantsWithMenuItemsQuery,
    GetRestaurantsWithMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRestaurantsWithMenuItemsQuery,
    GetRestaurantsWithMenuItemsQueryVariables
  >(GetRestaurantsWithMenuItemsDocument, options);
}
export function useGetRestaurantsWithMenuItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantsWithMenuItemsQuery,
    GetRestaurantsWithMenuItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestaurantsWithMenuItemsQuery,
    GetRestaurantsWithMenuItemsQueryVariables
  >(GetRestaurantsWithMenuItemsDocument, options);
}
export type GetRestaurantsWithMenuItemsQueryHookResult = ReturnType<
  typeof useGetRestaurantsWithMenuItemsQuery
>;
export type GetRestaurantsWithMenuItemsLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantsWithMenuItemsLazyQuery
>;
export type GetRestaurantsWithMenuItemsQueryResult = Apollo.QueryResult<
  GetRestaurantsWithMenuItemsQuery,
  GetRestaurantsWithMenuItemsQueryVariables
>;
export const GetRestaurantsPaymentConfigDocument = gql`
  query GetRestaurantsPaymentConfig($restaurantIds: [String!]) {
    userRestaurants(where: { restaurantIds: $restaurantIds }) {
      allRestaurants {
        id
        deliveryOrderAmountLimit {
          deliveryOrderAmountLimit
          deliveryOrderAmountLimitEnabled
          deliveryOrderRepeatedFailureLimitation
          firstDeliveryOrder
          firstDeliveryOrderEnabled
        }
        paymentMethods {
          name
          paymentMethodBrand
          isOnlinePayment
          state
        }
      }
    }
  }
`;

/**
 * __useGetRestaurantsPaymentConfigQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsPaymentConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsPaymentConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsPaymentConfigQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *   },
 * });
 */
export function useGetRestaurantsPaymentConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRestaurantsPaymentConfigQuery,
    GetRestaurantsPaymentConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRestaurantsPaymentConfigQuery,
    GetRestaurantsPaymentConfigQueryVariables
  >(GetRestaurantsPaymentConfigDocument, options);
}
export function useGetRestaurantsPaymentConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantsPaymentConfigQuery,
    GetRestaurantsPaymentConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestaurantsPaymentConfigQuery,
    GetRestaurantsPaymentConfigQueryVariables
  >(GetRestaurantsPaymentConfigDocument, options);
}
export type GetRestaurantsPaymentConfigQueryHookResult = ReturnType<
  typeof useGetRestaurantsPaymentConfigQuery
>;
export type GetRestaurantsPaymentConfigLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantsPaymentConfigLazyQuery
>;
export type GetRestaurantsPaymentConfigQueryResult = Apollo.QueryResult<
  GetRestaurantsPaymentConfigQuery,
  GetRestaurantsPaymentConfigQueryVariables
>;
export const GetMenuPricesForEditorDocument = gql`
  query GetMenuPricesForEditor(
    $restaurantsIds: [String!]
    $productIdPlus: [String!]
    $channel: Channel!
    $serviceMode: ServiceMode!
    $limit: Int
    $offset: Int
  ) {
    userRestaurants(where: { limit: $limit, offset: $offset, restaurantIds: $restaurantsIds }) {
      allRestaurants {
        ...LightRestaurantFragment
        menuPricesV3(productIdPlus: $productIdPlus, channel: $channel, serviceMode: $serviceMode) {
          ...ProductPriceV3Fragment
        }
      }
      totalCount
    }
  }
  ${LightRestaurantFragmentFragmentDoc}
  ${ProductPriceV3FragmentFragmentDoc}
`;

/**
 * __useGetMenuPricesForEditorQuery__
 *
 * To run a query within a React component, call `useGetMenuPricesForEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuPricesForEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuPricesForEditorQuery({
 *   variables: {
 *      restaurantsIds: // value for 'restaurantsIds'
 *      productIdPlus: // value for 'productIdPlus'
 *      channel: // value for 'channel'
 *      serviceMode: // value for 'serviceMode'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMenuPricesForEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMenuPricesForEditorQuery,
    GetMenuPricesForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMenuPricesForEditorQuery, GetMenuPricesForEditorQueryVariables>(
    GetMenuPricesForEditorDocument,
    options
  );
}
export function useGetMenuPricesForEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMenuPricesForEditorQuery,
    GetMenuPricesForEditorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMenuPricesForEditorQuery, GetMenuPricesForEditorQueryVariables>(
    GetMenuPricesForEditorDocument,
    options
  );
}
export type GetMenuPricesForEditorQueryHookResult = ReturnType<
  typeof useGetMenuPricesForEditorQuery
>;
export type GetMenuPricesForEditorLazyQueryHookResult = ReturnType<
  typeof useGetMenuPricesForEditorLazyQuery
>;
export type GetMenuPricesForEditorQueryResult = Apollo.QueryResult<
  GetMenuPricesForEditorQuery,
  GetMenuPricesForEditorQueryVariables
>;
export const GetRestaurantGroupsDocument = gql`
  query GetRestaurantGroups($userId: String, $region: String) {
    allRestaurantGroups(where: { userId: $userId, region: $region }) {
      groups {
        _id
        name
        restaurants {
          id
          pos {
            vendor
          }
        }
      }
    }
  }
`;

/**
 * __useGetRestaurantGroupsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantGroupsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useGetRestaurantGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRestaurantGroupsQuery, GetRestaurantGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantGroupsQuery, GetRestaurantGroupsQueryVariables>(
    GetRestaurantGroupsDocument,
    options
  );
}
export function useGetRestaurantGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantGroupsQuery,
    GetRestaurantGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantGroupsQuery, GetRestaurantGroupsQueryVariables>(
    GetRestaurantGroupsDocument,
    options
  );
}
export type GetRestaurantGroupsQueryHookResult = ReturnType<typeof useGetRestaurantGroupsQuery>;
export type GetRestaurantGroupsLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantGroupsLazyQuery
>;
export type GetRestaurantGroupsQueryResult = Apollo.QueryResult<
  GetRestaurantGroupsQuery,
  GetRestaurantGroupsQueryVariables
>;
export const GetOrdersDocument = gql`
  query GetOrders($restaurantIds: [String!], $limit: Int, $offset: Int) {
    allOrders(restaurantIds: $restaurantIds, limit: $limit, offset: $offset) {
      allOrders {
        ...RestaurantOrderFragment
      }
      restaurantsIds
    }
  }
  ${RestaurantOrderFragmentFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetHistoryDocument = gql`
  query GetHistory(
    $restaurantId: String!
    $userOktaId: String!
    $status: String!
    $startKey: String!
    $source: String!
  ) {
    history(
      restaurantId: $restaurantId
      userOktaId: $userOktaId
      status: $status
      startKey: $startKey
      source: $source
    ) {
      allHistory {
        ...HistoryFragment
      }
      nextPageKey
      source
    }
  }
  ${HistoryFragmentFragmentDoc}
`;

/**
 * __useGetHistoryQuery__
 *
 * To run a query within a React component, call `useGetHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoryQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      userOktaId: // value for 'userOktaId'
 *      status: // value for 'status'
 *      startKey: // value for 'startKey'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetHistoryQuery, GetHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHistoryQuery, GetHistoryQueryVariables>(GetHistoryDocument, options);
}
export function useGetHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHistoryQuery, GetHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHistoryQuery, GetHistoryQueryVariables>(
    GetHistoryDocument,
    options
  );
}
export type GetHistoryQueryHookResult = ReturnType<typeof useGetHistoryQuery>;
export type GetHistoryLazyQueryHookResult = ReturnType<typeof useGetHistoryLazyQuery>;
export type GetHistoryQueryResult = Apollo.QueryResult<GetHistoryQuery, GetHistoryQueryVariables>;
export const GetAllRestaurantsDeliveryQueryDocument = gql`
  query GetAllRestaurantsDeliveryQuery($restaurantsIds: [String!], $limit: Int, $offset: Int) {
    allRestaurantsDelivery(
      where: { limit: $limit, offset: $offset, restaurantIds: $restaurantsIds }
    ) {
      allRestaurantsDelivery {
        ...RestaurantDeliveryFragment
      }
      totalCount
    }
  }
  ${RestaurantDeliveryFragmentFragmentDoc}
`;

/**
 * __useGetAllRestaurantsDeliveryQueryQuery__
 *
 * To run a query within a React component, call `useGetAllRestaurantsDeliveryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRestaurantsDeliveryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRestaurantsDeliveryQueryQuery({
 *   variables: {
 *      restaurantsIds: // value for 'restaurantsIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllRestaurantsDeliveryQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRestaurantsDeliveryQueryQuery,
    GetAllRestaurantsDeliveryQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllRestaurantsDeliveryQueryQuery,
    GetAllRestaurantsDeliveryQueryQueryVariables
  >(GetAllRestaurantsDeliveryQueryDocument, options);
}
export function useGetAllRestaurantsDeliveryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRestaurantsDeliveryQueryQuery,
    GetAllRestaurantsDeliveryQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllRestaurantsDeliveryQueryQuery,
    GetAllRestaurantsDeliveryQueryQueryVariables
  >(GetAllRestaurantsDeliveryQueryDocument, options);
}
export type GetAllRestaurantsDeliveryQueryQueryHookResult = ReturnType<
  typeof useGetAllRestaurantsDeliveryQueryQuery
>;
export type GetAllRestaurantsDeliveryQueryLazyQueryHookResult = ReturnType<
  typeof useGetAllRestaurantsDeliveryQueryLazyQuery
>;
export type GetAllRestaurantsDeliveryQueryQueryResult = Apollo.QueryResult<
  GetAllRestaurantsDeliveryQueryQuery,
  GetAllRestaurantsDeliveryQueryQueryVariables
>;
export const GetUserByOktaIdDocument = gql`
  query GetUserByOktaId($oktaId: String!) {
    users(oktaId: $oktaId) {
      OktaId
      Country
      RestaurantIds
      RoleId
      RoleName
      UserName
      Email
    }
  }
`;

/**
 * __useGetUserByOktaIdQuery__
 *
 * To run a query within a React component, call `useGetUserByOktaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByOktaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByOktaIdQuery({
 *   variables: {
 *      oktaId: // value for 'oktaId'
 *   },
 * });
 */
export function useGetUserByOktaIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>(
    GetUserByOktaIdDocument,
    options
  );
}
export function useGetUserByOktaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByOktaIdQuery, GetUserByOktaIdQueryVariables>(
    GetUserByOktaIdDocument,
    options
  );
}
export type GetUserByOktaIdQueryHookResult = ReturnType<typeof useGetUserByOktaIdQuery>;
export type GetUserByOktaIdLazyQueryHookResult = ReturnType<typeof useGetUserByOktaIdLazyQuery>;
export type GetUserByOktaIdQueryResult = Apollo.QueryResult<
  GetUserByOktaIdQuery,
  GetUserByOktaIdQueryVariables
>;
export const GetUserPermissionsByOktaIdDocument = gql`
  query GetUserPermissionsByOktaId($oktaId: String!) {
    users(oktaId: $oktaId) {
      OktaId
      Country
      RestaurantIds
      RoleId
      RoleName
      Permissions
      UserName
      Email
    }
  }
`;

/**
 * __useGetUserPermissionsByOktaIdQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsByOktaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsByOktaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsByOktaIdQuery({
 *   variables: {
 *      oktaId: // value for 'oktaId'
 *   },
 * });
 */
export function useGetUserPermissionsByOktaIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserPermissionsByOktaIdQuery,
    GetUserPermissionsByOktaIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPermissionsByOktaIdQuery, GetUserPermissionsByOktaIdQueryVariables>(
    GetUserPermissionsByOktaIdDocument,
    options
  );
}
export function useGetUserPermissionsByOktaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPermissionsByOktaIdQuery,
    GetUserPermissionsByOktaIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPermissionsByOktaIdQuery,
    GetUserPermissionsByOktaIdQueryVariables
  >(GetUserPermissionsByOktaIdDocument, options);
}
export type GetUserPermissionsByOktaIdQueryHookResult = ReturnType<
  typeof useGetUserPermissionsByOktaIdQuery
>;
export type GetUserPermissionsByOktaIdLazyQueryHookResult = ReturnType<
  typeof useGetUserPermissionsByOktaIdLazyQuery
>;
export type GetUserPermissionsByOktaIdQueryResult = Apollo.QueryResult<
  GetUserPermissionsByOktaIdQuery,
  GetUserPermissionsByOktaIdQueryVariables
>;
export const GetAllDriversDocument = gql`
  query GetAllDrivers($restaurantId: String!, $limit: Int, $offset: Int) {
    allDrivers(restaurantId: $restaurantId, limit: $limit, offset: $offset) {
      allDrivers {
        availabilityStatus
        deliveryAvailabilityStatus
        document
        email
        createdAt
        updatedAt
        firstName
        lastName
        identifier
        orderLimit
        motorbike
        phoneNumber
        realtimeStatus
        restaurant
      }
    }
  }
`;

/**
 * __useGetAllDriversQuery__
 *
 * To run a query within a React component, call `useGetAllDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDriversQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllDriversQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllDriversQuery, GetAllDriversQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(
    GetAllDriversDocument,
    options
  );
}
export function useGetAllDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllDriversQuery, GetAllDriversQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDriversQuery, GetAllDriversQueryVariables>(
    GetAllDriversDocument,
    options
  );
}
export type GetAllDriversQueryHookResult = ReturnType<typeof useGetAllDriversQuery>;
export type GetAllDriversLazyQueryHookResult = ReturnType<typeof useGetAllDriversLazyQuery>;
export type GetAllDriversQueryResult = Apollo.QueryResult<
  GetAllDriversQuery,
  GetAllDriversQueryVariables
>;
export const GetDriverByIdDocument = gql`
  query GetDriverById($driverId: String!, $restaurantId: String!) {
    driver(restaurantId: $restaurantId, driverId: $driverId) {
      firstName
      lastName
      document
      identifier
      phoneNumber
      orderLimit
      email
    }
  }
`;

/**
 * __useGetDriverByIdQuery__
 *
 * To run a query within a React component, call `useGetDriverByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverByIdQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetDriverByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriverByIdQuery, GetDriverByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriverByIdQuery, GetDriverByIdQueryVariables>(
    GetDriverByIdDocument,
    options
  );
}
export function useGetDriverByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriverByIdQuery, GetDriverByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriverByIdQuery, GetDriverByIdQueryVariables>(
    GetDriverByIdDocument,
    options
  );
}
export type GetDriverByIdQueryHookResult = ReturnType<typeof useGetDriverByIdQuery>;
export type GetDriverByIdLazyQueryHookResult = ReturnType<typeof useGetDriverByIdLazyQuery>;
export type GetDriverByIdQueryResult = Apollo.QueryResult<
  GetDriverByIdQuery,
  GetDriverByIdQueryVariables
>;
export const GetDriverNameDocument = gql`
  query GetDriverName($driverId: String!, $restaurantId: String!) {
    driver(restaurantId: $restaurantId, driverId: $driverId) {
      firstName
      lastName
    }
  }
`;

/**
 * __useGetDriverNameQuery__
 *
 * To run a query within a React component, call `useGetDriverNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverNameQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetDriverNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetDriverNameQuery, GetDriverNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriverNameQuery, GetDriverNameQueryVariables>(
    GetDriverNameDocument,
    options
  );
}
export function useGetDriverNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriverNameQuery, GetDriverNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriverNameQuery, GetDriverNameQueryVariables>(
    GetDriverNameDocument,
    options
  );
}
export type GetDriverNameQueryHookResult = ReturnType<typeof useGetDriverNameQuery>;
export type GetDriverNameLazyQueryHookResult = ReturnType<typeof useGetDriverNameLazyQuery>;
export type GetDriverNameQueryResult = Apollo.QueryResult<
  GetDriverNameQuery,
  GetDriverNameQueryVariables
>;
export const PolygonsDocument = gql`
  query Polygons($restaurantId: String!) {
    polygons(restaurantId: $restaurantId) {
      id
      polygons {
        default
        pendingApproval
        restaurantId
        createdAt
        createdBy
        updatedAt
        updatedBy
        restaurantId
        gridArea {
          id
          polygon {
            coordinates
            color
          }
        }
        id
        name
        mainArea {
          coordinates
          color
        }
        reducedArea {
          coordinates
          color
        }
      }
    }
  }
`;

/**
 * __usePolygonsQuery__
 *
 * To run a query within a React component, call `usePolygonsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolygonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolygonsQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function usePolygonsQuery(
  baseOptions: Apollo.QueryHookOptions<PolygonsQuery, PolygonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PolygonsQuery, PolygonsQueryVariables>(PolygonsDocument, options);
}
export function usePolygonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PolygonsQuery, PolygonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PolygonsQuery, PolygonsQueryVariables>(PolygonsDocument, options);
}
export type PolygonsQueryHookResult = ReturnType<typeof usePolygonsQuery>;
export type PolygonsLazyQueryHookResult = ReturnType<typeof usePolygonsLazyQuery>;
export type PolygonsQueryResult = Apollo.QueryResult<PolygonsQuery, PolygonsQueryVariables>;
export const MultiplePolygonsDocument = gql`
  query MultiplePolygons {
    multiplePolygons {
      id
      polygons {
        default
        pendingApproval
        gridArea {
          id
          polygon {
            color
            coordinates
          }
        }
        id
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
        mainArea {
          color
          coordinates
        }
        reducedArea {
          color
          coordinates
        }
        restaurantId
      }
    }
  }
`;

/**
 * __useMultiplePolygonsQuery__
 *
 * To run a query within a React component, call `useMultiplePolygonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiplePolygonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiplePolygonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiplePolygonsQuery(
  baseOptions?: Apollo.QueryHookOptions<MultiplePolygonsQuery, MultiplePolygonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MultiplePolygonsQuery, MultiplePolygonsQueryVariables>(
    MultiplePolygonsDocument,
    options
  );
}
export function useMultiplePolygonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MultiplePolygonsQuery, MultiplePolygonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MultiplePolygonsQuery, MultiplePolygonsQueryVariables>(
    MultiplePolygonsDocument,
    options
  );
}
export type MultiplePolygonsQueryHookResult = ReturnType<typeof useMultiplePolygonsQuery>;
export type MultiplePolygonsLazyQueryHookResult = ReturnType<typeof useMultiplePolygonsLazyQuery>;
export type MultiplePolygonsQueryResult = Apollo.QueryResult<
  MultiplePolygonsQuery,
  MultiplePolygonsQueryVariables
>;
export const GetUsersByCountryDocument = gql`
  query GetUsersByCountry {
    users {
      OktaId
      UserName
      Country
      RoleId
      RoleName
      Email
      RestaurantIds
      Status
    }
  }
`;

/**
 * __useGetUsersByCountryQuery__
 *
 * To run a query within a React component, call `useGetUsersByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersByCountryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersByCountryQuery, GetUsersByCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByCountryQuery, GetUsersByCountryQueryVariables>(
    GetUsersByCountryDocument,
    options
  );
}
export function useGetUsersByCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByCountryQuery, GetUsersByCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByCountryQuery, GetUsersByCountryQueryVariables>(
    GetUsersByCountryDocument,
    options
  );
}
export type GetUsersByCountryQueryHookResult = ReturnType<typeof useGetUsersByCountryQuery>;
export type GetUsersByCountryLazyQueryHookResult = ReturnType<typeof useGetUsersByCountryLazyQuery>;
export type GetUsersByCountryQueryResult = Apollo.QueryResult<
  GetUsersByCountryQuery,
  GetUsersByCountryQueryVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      RoleId
      RoleName
      Permissions
      CreatedAt
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetRestaurantSettingsDocument = gql`
  query GetRestaurantSettings($restaurantId: String!) {
    restaurant(restaurantId: $restaurantId) {
      id
      name
      settings {
        deliverySettings {
          deliveryCost
          eta
        }
        driverOrderLimit
        autoFireRadius
        allowAutoPrepare
        allowAutoFire
        allowPauseService
        allowReducedArea
        allowRefund
        allowVoucher
        notifications {
          deliveryArea
          eta
          pauseService
          posOffline
        }
        lastMile {
          providers
          catcherLocationId
          settings {
            provider
            disabledPaymentMethods
          }
        }
      }
    }
    etaValues(restaurantId: $restaurantId) {
      etaValues
    }
  }
`;

/**
 * __useGetRestaurantSettingsQuery__
 *
 * To run a query within a React component, call `useGetRestaurantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantSettingsQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetRestaurantSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestaurantSettingsQuery,
    GetRestaurantSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantSettingsQuery, GetRestaurantSettingsQueryVariables>(
    GetRestaurantSettingsDocument,
    options
  );
}
export function useGetRestaurantSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantSettingsQuery,
    GetRestaurantSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantSettingsQuery, GetRestaurantSettingsQueryVariables>(
    GetRestaurantSettingsDocument,
    options
  );
}
export type GetRestaurantSettingsQueryHookResult = ReturnType<typeof useGetRestaurantSettingsQuery>;
export type GetRestaurantSettingsLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantSettingsLazyQuery
>;
export type GetRestaurantSettingsQueryResult = Apollo.QueryResult<
  GetRestaurantSettingsQuery,
  GetRestaurantSettingsQueryVariables
>;
export const GetLastMileProvidersDocument = gql`
  query GetLastMileProviders {
    getLastMileProviders {
      lastMileProviders
    }
  }
`;

/**
 * __useGetLastMileProvidersQuery__
 *
 * To run a query within a React component, call `useGetLastMileProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastMileProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastMileProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastMileProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLastMileProvidersQuery,
    GetLastMileProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastMileProvidersQuery, GetLastMileProvidersQueryVariables>(
    GetLastMileProvidersDocument,
    options
  );
}
export function useGetLastMileProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastMileProvidersQuery,
    GetLastMileProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastMileProvidersQuery, GetLastMileProvidersQueryVariables>(
    GetLastMileProvidersDocument,
    options
  );
}
export type GetLastMileProvidersQueryHookResult = ReturnType<typeof useGetLastMileProvidersQuery>;
export type GetLastMileProvidersLazyQueryHookResult = ReturnType<
  typeof useGetLastMileProvidersLazyQuery
>;
export type GetLastMileProvidersQueryResult = Apollo.QueryResult<
  GetLastMileProvidersQuery,
  GetLastMileProvidersQueryVariables
>;
export const GetRestaurantOrderLimitDocument = gql`
  query GetRestaurantOrderLimit($restaurantId: String!) {
    restaurant(restaurantId: $restaurantId) {
      settings {
        driverOrderLimit
      }
    }
  }
`;

/**
 * __useGetRestaurantOrderLimitQuery__
 *
 * To run a query within a React component, call `useGetRestaurantOrderLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantOrderLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantOrderLimitQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetRestaurantOrderLimitQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestaurantOrderLimitQuery,
    GetRestaurantOrderLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantOrderLimitQuery, GetRestaurantOrderLimitQueryVariables>(
    GetRestaurantOrderLimitDocument,
    options
  );
}
export function useGetRestaurantOrderLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantOrderLimitQuery,
    GetRestaurantOrderLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantOrderLimitQuery, GetRestaurantOrderLimitQueryVariables>(
    GetRestaurantOrderLimitDocument,
    options
  );
}
export type GetRestaurantOrderLimitQueryHookResult = ReturnType<
  typeof useGetRestaurantOrderLimitQuery
>;
export type GetRestaurantOrderLimitLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantOrderLimitLazyQuery
>;
export type GetRestaurantOrderLimitQueryResult = Apollo.QueryResult<
  GetRestaurantOrderLimitQuery,
  GetRestaurantOrderLimitQueryVariables
>;
export const GetAllChangeDriversDocument = gql`
  query GetAllChangeDrivers($restaurantId: String!, $limit: Int, $offset: Int) {
    allDrivers(restaurantId: $restaurantId, limit: $limit, offset: $offset) {
      allDrivers {
        firstName
        lastName
        identifier
        phoneNumber
      }
    }
  }
`;

/**
 * __useGetAllChangeDriversQuery__
 *
 * To run a query within a React component, call `useGetAllChangeDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChangeDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChangeDriversQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllChangeDriversQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllChangeDriversQuery, GetAllChangeDriversQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllChangeDriversQuery, GetAllChangeDriversQueryVariables>(
    GetAllChangeDriversDocument,
    options
  );
}
export function useGetAllChangeDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllChangeDriversQuery,
    GetAllChangeDriversQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllChangeDriversQuery, GetAllChangeDriversQueryVariables>(
    GetAllChangeDriversDocument,
    options
  );
}
export type GetAllChangeDriversQueryHookResult = ReturnType<typeof useGetAllChangeDriversQuery>;
export type GetAllChangeDriversLazyQueryHookResult = ReturnType<
  typeof useGetAllChangeDriversLazyQuery
>;
export type GetAllChangeDriversQueryResult = Apollo.QueryResult<
  GetAllChangeDriversQuery,
  GetAllChangeDriversQueryVariables
>;
export const GetClosedOrdersDocument = gql`
  query GetClosedOrders($restaurantId: String!) {
    closedOrders(restaurantId: $restaurantId) {
      createdAt
      delivery {
        driver {
          name
        }
        status
      }
      orderNumberFormatted
      totalCents
      paymentType
      paymentMethodBrand
      id
    }
  }
`;

/**
 * __useGetClosedOrdersQuery__
 *
 * To run a query within a React component, call `useGetClosedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedOrdersQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetClosedOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetClosedOrdersQuery, GetClosedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClosedOrdersQuery, GetClosedOrdersQueryVariables>(
    GetClosedOrdersDocument,
    options
  );
}
export function useGetClosedOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetClosedOrdersQuery, GetClosedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClosedOrdersQuery, GetClosedOrdersQueryVariables>(
    GetClosedOrdersDocument,
    options
  );
}
export type GetClosedOrdersQueryHookResult = ReturnType<typeof useGetClosedOrdersQuery>;
export type GetClosedOrdersLazyQueryHookResult = ReturnType<typeof useGetClosedOrdersLazyQuery>;
export type GetClosedOrdersQueryResult = Apollo.QueryResult<
  GetClosedOrdersQuery,
  GetClosedOrdersQueryVariables
>;
export const GetAllRestaurantsDeliveryNameDocument = gql`
  query GetAllRestaurantsDeliveryName {
    allRestaurantsDelivery {
      allRestaurantsDelivery {
        internalName
        name
        id
      }
    }
  }
`;

/**
 * __useGetAllRestaurantsDeliveryNameQuery__
 *
 * To run a query within a React component, call `useGetAllRestaurantsDeliveryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRestaurantsDeliveryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRestaurantsDeliveryNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRestaurantsDeliveryNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllRestaurantsDeliveryNameQuery,
    GetAllRestaurantsDeliveryNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllRestaurantsDeliveryNameQuery,
    GetAllRestaurantsDeliveryNameQueryVariables
  >(GetAllRestaurantsDeliveryNameDocument, options);
}
export function useGetAllRestaurantsDeliveryNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllRestaurantsDeliveryNameQuery,
    GetAllRestaurantsDeliveryNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllRestaurantsDeliveryNameQuery,
    GetAllRestaurantsDeliveryNameQueryVariables
  >(GetAllRestaurantsDeliveryNameDocument, options);
}
export type GetAllRestaurantsDeliveryNameQueryHookResult = ReturnType<
  typeof useGetAllRestaurantsDeliveryNameQuery
>;
export type GetAllRestaurantsDeliveryNameLazyQueryHookResult = ReturnType<
  typeof useGetAllRestaurantsDeliveryNameLazyQuery
>;
export type GetAllRestaurantsDeliveryNameQueryResult = Apollo.QueryResult<
  GetAllRestaurantsDeliveryNameQuery,
  GetAllRestaurantsDeliveryNameQueryVariables
>;
export const GetRestaurantServiceStatusDocument = gql`
  query GetRestaurantServiceStatus($restaurantId: String!) {
    restaurant(restaurantId: $restaurantId) {
      id
      name
      settings {
        serviceStatus {
          status
        }
      }
    }
  }
`;

/**
 * __useGetRestaurantServiceStatusQuery__
 *
 * To run a query within a React component, call `useGetRestaurantServiceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantServiceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantServiceStatusQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useGetRestaurantServiceStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestaurantServiceStatusQuery,
    GetRestaurantServiceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantServiceStatusQuery, GetRestaurantServiceStatusQueryVariables>(
    GetRestaurantServiceStatusDocument,
    options
  );
}
export function useGetRestaurantServiceStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantServiceStatusQuery,
    GetRestaurantServiceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestaurantServiceStatusQuery,
    GetRestaurantServiceStatusQueryVariables
  >(GetRestaurantServiceStatusDocument, options);
}
export type GetRestaurantServiceStatusQueryHookResult = ReturnType<
  typeof useGetRestaurantServiceStatusQuery
>;
export type GetRestaurantServiceStatusLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantServiceStatusLazyQuery
>;
export type GetRestaurantServiceStatusQueryResult = Apollo.QueryResult<
  GetRestaurantServiceStatusQuery,
  GetRestaurantServiceStatusQueryVariables
>;
export const GetRestaurantsServiceStatusDocument = gql`
  query GetRestaurantsServiceStatus($restaurantIds: [String!]!) {
    allRestaurantsServiceStatus(restaurantIds: $restaurantIds) {
      restaurantId
      restaurantName
      dmpServiceStatus
    }
  }
`;

/**
 * __useGetRestaurantsServiceStatusQuery__
 *
 * To run a query within a React component, call `useGetRestaurantsServiceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantsServiceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantsServiceStatusQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *   },
 * });
 */
export function useGetRestaurantsServiceStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRestaurantsServiceStatusQuery,
    GetRestaurantsServiceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRestaurantsServiceStatusQuery,
    GetRestaurantsServiceStatusQueryVariables
  >(GetRestaurantsServiceStatusDocument, options);
}
export function useGetRestaurantsServiceStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantsServiceStatusQuery,
    GetRestaurantsServiceStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRestaurantsServiceStatusQuery,
    GetRestaurantsServiceStatusQueryVariables
  >(GetRestaurantsServiceStatusDocument, options);
}
export type GetRestaurantsServiceStatusQueryHookResult = ReturnType<
  typeof useGetRestaurantsServiceStatusQuery
>;
export type GetRestaurantsServiceStatusLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantsServiceStatusLazyQuery
>;
export type GetRestaurantsServiceStatusQueryResult = Apollo.QueryResult<
  GetRestaurantsServiceStatusQuery,
  GetRestaurantsServiceStatusQueryVariables
>;
export const AllDmpOrdersDocument = gql`
  query AllDmpOrders($restaurantIds: [String!]!) {
    allDmpOrders(restaurantIds: $restaurantIds) {
      orders {
        deliveryMode
        lastMileDeliveryId
        lastMileProvider
        channel
        createdAt
        lastEventTime
        driverName
        formattedAddress
        orderId
        displayOrderId
        quadrant
        restaurantId
        status
      }
      numberOfActiveDrivers
    }
  }
`;

/**
 * __useAllDmpOrdersQuery__
 *
 * To run a query within a React component, call `useAllDmpOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDmpOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDmpOrdersQuery({
 *   variables: {
 *      restaurantIds: // value for 'restaurantIds'
 *   },
 * });
 */
export function useAllDmpOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<AllDmpOrdersQuery, AllDmpOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllDmpOrdersQuery, AllDmpOrdersQueryVariables>(
    AllDmpOrdersDocument,
    options
  );
}
export function useAllDmpOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllDmpOrdersQuery, AllDmpOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllDmpOrdersQuery, AllDmpOrdersQueryVariables>(
    AllDmpOrdersDocument,
    options
  );
}
export type AllDmpOrdersQueryHookResult = ReturnType<typeof useAllDmpOrdersQuery>;
export type AllDmpOrdersLazyQueryHookResult = ReturnType<typeof useAllDmpOrdersLazyQuery>;
export type AllDmpOrdersQueryResult = Apollo.QueryResult<
  AllDmpOrdersQuery,
  AllDmpOrdersQueryVariables
>;
export const GetDmpOrderByIdDocument = gql`
  query GetDmpOrderById($restaurantId: String!, $orderId: String!) {
    getDmpOrderById(restaurantId: $restaurantId, orderId: $orderId) {
      ...DmpOrderByIdFragment
    }
  }
  ${DmpOrderByIdFragmentFragmentDoc}
`;

/**
 * __useGetDmpOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetDmpOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDmpOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDmpOrderByIdQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetDmpOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetDmpOrderByIdQuery, GetDmpOrderByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDmpOrderByIdQuery, GetDmpOrderByIdQueryVariables>(
    GetDmpOrderByIdDocument,
    options
  );
}
export function useGetDmpOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDmpOrderByIdQuery, GetDmpOrderByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDmpOrderByIdQuery, GetDmpOrderByIdQueryVariables>(
    GetDmpOrderByIdDocument,
    options
  );
}
export type GetDmpOrderByIdQueryHookResult = ReturnType<typeof useGetDmpOrderByIdQuery>;
export type GetDmpOrderByIdLazyQueryHookResult = ReturnType<typeof useGetDmpOrderByIdLazyQuery>;
export type GetDmpOrderByIdQueryResult = Apollo.QueryResult<
  GetDmpOrderByIdQuery,
  GetDmpOrderByIdQueryVariables
>;
export const GetNotificationsDocument = gql`
  query GetNotifications {
    notifications {
      createdAt
      eventType
      eventValue {
        capacitySettings {
          capacity
          updatedAt
        }
        deliverySettings {
          deliveryCost
          eta
        }
        deliveryTime {
          periods {
            finish
            start
          }
          weekday
        }
        serviceStatus {
          reason
          status
          updatedAt
        }
        driverOrderLimit
        allowPauseService
        allowReducedArea
        notifications {
          deliveryArea
          eta
          pauseService
          posOffline
        }
        posStatus {
          available
          updatedAt
        }
      }
      id
      restaurantId
      restaurantName
    }
  }
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  );
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
    GetNotificationsDocument,
    options
  );
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<
  GetNotificationsQuery,
  GetNotificationsQueryVariables
>;
export const GetComplaintsDocument = gql`
  query GetComplaints(
    $restaurantsIds: [String!]!
    $status: String
    $startDate: String
    $endDate: String
  ) {
    complaints(
      restaurantsIds: $restaurantsIds
      status: $status
      startDate: $startDate
      endDate: $endDate
    ) {
      ...ComplaintsRow
    }
  }
  ${ComplaintsRowFragmentDoc}
`;

/**
 * __useGetComplaintsQuery__
 *
 * To run a query within a React component, call `useGetComplaintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplaintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplaintsQuery({
 *   variables: {
 *      restaurantsIds: // value for 'restaurantsIds'
 *      status: // value for 'status'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetComplaintsQuery(
  baseOptions: Apollo.QueryHookOptions<GetComplaintsQuery, GetComplaintsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetComplaintsQuery, GetComplaintsQueryVariables>(
    GetComplaintsDocument,
    options
  );
}
export function useGetComplaintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetComplaintsQuery, GetComplaintsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetComplaintsQuery, GetComplaintsQueryVariables>(
    GetComplaintsDocument,
    options
  );
}
export type GetComplaintsQueryHookResult = ReturnType<typeof useGetComplaintsQuery>;
export type GetComplaintsLazyQueryHookResult = ReturnType<typeof useGetComplaintsLazyQuery>;
export type GetComplaintsQueryResult = Apollo.QueryResult<
  GetComplaintsQuery,
  GetComplaintsQueryVariables
>;
export const GetComplaintDetailsDocument = gql`
  query GetComplaintDetails($restaurantId: String!, $complaintId: String!) {
    complaintDetails(restaurantId: $restaurantId, complaintId: $complaintId) {
      ...ComplaintsRow
    }
  }
  ${ComplaintsRowFragmentDoc}
`;

/**
 * __useGetComplaintDetailsQuery__
 *
 * To run a query within a React component, call `useGetComplaintDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComplaintDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComplaintDetailsQuery({
 *   variables: {
 *      restaurantId: // value for 'restaurantId'
 *      complaintId: // value for 'complaintId'
 *   },
 * });
 */
export function useGetComplaintDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetComplaintDetailsQuery, GetComplaintDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetComplaintDetailsQuery, GetComplaintDetailsQueryVariables>(
    GetComplaintDetailsDocument,
    options
  );
}
export function useGetComplaintDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetComplaintDetailsQuery,
    GetComplaintDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetComplaintDetailsQuery, GetComplaintDetailsQueryVariables>(
    GetComplaintDetailsDocument,
    options
  );
}
export type GetComplaintDetailsQueryHookResult = ReturnType<typeof useGetComplaintDetailsQuery>;
export type GetComplaintDetailsLazyQueryHookResult = ReturnType<
  typeof useGetComplaintDetailsLazyQuery
>;
export type GetComplaintDetailsQueryResult = Apollo.QueryResult<
  GetComplaintDetailsQuery,
  GetComplaintDetailsQueryVariables
>;
export const GetVoucherComplaintDocument = gql`
  query GetVoucherComplaint {
    getVoucherComplaint {
      loyaltyEngineId
      text
    }
  }
`;

/**
 * __useGetVoucherComplaintQuery__
 *
 * To run a query within a React component, call `useGetVoucherComplaintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoucherComplaintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoucherComplaintQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVoucherComplaintQuery(
  baseOptions?: Apollo.QueryHookOptions<GetVoucherComplaintQuery, GetVoucherComplaintQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVoucherComplaintQuery, GetVoucherComplaintQueryVariables>(
    GetVoucherComplaintDocument,
    options
  );
}
export function useGetVoucherComplaintLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVoucherComplaintQuery,
    GetVoucherComplaintQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVoucherComplaintQuery, GetVoucherComplaintQueryVariables>(
    GetVoucherComplaintDocument,
    options
  );
}
export type GetVoucherComplaintQueryHookResult = ReturnType<typeof useGetVoucherComplaintQuery>;
export type GetVoucherComplaintLazyQueryHookResult = ReturnType<
  typeof useGetVoucherComplaintLazyQuery
>;
export type GetVoucherComplaintQueryResult = Apollo.QueryResult<
  GetVoucherComplaintQuery,
  GetVoucherComplaintQueryVariables
>;
export const GetMasterRestaurantsDeliveryDocument = gql`
  query GetMasterRestaurantsDelivery {
    masterRestaurantsDelivery {
      ...DmpMasterRelationsFragment
    }
  }
  ${DmpMasterRelationsFragmentFragmentDoc}
`;

/**
 * __useGetMasterRestaurantsDeliveryQuery__
 *
 * To run a query within a React component, call `useGetMasterRestaurantsDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterRestaurantsDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterRestaurantsDeliveryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMasterRestaurantsDeliveryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMasterRestaurantsDeliveryQuery,
    GetMasterRestaurantsDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMasterRestaurantsDeliveryQuery,
    GetMasterRestaurantsDeliveryQueryVariables
  >(GetMasterRestaurantsDeliveryDocument, options);
}
export function useGetMasterRestaurantsDeliveryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMasterRestaurantsDeliveryQuery,
    GetMasterRestaurantsDeliveryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMasterRestaurantsDeliveryQuery,
    GetMasterRestaurantsDeliveryQueryVariables
  >(GetMasterRestaurantsDeliveryDocument, options);
}
export type GetMasterRestaurantsDeliveryQueryHookResult = ReturnType<
  typeof useGetMasterRestaurantsDeliveryQuery
>;
export type GetMasterRestaurantsDeliveryLazyQueryHookResult = ReturnType<
  typeof useGetMasterRestaurantsDeliveryLazyQuery
>;
export type GetMasterRestaurantsDeliveryQueryResult = Apollo.QueryResult<
  GetMasterRestaurantsDeliveryQuery,
  GetMasterRestaurantsDeliveryQueryVariables
>;
export const PowerBiTokenDocument = gql`
  query PowerBiToken($reportKey: ReportKey!) {
    powerBiToken(reportKey: $reportKey) {
      embedUrl
      expiry
      reportId
      accessToken
    }
  }
`;

/**
 * __usePowerBiTokenQuery__
 *
 * To run a query within a React component, call `usePowerBiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerBiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerBiTokenQuery({
 *   variables: {
 *      reportKey: // value for 'reportKey'
 *   },
 * });
 */
export function usePowerBiTokenQuery(
  baseOptions: Apollo.QueryHookOptions<PowerBiTokenQuery, PowerBiTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PowerBiTokenQuery, PowerBiTokenQueryVariables>(
    PowerBiTokenDocument,
    options
  );
}
export function usePowerBiTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PowerBiTokenQuery, PowerBiTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PowerBiTokenQuery, PowerBiTokenQueryVariables>(
    PowerBiTokenDocument,
    options
  );
}
export type PowerBiTokenQueryHookResult = ReturnType<typeof usePowerBiTokenQuery>;
export type PowerBiTokenLazyQueryHookResult = ReturnType<typeof usePowerBiTokenLazyQuery>;
export type PowerBiTokenQueryResult = Apollo.QueryResult<
  PowerBiTokenQuery,
  PowerBiTokenQueryVariables
>;
export const GetAllChannelsConfigDocument = gql`
  query GetAllChannelsConfig {
    allChannels {
      channel
      serviceModes
    }
  }
`;

/**
 * __useGetAllChannelsConfigQuery__
 *
 * To run a query within a React component, call `useGetAllChannelsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllChannelsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllChannelsConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllChannelsConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllChannelsConfigQuery,
    GetAllChannelsConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllChannelsConfigQuery, GetAllChannelsConfigQueryVariables>(
    GetAllChannelsConfigDocument,
    options
  );
}
export function useGetAllChannelsConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllChannelsConfigQuery,
    GetAllChannelsConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllChannelsConfigQuery, GetAllChannelsConfigQueryVariables>(
    GetAllChannelsConfigDocument,
    options
  );
}
export type GetAllChannelsConfigQueryHookResult = ReturnType<typeof useGetAllChannelsConfigQuery>;
export type GetAllChannelsConfigLazyQueryHookResult = ReturnType<
  typeof useGetAllChannelsConfigLazyQuery
>;
export type GetAllChannelsConfigQueryResult = Apollo.QueryResult<
  GetAllChannelsConfigQuery,
  GetAllChannelsConfigQueryVariables
>;
export const GetOrdersHistoryDocument = gql`
  query GetOrdersHistory(
    $dateFrom: String!
    $dateTo: String!
    $evaluatePaymentMethodBrand: Boolean
  ) {
    ordersHistory(
      dateFrom: $dateFrom
      dateTo: $dateTo
      evaluatePaymentMethodBrand: $evaluatePaymentMethodBrand
    ) {
      channel
      createdAt
      customerEmail
      driverId
      displayOrderId
      rbiNumberId
      paymentMethod
      paymentTotal
      restaurantId
      restaurantName
      status
      events {
        name
        time
      }
    }
  }
`;

/**
 * __useGetOrdersHistoryQuery__
 *
 * To run a query within a React component, call `useGetOrdersHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersHistoryQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      evaluatePaymentMethodBrand: // value for 'evaluatePaymentMethodBrand'
 *   },
 * });
 */
export function useGetOrdersHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrdersHistoryQuery, GetOrdersHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersHistoryQuery, GetOrdersHistoryQueryVariables>(
    GetOrdersHistoryDocument,
    options
  );
}
export function useGetOrdersHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersHistoryQuery, GetOrdersHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersHistoryQuery, GetOrdersHistoryQueryVariables>(
    GetOrdersHistoryDocument,
    options
  );
}
export type GetOrdersHistoryQueryHookResult = ReturnType<typeof useGetOrdersHistoryQuery>;
export type GetOrdersHistoryLazyQueryHookResult = ReturnType<typeof useGetOrdersHistoryLazyQuery>;
export type GetOrdersHistoryQueryResult = Apollo.QueryResult<
  GetOrdersHistoryQuery,
  GetOrdersHistoryQueryVariables
>;
export const GetRiderAppPrivacyPolicyDocument = gql`
  query GetRiderAppPrivacyPolicy {
    riderAppPrivacyPolicy {
      content
      createdAt
      createdBy
      current
      updatedAt
      updatedBy
      version
    }
  }
`;

/**
 * __useGetRiderAppPrivacyPolicyQuery__
 *
 * To run a query within a React component, call `useGetRiderAppPrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRiderAppPrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRiderAppPrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRiderAppPrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRiderAppPrivacyPolicyQuery,
    GetRiderAppPrivacyPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRiderAppPrivacyPolicyQuery, GetRiderAppPrivacyPolicyQueryVariables>(
    GetRiderAppPrivacyPolicyDocument,
    options
  );
}
export function useGetRiderAppPrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRiderAppPrivacyPolicyQuery,
    GetRiderAppPrivacyPolicyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRiderAppPrivacyPolicyQuery, GetRiderAppPrivacyPolicyQueryVariables>(
    GetRiderAppPrivacyPolicyDocument,
    options
  );
}
export type GetRiderAppPrivacyPolicyQueryHookResult = ReturnType<
  typeof useGetRiderAppPrivacyPolicyQuery
>;
export type GetRiderAppPrivacyPolicyLazyQueryHookResult = ReturnType<
  typeof useGetRiderAppPrivacyPolicyLazyQuery
>;
export type GetRiderAppPrivacyPolicyQueryResult = Apollo.QueryResult<
  GetRiderAppPrivacyPolicyQuery,
  GetRiderAppPrivacyPolicyQueryVariables
>;
export const GetMarketAvailablePaymentsDocument = gql`
  query GetMarketAvailablePayments {
    getPaymentMethods {
      paymentName
      paymentMethodBrand
      isOnlinePayment
    }
  }
`;

/**
 * __useGetMarketAvailablePaymentsQuery__
 *
 * To run a query within a React component, call `useGetMarketAvailablePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketAvailablePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketAvailablePaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketAvailablePaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketAvailablePaymentsQuery,
    GetMarketAvailablePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMarketAvailablePaymentsQuery, GetMarketAvailablePaymentsQueryVariables>(
    GetMarketAvailablePaymentsDocument,
    options
  );
}
export function useGetMarketAvailablePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketAvailablePaymentsQuery,
    GetMarketAvailablePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketAvailablePaymentsQuery,
    GetMarketAvailablePaymentsQueryVariables
  >(GetMarketAvailablePaymentsDocument, options);
}
export type GetMarketAvailablePaymentsQueryHookResult = ReturnType<
  typeof useGetMarketAvailablePaymentsQuery
>;
export type GetMarketAvailablePaymentsLazyQueryHookResult = ReturnType<
  typeof useGetMarketAvailablePaymentsLazyQuery
>;
export type GetMarketAvailablePaymentsQueryResult = Apollo.QueryResult<
  GetMarketAvailablePaymentsQuery,
  GetMarketAvailablePaymentsQueryVariables
>;
