import { Buffer } from 'buffer';

import { createModel } from '@rematch/core';
import { inflate } from 'pako';

import { GetMenuQuery, ProductFragmentFragment, ProductPrice } from 'src/graphql';
import { getProductSections, matchProductAndPrice } from 'src/utils';

import { MenuQueryDecompressed } from '../types';

import type { RootModel } from '.';

type MenuState = {
  data: Record<string, ProductFragmentFragment>;
  selected: ProductFragmentFragment['id'][];
  current: ProductFragmentFragment | null;
  menuPrices: readonly ProductPrice[];
  menuPricesLoading: boolean;
  filters: {
    searchTerm: string;
    types: string[];
    sections: string[];
    restaurant: string | null;
    serviceMode: string | null;
  };
};

/**
 * Menu redux store
 */
export const menu = createModel<RootModel>()({
  state: {
    data: {},
    selected: [],
    current: null,
    menuPrices: [],
    menuPricesLoading: false,
    filters: {
      searchTerm: '',
      types: [],
      sections: [],
      restaurant: null,
      serviceMode: null,
    },
  } as MenuState,
  reducers: {
    /**
     * Creates an dictionary of products { [product.id]: product }
     */
    setProducts: (state, payload: GetMenuQuery['menu']) => {
      let menu: MenuQueryDecompressed['menu'] = [];

      if (payload) {
        const dataBuffer = Buffer.from(payload, 'base64');
        menu = JSON.parse(inflate(dataBuffer, { to: 'string' }));
      }

      return {
        ...state,
        data: menu.reduce<MenuState['data']>(
          (hash, product) => ({
            ...hash,
            [product.id]: product,
          }),
          {}
        ),
      };
    },

    /**
     * Stores the value of the menu prices of the selected restaurant
     */
    setMenuPrices: (state, payload: readonly ProductPrice[]) => {
      return {
        ...state,
        menuPrices: payload,
      };
    },

    /**
     * Stores the value of the menu prices query loading state
     */
    setMenuPricesLoading: (state, payload: boolean) => {
      return {
        ...state,
        menuPricesLoading: payload,
      };
    },

    /**
     * Sets current product from an id
     */
    setCurrentProduct: (state, payload: ProductFragmentFragment['id'] | null) => {
      if (typeof payload === 'string') {
        return { ...state, current: state.data[payload] };
      }
      return { ...state, current: null };
    },

    /**
     * Toggles selected products by adding or removing them the selectedProducts array
     */
    setSelectedProducts: (state, payload: ProductFragmentFragment['id']) => {
      if (state.selected.includes(payload)) {
        return {
          ...state,
          selected: state.selected.filter(id => id !== payload),
        };
      }
      return { ...state, selected: [...state.selected, payload] };
    },

    /**
     * Toggles selected products by adding or removing all products from the selectedProducts array
     */
    setSelectedProductsAll: (state, payload: Array<ProductFragmentFragment['id']>) => {
      return { ...state, selected: [...payload] };
    },

    /**
     * Clears selected products
     */
    clearSelectedProducts: state => {
      return { ...state, selected: [] };
    },

    /**
     * Updates the value of the search filter
     */
    setSearchTerm: (state, payload: string) => {
      return { ...state, filters: { ...state.filters, searchTerm: payload } };
    },

    /**
     * Updates the value of the restaurant filter
     */
    setSelectedRestaurant: (state, payload: string) => {
      if (payload === state.filters.restaurant) {
        return { ...state, filters: { ...state.filters, restaurant: null } };
      }

      return { ...state, filters: { ...state.filters, restaurant: payload } };
    },

    /**
     * Updates the value of the restaurant filter
     */
    setSelectedServiceMode: (state, payload: string) => {
      if (payload === state.filters.serviceMode) {
        return { ...state, filters: { ...state.filters, serviceMode: null } };
      }

      return { ...state, filters: { ...state.filters, serviceMode: payload } };
    },

    /**
     * Updates the value of the type filter
     */
    setSelectedTypes: (state, payload: string) => {
      if (state.filters.types.includes(payload)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            types: state.filters.types.filter(type => type !== payload),
          },
        };
      }

      return { ...state, filters: { ...state.filters, types: [...state.filters.types, payload] } };
    },

    /**
     * Updates the value of the sections filter
     */
    setSelectedSections: (state, payload: string) => {
      if (state.filters.sections.includes(payload)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            sections: state.filters.sections.filter(section => section !== payload),
          },
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          sections: [...state.filters.sections, payload],
        },
      };
    },
  },

  effects: dispatch => ({
    /**
     * Triggers an update of the editor model
     */
    setSelectedProducts(_payload: ProductFragmentFragment['id'], _rootState) {
      this.updateEditor();
    },

    /**
     * Triggers an update of the editor model
     */
    setSelectedProductsAll(_payload: Array<ProductFragmentFragment['id']>, _rootState) {
      this.updateEditor();
    },

    /**
     * Clears the products of the editor model
     */
    clearSelectedProducts() {
      dispatch.editor.setProducts([]);
    },

    /**
     * Updates the editor model with the data of the selected products
     */
    updateEditor(_, rootState) {
      const selectedProducts = rootState.menu.selected.map(id => rootState.menu.data[id]);
      dispatch.editor.setProducts(selectedProducts);
    },
  }),

  selectors: (slice, createSelector) => ({
    /**
     * Memoized array of all products
     */
    allProducts() {
      return createSelector([slice(state => state.data)], products => {
        return Object.values(products);
      });
    },

    /**
     * Memoized array of filtered products
     */
    filteredProducts() {
      return createSelector(
        this.searchTerm,
        this.selectedTypes,
        this.selectedSections,
        this.allProducts,
        this.menuPrices,
        (st, ty, sec, ap, mp) => {
          // Fix TS not infering the resolved values of the selectors
          const searchTerm = st as unknown as string;
          const types = ty as unknown as string[];
          const sections = sec as unknown as string[];
          const allProducts = ap as unknown as ProductFragmentFragment[];
          const menuPrices = mp as unknown as ProductPrice[];

          if (allProducts.length === 0) {
            return [];
          }

          // avoid filtering when filters are empty
          if (!searchTerm && types.length === 0 && sections.length === 0 && !menuPrices) {
            return allProducts;
          }

          let result = allProducts;

          // filter by product type
          if (types.length > 0) {
            result = result.filter(product => types.includes(product.__typename));
          }

          // filter by product section
          if (sections.length > 0) {
            result = result.filter(product =>
              sections.some(section => getProductSections(product).includes(section))
            );
          }

          // filter by product name, id or plu
          if (searchTerm) {
            result = result.filter(product =>
              [product.name.toLowerCase(), product.id, product.plusComposite].some(field =>
                field?.includes(searchTerm.toLocaleLowerCase())
              )
            );
          }

          // add price and availability for the selected restaurant
          if (menuPrices && menuPrices.length > 0) {
            result = result.map((product: ProductFragmentFragment) =>
              matchProductAndPrice(menuPrices, product)
            );
          }

          return result;
        }
      );
    },

    /**
     * Memoized value of the current product
     */
    currentProduct() {
      return slice(state => state.current);
    },

    /**
     * Memoized array of selected products ids
     */
    selectedProducts() {
      return slice(state => state.selected);
    },

    /**
     * Memoized value of the search input
     */
    searchTerm() {
      return slice(state => state.filters.searchTerm);
    },

    /**
     * Memoized value of the type filter
     */
    selectedTypes() {
      return slice(state => state.filters.types);
    },

    /**
     * Memoized value of the restaurant filter
     */
    selectedRestaurant() {
      return slice(state => state.filters.restaurant);
    },

    /**
     * Memoized value of the service mode filter
     */
    selectedServiceMode() {
      return slice(state => state.filters.serviceMode);
    },

    /**
     * Memoized value of the menu prices of a restaurant
     */
    menuPrices() {
      return slice(state => state.menuPrices);
    },

    /**
     * Memoized value of the menu prices of loading state of the GetRestaurantsWithMenuItems query
     */
    menuPricesLoading() {
      return slice(state => state.menuPricesLoading);
    },

    /**
     * value of the section filter
     */
    selectedSections() {
      return slice(state => state.filters.sections);
    },

    /**
     * A list of all product types
     */
    productTypes() {
      return createSelector(this.allProducts, ap => {
        const allProducts = ap as unknown as ProductFragmentFragment[];
        return [...new Set(allProducts.map(product => product.__typename))];
      });
    },

    /**
     * A list of all product sections
     */
    productSections() {
      return createSelector(this.allProducts, ap => {
        const allProducts = ap as unknown as ProductFragmentFragment[];
        return [...new Set(allProducts.map(product => getProductSections(product)).flat())];
      });
    },
  }),
});
