export type PriceType = 'exact' | 'percent' | 'relative';

export const calculateNewPrice = ({
  modifier,
  restaurantItemPrice,
  type,
  value,
}: {
  modifier: 1 | -1;
  restaurantItemPrice: number;
  type: PriceType | null;
  value: number;
}) => {
  if (type === 'exact') {
    return value;
  }

  let result;
  if (type === 'percent') {
    result = Math.round(restaurantItemPrice + restaurantItemPrice * ((value / 100) * modifier));
  } else {
    result = restaurantItemPrice + value * modifier;
  }

  return result < 0 ? 0 : result;
};

export const roundTo = (num: number, round: number) => {
  return Number(`${Math.round(Number(`${num}e${round}`))}e-${round}`);
};

/**
 * Validation fn for the price input
 * 1.- Transforms the value from the input into a cents price ex: "6.50" => 650
 * 2.- Transforms negative values into positive values ex: "-6.50" => 650
 * 3.- Enforces the max value of 99.99 ex: "120" => 9999
 */
export function validatePrice(value: string, maxValue = true): number {
  const [int, decimal] = value.split('.');
  const hasTwoDecimals = decimal && decimal.length >= 2;

  let price = Math.round(Number(value) * 100);

  if (hasTwoDecimals) {
    const currentPrice = `${int}.${decimal.slice(0, 2)}`;
    price = Math.round(Number(currentPrice) * 100);
  }

  if (isNaN(price)) {
    price = 0;
  }

  if (price < 0) {
    price = price * -1;
  }

  if (maxValue && price > 9999) {
    price = 9999;
  }

  return price;
}

/**
 * Formats a price for the value attribute of a price input rounding up using 2 decimal places.
 * Converts a price in cents into a float value. For example, 112 => "1.12".
 *
 * @param {number | null} price - The price in cents. A nullable value.
 * @param {boolean} fixed - Defines whether or not to return fixed notation number - defaults to true
 * @returns {string} The formatted price as a string.
 */
export function formattedPrice(price?: number | null, fixed = true): string {
  const number = inputFormattedPrice(price);

  if (typeof number !== 'number') {
    return '';
  }

  return fixed ? number.toFixed(2) : number.toString();
}

export function inputFormattedPrice(price?: number | null) {
  if (typeof price !== 'number') {
    return '';
  }

  const number = Number(Math.round((price + Number.EPSILON) * 100) / 10000);
  return number;
}
