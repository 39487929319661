import { useMemo } from 'react';

import {
  ApolloClient,
  ApolloProvider as ApolloClientProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { v4 } from 'uuid';

import { AuthProvider, useAuthContext } from 'src/Auth';
import introspection from 'src/graphql/__generated__/introspectionResult';
import { LocaleProvider, useLocale } from 'src/locale';
import { ThemeProvider } from 'src/pages/shell';
import { store } from 'src/rematch/store';

import { DeliveryModalProvider } from './hooks/useDeliveryModal';
import { getServerUrl, shouldForceRegion } from './utils';

/** Apollo provider conditional on region context */
export const ApolloProvider: React.FC<{ children: React.ReactNode }> = props => {
  const { region, locale } = useLocale();
  const { authClient } = useAuthContext();

  const uri = `${getServerUrl(region)}/v1/graphql`;

  const httpLink = createHttpLink({
    uri,
  });

  const oktaId = (authClient.authStateManager.getAuthState()?.idToken?.claims.UserID ??
    '') as string;

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-ui-locale': locale,
        'x-ui-region': shouldForceRegion(oktaId, region),
        'okta-id-token': authClient.getIdToken() ?? '',
        'x-correlation-id': v4(),
      },
    };
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          possibleTypes: introspection.possibleTypes,
          typePolicies: { PolygonsGridQuadrant: { keyFields: false } },
        }),
        link: authLink.concat(httpLink),
        connectToDevTools: process.env.NODE_ENV === 'development',
      }),
    [httpLink, authLink]
  );

  return <ApolloClientProvider {...props} client={client} />;
};

// Careful here, order matters
const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ReduxProvider store={store}>
    <HelmetProvider>
      <ThemeProvider>
        <AuthProvider>
          <LocaleProvider>
            <ApolloProvider>
              <DeliveryModalProvider>
                <BrowserRouter>{children}</BrowserRouter>
              </DeliveryModalProvider>
            </ApolloProvider>
          </LocaleProvider>
        </AuthProvider>
      </ThemeProvider>
    </HelmetProvider>
  </ReduxProvider>
);

export default AppProviders;
